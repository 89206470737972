import React, { useState } from 'react'
import { 
    Flex, 
    Box, 
    NavLink, 
    useSelectors, 
    useActions,
    Logo,
    logoMain,
    logoExt
 } from '@ludwig/ui'

import { SearchBox } from './SearchBox'
import { Icon } from '../common/LudwigIcon'
import { Text } from '../common/LudwigText'
import { QuickToggle } from '../common/LudwigQuickToggle'

export const Headbar = React.memo(({current}) => {

    const showSearch = current.key === 'search' || !current.key

    const { theme, appData } = useSelectors()

    const { stayOnTop } = appData || {}
    const [pinWin, setPinWin] = useState(stayOnTop)
    const [expWin, setExpWin] = useState(false)

    const {toggleStayOnTop, updateWinView} = useActions()

    //const { togglePinWindow, collapseExpandWindow } = useActions()

    const handleTogglePinWindow = (e) => {
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()

        setPinWin((prev) => {
            //togglePinWindow({pinWin: !prev})
            if(updateWinView){
                toggleStayOnTop && toggleStayOnTop({stayOnTop: !prev})
            }
            return !prev
        })
        
    }

    const handleCollapseExpandWindow = (e) => {
        e.stopPropagation()
        e.preventDefault()
        e.nativeEvent.stopImmediatePropagation()

        setExpWin((prev) => {
            if(updateWinView){
                updateWinView({view: prev ? 'compact' : 'standard'})
            }
            return !prev
        })
        
    }


    return(
        <Flex bg='rgba(255,255,255,.9)' height='75px' width='100%' >
        <Flex height='100%' width='100%'>
            <Flex className='nodrag' height='100%' flexDirection='column'>
                <NavLink display='flex' href='/' width={{_:'80px', sm:'80px', ms:'200px', md: '250px'}}
                    px={{_:'0', sm:'0', ms:'10px', md: '10px'}}
                    alignItems='center' justifyContent={{_:'center', sm:'center', ms:'flex-start'}}>
                        <Box display={{_:'none', sm:'none', ms:'block', md: 'block'}}>
                            <svg width="160" height="48" viewBox="0 0 160 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill="black" d={theme.legacyIcons.logoTld} />
                            </svg>
                            <Logo logo={logoExt} />
                        </Box>
                        <Box ml='md' mt='16px' display={{_:'block', sm:'block', ms:'none', md: 'none'}}>
                            {/* <svg width="48" height="48" viewBox="0 0 48 48"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill="black" d={theme.legacyIcons.logoN} />
                            </svg> */}
                            <Logo logo={logoMain} />
                        </Box>
                </NavLink>
            </Flex>
            
            <Flex bg="white" height='100%' flex='1'>
            <Flex flex='1' height='55px' pl='lg' alignItems='center'>
                {showSearch && <SearchBox/>}

                {current.key === 'favorites' && <Flex height='100%' alignItems='center'>
                    <Icon fontSize='md' color='black' iconPath={theme.icons.favourite} />
                    <Text pl='sm' variant='strong_l' color='black' textId='Favorites' />
                </Flex>}

                {current.key === 'history' && <Flex height='100%' alignItems='center'>
                    <Icon fontSize='md' color='black' iconPath={theme.icons.history} />
                    <Text pl='sm' variant='strong_l' color='black' textId='History' />
                </Flex>}

                {current.key === 'library' && <Flex height='100%' alignItems='center'>
                    <Icon fontSize='md' color='black' iconPath={theme.icons.library} />
                    <Text pl='sm' variant='strong_l' color='black' textId='Library' />
                </Flex>}

                {current.key === 'settings' && <Flex height='100%' alignItems='center'>
                    <Icon fontSize='md' color='black' iconPath={theme.icons.settings} />
                    <Text pl='sm' variant='strong_l' color='black' textId='Settings' />
                </Flex>}
            </Flex>
            
            <Flex flexDirection='column' mx='sm' color='black'>
                <QuickToggle tip='Pin Window' selected={pinWin} icon={theme.icons.pin} handleToggle={handleTogglePinWindow}/>
                <QuickToggle tip='Collapse Window' selected={expWin} icon={theme.icons.up} handleToggle={handleCollapseExpandWindow}/>
            </Flex>
            </Flex>
        </Flex>
        </Flex>
    )
})



