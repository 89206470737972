//import { colors } from './colors/index';
//const { primary, secondary, third, fourth, fifth, sixth } = colors || {};

import newColors from './configs/colors.json'

const colors = { ...newColors }

export const results = {
    dictionary: {
        bg: colors.grey['50'],
        '&:hover': {
            bg: colors.grey['70']
        }
    },
    exact: {
        bg: colors.blue['500'],
        '&:hover': {
            color: colors.blue['700']
        }
    },
    similar: {
        bg: colors.yellow['500'],
        '&:hover': {
            color: colors.yellow['700']
        }
    },
    related: {
        bg: colors.coral['500'],
        '&:hover': {
            bg: colors.coral['700']
        }
    },
    translation: {
        bg: colors.aqua['500'],
        '&:hover': {
            bg: colors.aqua['700']
        }
    },
    idiom: {
        bg: colors.purple['300'],
        '&:hover': {
            bg: colors.purple['700']
        }
    }
};
