import React, { useState, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { styled, variant, Tapable } from "@ludwig/ui";
import { AnimatePresence } from "framer-motion";
import log from "../utilities/logger";

export function Toast({ handleClose, id:toastId, config, children, target, ...props }) {
    const { duration = 3000, role = "status" } = config || {};
    const [show, setShow] = useState(true)
    const triggerClose = useCallback(() => {
        log.debug('TOAST - Close: ', toastId)
        setShow(prevState => false)
        handleClose(toastId)
    }, [])
    let timeoutId;
    useEffect(() => {
        if (!duration || timeoutId) {
            return;
        }
        timeoutId = setTimeout(() => {
            triggerClose();
        }, duration);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [duration, triggerClose]);

    return (
        createPortal(<AnimatePresence>
            {
                show && <ToastWrapper
                    key={toastId}
                    show={show}
                    layout
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, y: 20, scale: 0.5 }}
                    role={role}
                    handleClick={triggerClose}
                    {...props}
                >
                    {children}
                </ToastWrapper>
            }
        </AnimatePresence>, target)
    )
}


const ToastWrapper = styled(Tapable)(
    props => variant({
        variants: {
            error: {
                bg: 'danger.700'
            },
            success: {
                bg: 'secondary.700'
            },
            warn: {
                bg: 'third.700'
            }
        }
    }))

ToastWrapper.defaultProps = {
    variant: 'warn',
    borderRadius: 'sm',
    bottom: '0',
    left: '0',
    px: 'md',
    py: 'sm',
    bg: 'primary.500',
    color: 'white'
}