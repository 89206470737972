
import { SettingsContainer as SettingsView } from '../components/desktop/SettingsContainer'
import { MainLayout } from '../components/Wrappers'
import { ToastsProvider } from '../contexts/toastsContext'

export const Settings = () => {
    const current = {key: 'settings', area:'main'}
    return(
        <MainLayout current={current}>
            <ToastsProvider>
                <SettingsView />
            </ToastsProvider>
        </MainLayout>
    )
}
