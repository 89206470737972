const base = {
    '50': '#e8e8e8',
    '100': '#c6c6c6',
    '200': '#a0a0a0',
    '300': '#7a7a7a',
    '400': '#5e5e5e',
    '500': '#414141',
    '600': '#3b3b3b',
    '700': '#323232',
    '800': '#2a2a2a',
    '900': '#1c1c1c'
};

const contrast = {
    '50': '#000000',
    '100': '#000000',
    '200': '#000000',
    '300': '#ffffff',
    '400': '#ffffff',
    '500': '#ffffff',
    '600': '#ffffff',
    '700': '#ffffff',
    '800': '#ffffff',
    '900': '#ffffff'
};
const alpha = {
    '50': base[50] + '1F',
    '100': base[100] + '1F',
    '200': base[200] + '1F',
    '300': base[300] + '1F',
    '400': base[400] + '1F',
    '500': base[500] + '1F',
    '600': base[600] + '1F',
    '700': base[700] + '1F',
    '800': base[800] + '1F',
    '900': base[900] + '1F'
};

export const blacks = {
    '50': base[50],
    '100': base[100],
    '200': base[200],
    '300': base[300],
    '400': base[400],
    '500': base[500],
    '600': base[600],
    '700': base[700],
    '800': base[800],
    '900': base[900],
    light: base[300],
    medium: base[500],
    dark: base[900],
    contrast,
    alpha
};
