import React, { useCallback, useState, useEffect } from 'react';

import {
    styled,
    variant,
    useActions, useLocation, useSystem,
    useSocialShareModal, useConfirmModal,
    Box, Flex,
    Text,
    ActionLink,
    FavoriteSnippet,
    WebclipSnippet,
    ContentWrapper,
    NavLink,
    ActionButton
} from '@ludwig/ui';

import {respStyleObject} from '../../../theme/utils'

import log from '../../../utilities/logger';


const Content = styled(Flex)`
    width: 100%;
    flex-direction: column;
    align-items: stretch;
`;



const Snippets = styled(Content)`
    flex-direction: column;
`;

Snippets.defaultProps = {
    ...Content.defaultProps
};

const TabLink = styled(NavLink)(
    props => `
        text-align: center;
    `,
    props =>
        variant({
            variants: {
                default: {
                    borderRadius: 'sm',
                    flex: respStyleObject({ _: 1, ms: 'initial' }),
                    ...(!props.active
                        ? {
                            '@media(hover)': {
                                ':hover': {
                                    bg: 'primaryAlpha.100'
                                }
                            }
                        }
                        : {
                            bg: 'primaryAlpha.200'
                        })
                }
            }
        })
);

TabLink.defaultProps = {
    variant: 'default'
};

const TabText = styled(Text)(
    props => `
        text-align: center;
        font-weight: bold;
    `,
    props =>
        variant({
            variants: {
                default: {
                    color: props.active ? 'primary.700' : 'primary.200',
                    boxShadow: props.active
                        ? `0px 3px 0px 0px ${props.theme.colors.secondary[400]}`
                        : 'primary.200'
                }
            }
        })
);

TabText.defaultProps = {
    variant: 'default'
};

const SubHeader = ({ type }) => {
    return (
        <Flex
            // my="sm"
            gridGap="md"
            px={{ _: 'sm', ms: 'md' }}
            // py={{ _: 'md', ms: 'sm' }}
            py="md"
            width="100%"
            alignItems="center"
            justifyContent={{ _: 'space-around', ms: 'flex-start' }}
            borderBottom={{ _: 'alphaGray', ms: 'none' }}>
            <TabLink
                active={type === 'FAVORITES'}
                p="sm"
                to={{ type: 'FAVORITES' }}>
                <TabText
                    active={type === 'FAVORITES'}
                    textId="SAVED FROM LUDWIG"
                />
            </TabLink>
            <TabLink
                active={type === 'WEBCLIPS'}
                p="sm"
                to={{ type: 'WEBCLIPS' }}>
                <TabText active={type === 'WEBCLIPS'} textId="SAVED FROM WEB" />
            </TabLink>
        </Flex>
    );
};

export const FavoritesView = ({
    userContent,
    backAction,
    hideBackAction,
    webclip,
    handleCloseWebclipForm,
    ...props
}) => {
    const { saveSentence, saveWebclip, deleteWebclip } = useActions();
    const { type } = useLocation();
    const { savedSentences: favorites, webclips } = userContent || {};
    const showSubHeader = false //routesMap['WEBCLIPS'];
    const showFavorites = type === 'FAVORITES';
    const showWebclips = type === 'WEBCLIPS';
    const { showSocialShareModal } = useSocialShareModal();
    const { showConfirmModal } = useConfirmModal();

    const handleSocialShare = useCallback(
        payload => {
            log.debug('COMP - Favorite Page - Handle Social Share: ', payload);
            showSocialShareModal({ ...payload });
        },
        [showSocialShareModal]
    );

    // const handleSearch = useCallback(
    //     payload => {
    //         getSentences({ ...payload, type: 'SEARCH' });
    //     },
    //     [getSentences]
    // );

    const handleDeleteSentence = useCallback(
        payload => {
            log.debug(
                'COMP - Search Page - Handle Save Sentence - Payload: ',
                payload
            );
            const alertSave = payload => {
                log.debug(
                    'COMP - Favorite Page - Handle Delete Sentence - Payload: ',
                    payload
                );
                return saveSentence(payload);
            };
            const { renderContent, ...rest } = payload || {};
            showConfirmModal({
                confirmAction: alertSave,
                confirmPayload: rest,
                renderContent,
                confirmTitle: 'COMMON_FAVORITES_REMOVE_SENTENCE_TITLE_DEFAULT',
                confirmActionText:
                    'COMMON_FAVORITES_REMOVE_SENTENCE_ACTION_DEFAULT',
                confirmSuccess:
                    'COMMON_FAVORITES_REMOVE_SENTENCE_SUCCESS_DEFAULT',
                confirmCloseText: 'COMMON_ACTION_CLOSE_DEFAULT'
            });
        },
        [saveSentence, showConfirmModal]
    );
    const handleDeleteWebclip = useCallback(
        payload => {
            log.debug(
                'COMP - Search Page - Handle Delete Webclip - Payload: ',
                payload
            );
            const alertSave = payload => {
                log.debug(
                    'COMP - Favorite Page - Handle Delete Webclip - Payload: ',
                    payload
                );
                return deleteWebclip(payload);
            };
            const { renderContent, ...rest } = payload || {};
            showConfirmModal({
                confirmAction: alertSave,
                confirmPayload: rest,
                renderContent,
                confirmTitle: 'COMMON_FAVORITES_REMOVE_SENTENCE_TITLE_DEFAULT',
                confirmActionText:
                    'COMMON_FAVORITES_REMOVE_SENTENCE_ACTION_DEFAULT',
                confirmSuccess:
                    'COMMON_FAVORITES_REMOVE_SENTENCE_SUCCESS_DEFAULT',
                confirmCloseText: 'COMMON_ACTION_CLOSE_DEFAULT'
            });
        },
        [deleteWebclip, showConfirmModal]
    );
    const actions = {
        handleDeleteSentence,
        handleSocialShare,
        handleDeleteWebclip,
        saveWebclip
    };

    
    return (
        <ContentWrapper {...props}>
            {/* <ContentHeader
                title="COMMON_FAVORITES_TITLE_DEFAULT"
                backAction={backAction}
                hideBackAction={hideBackAction}
            /> */}
            {/* <ContentGrid
                variant="favorites"
                pt="0"
                width="100%"
                flex="1"
                overflow={{ _: 'hidden', ms: 'auto' }}> */}
                <Flex 
                    flexDirection="column"
                    height="100%"
                    width="100%"
                    p='md'
                    overflowY={{ _: 'hidden', ms: 'visible' }}>
                    {webclip && (
                        <WebclipForm
                            webclip={webclip}
                            handleClose={handleCloseWebclipForm}
                        />
                    )}
                    {showSubHeader && <SubHeader type={type} />}
                    {showFavorites && (
                        <Favorites
                            flex="1"
                            overflowY="auto"
                            sentences={favorites}
                            actions={actions}
                        />
                    )}
                    {showWebclips && (
                        <Webclips
                            flex="1"
                            overflowY="auto"
                            sentences={webclips}
                            actions={actions}
                        />
                    )}
                </Flex>
            {/* </ContentGrid> */}
        </ContentWrapper>
    );
};

const Favorites = ({ sentences, actions, ...props }) => {
    const hasSentences = sentences && sentences.length > 0;
    return (
        <Snippets overflowY={{ _: 'auto', ms: 'hidden' }} {...props}>
            {!hasSentences && (
                <Flex alignItems="center" justifyContent="center">
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        margin="0"
                        as="p">
                        <Box mb="md">
                            <Text id="COMMON_EMPTY_FAVORITES" />
                        </Box>
                        <ActionLink
                            to={{
                                type: 'SEARCH',
                                payload: {
                                    sentence: 'Follow the heart'
                                }
                            }}
                            variant="secondaryLight">
                            <Text id="COMMON_EMPTY_FAVORITES_FOLLOW" />
                        </ActionLink>
                        {/* <span>&hearts;</span> */}
                    </Box>
                </Flex>
            )}
            {sentences.map((item, index) => (
                <FavoriteSnippet actions={actions} {...item} key={index} />
            ))}
        </Snippets>
    );
};
const Webclips = ({ sentences, actions, ...props }) => {
    const { saveWebclip } = actions || {};
    const { isExt, isChrome } = useSystem() || {};
    const hasSentences = sentences && sentences.length > 0;
    // const handleSave = () => {
    //     let payload = {
    //         sentence: 'This is my webclip',
    //         source: 'ludwig.guru',
    //         link: 'https://ludwig.guru/s/hello',
    //         categories: [],
    //         tags: []
    //     };
    //     saveWebclip(payload);
    // };
    return (
        <Snippets overflowY={{ _: 'auto', ms: 'hidden' }} {...props}>
            {/* <ActionButton handleClick={handleSave}>Save webclip</ActionButton> */}
            {!hasSentences && (
                <Flex p="md" alignItems="center" justifyContent="center">
                    <Box textAlign="center" margin="0" as="p">
                        <Box mb="md">
                            {/* <Text id="COMMON_EMPTY_WEBCLIPS" /> */}
                            <Text id="COMMON_COMING_SOON" />
                        </Box>
                        {!isExt && isChrome && (
                            <>
                                <Box mb="md">
                                    <Text id="COMMON_EMPTY_WEBCLIPS_EXT_DISCLAIMER" />
                                </Box>
                                <ActionLink
                                    isExternal
                                    href="https://chrome.google.com/webstore/detail/mddofjlammcffeigngeniglccdbfgnhh/"
                                    variant="secondaryLight">
                                    <Text id="COMMON_EMPTY_WEBCLIPS_EXT_DOWNLOAD" />
                                </ActionLink>
                            </>
                        )}
                    </Box>
                </Flex>
            )}
            {sentences.map((item, index) => (
                <WebclipSnippet actions={actions} {...item} key={index} />
            ))}
        </Snippets>
    );
};

const WebclipForm = ({ webclip, handleClose }) => {
    const { saveWebclip } = useActions() || {};
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { sentence, link } = webclip || {};
    const handleSave = async () => {
        setLoading(prevState => true);
        let payload = {
            ...webclip,
            categories: [],
            tags: []
        };
        const { thunk } = saveWebclip(payload);
        const { status } = (await thunk) || {};
        if (status === 'success') {
            setSuccess(prevState => true);
        }
        setLoading(prevState => false);
    };
    const triggerClose = useCallback(() => {
        handleClose && handleClose();
    }, [handleClose]);

    useEffect(() => {
        let timeout;
        if (success) {
            timeout = setTimeout(() => {
                triggerClose();
            }, 3000);
        }
        return () => clearTimeout(timeout);
    }, [success, triggerClose]);
    return (
        <Box
            position={{ _: 'sticky', ms: 'relative' }}
            top="0"
            zIndex="1"
            p="sm"
            borderBottom={{ _: 'alphaGray', ms: 'none' }}>
            <Box
                bg="white"
                boxShadow='shadow_02'
                my={{ _: 'sm', ms: 'md' }}
                py="md"
                px={{ _: 'md', md: 'lg' }}
                borderRadius="md">
                {success && (
                    <Box>
                        <Text
                            fontWeight="bold"
                            color="secondary.400"
                            textId="Webclip saved!"
                        />
                    </Box>
                )}
                {!success && (
                    <>
                        <Box mb="md">
                            <Box>
                                <Text
                                    fontSize="sm"
                                    color="primary.300"
                                    textId="Text"
                                />
                            </Box>
                            <Box>{sentence}</Box>
                        </Box>
                        <Box mb="md">
                            <Box>
                                <Text
                                    fontSize="sm"
                                    color="primary.300"
                                    textId="Source"
                                />
                                <Box
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    fontSize="sm"
                                    color="secondary.400">
                                    {link}
                                </Box>
                            </Box>
                        </Box>
                        <Flex alignItems="center" justifyContent="flex-end">
                            <ActionButton
                                handleClick={triggerClose}
                                mr="md"
                                variant="secondaryAlpha">
                                <Text textId="Close" />
                            </ActionButton>
                            <ActionButton
                                loader={loading}
                                handleClick={handleSave}
                                variant="secondary">
                                <Text textId="Save Webclip" />
                            </ActionButton>
                        </Flex>
                    </>
                )}
            </Box>
        </Box>
    );
};
