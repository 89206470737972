import { Library as LibraryView } from '../components/desktop/main/Library'
import { MainLayout } from '../components/Wrappers'

export const Library = () => {
    const current = { key: 'library', area: 'main' }
    return (
        <MainLayout current={current}>
            <LibraryView />
        </MainLayout>
    )
}
