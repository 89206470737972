import { userApi } from '@ludwig/api'
import { selectors } from '../../selectors/index'
import { decodePath, encodeOptions } from '../../utilities/strings'
import { log } from '../../../utilities/logger'

const { userSelector } = selectors || {}

const { sendMessage } = userApi || {}

export const getRoutes = (base = '') => {

    return ({
        WELCOME: {
            path: `/`,
            component: 'Welcome',
            role: 'user'
        },
        FOCUS: {
            path: `/focus`,
            component: 'Welcome',
            role: 'user'
        },
        LIBRARY: {
            path: `/library`,
            component: 'Library',
            role: 'user'
        },
        PARAPHRASE: {
            path: `/paraphrase/:sentence?`,
            component: 'Paraphrase',
            role: 'user',
            options: {
                encode: encodeOptions
            },
            fromPath: path => decodePath({ path, slice: true }),
            // toPath: path => log.debug('To Path: ', path),
        },
        SETTINGS: {
            path: `/settings`,
            // TODO Create a page related to system settings accessible to all users
            role: 'user', 
            component: 'Settings'
        },
        LOGIN: {
            path: `/login`,
            // role: 'user', Not here, it causes loop
            component: 'Login'
        },
        FIRST: {
            path: `/first`,
            // role: 'user', Not here, it causes loop
            component: 'FirstLand'
        },
        SOURCE: {
            path: `/source`,
            role: 'user',
            component: 'Source'
        },
        UPGRADE: {
            path: `/upgrade`,
            role: 'user',
            component: 'Upgrade'
        },
        MAINTENANCE: {
            path: `/maintenance`,
            role: 'user',
            component: 'Maintenance'
        },
        TRANSLATE: {
            path: `/translate`,
            role: 'user',
            component: 'Translate'
        },
        SEND_MESSAGE: {
            thunk: async (dispatch, getState, { action, extra: { apiInstance: api } }) => {
                //  TODO[epic=contact] We should use ludwig/core action
                const state = getState() || {}
                const { version, ua, appData } = state || {}
                const user = userSelector(state);
                const { language:lang, appId } = appData || {}
                const { os, browser } = ua || {}
                const { id: userId, isPremium, isUser } = user || {}
                const { payload } = action || {}
                const info = {
                    version,
                    os: os.name,
                    osVersion: os.version,
                    browser: browser.name,
                    browserVersion: browser.version,
                    lang,
                    appId,
                    // device: device.type,
                    // bot: /bot/.test(userAgent)
                };
                const body = { ...payload, info, userId }
                const type = isPremium ? ' - Premium User - ' : isUser ? '- Logged User -' : '- Guest User -';
                const params = {
                    type
                }
                try{
                    const res = await sendMessage({ api, body, params, id:userId, dispatch })
                    const {message} = res.data || {}
                    if(message === 'OK') dispatch({type: 'MESSAGE_SENT', payload:{errorAfterSendMessage: message}})
                }catch(e){ 
                    dispatch({type: 'MESSAGE_SENT', payload:{errorAfterSendMessage: 'error'}})
                }
                
            }
        }
    })
}

export const routes = getRoutes()

export default routes