import React, { memo, useCallback } from 'react';
import useSize from '@react-hook/size';
import { useApiContext } from '../../../contexts/apiContext';
import {codeByLang} from '@ludwig/utilities'
import { FeedbackBlock } from '@ludwig/views';

import {
    styled,
    Box,
    ResultGrid,
    Sentences,
    Picks,
    Translation,
    Idioms,
    RelatedSentences,
    Word,
    Hint,
    SearchTitle,
    Skeleton,
    ResultsCardSK,
    useBoundingBox,
    useEnv,
    useUser,
    useActions,
    Flex
} from '@ludwig/ui'

// import {respStyleObject} from '../../../theme/utils'
//import { FeedbackBlock } from './blocks/FeedbackBlock'


const spaceSteps = { _: 'xs', xs: 'sm', md: 'ms' };

// const spaceStepsObject = respStyleObject(spaceSteps);

const Content = styled(Flex)({
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
});

export const ResultsLoader = ({ isCompact }) => {
    const Wrapper = isCompact ? React.Fragment : ResultGrid;
    return (
        <Wrapper>
            <Content pt={spaceSteps}>
                <ResultsCardSK>
                    <Skeleton
                        itemProps={{
                            px: [1, 2, 2, 2, 3],
                            py: [1, 1, 1, 1, 2],
                            mr: [1, 2, 2, 2, 3],
                            mb: [1, 2, 2, 2, 3],
                            height: [3, 3, 3, 3, 4]
                        }}
                        borderRadius={'4px'}
                        count={4}
                        width="20%"
                        widthRandomness={0.5}
                    // height="50px"
                    />
                </ResultsCardSK>
                <ResultsCardSK>
                    <Skeleton
                        itemProps={{
                            mb: spaceSteps,
                            mr: spaceSteps
                        }}
                        flexDirection="column"
                        height="100px"
                        width="100%"
                        heightRandomness={0.5}
                        borderRadius={'1em'}
                        count={5}
                    />
                </ResultsCardSK>
            </Content>
        </Wrapper>
    );
};

const getSentencesLimits = ({
    isGuest,
    LIMIT_RESULTS_GUEST,
    LIMIT_RESULTS_USER,
    exactSentencesLength,
    similarSentencesLength
}) => {
    // const hasSimilar = similarSentencesLength > 0;
    const totalLimit = isGuest ? LIMIT_RESULTS_GUEST : LIMIT_RESULTS_USER;
    let exactLimit = Math.round(totalLimit / 2);
    exactLimit =
        exactSentencesLength >= exactLimit ? exactLimit : exactSentencesLength;
    let similarLimit = totalLimit - exactLimit;
    similarLimit =
        similarSentencesLength >= similarLimit
            ? similarLimit
            : similarSentencesLength;
    exactLimit = totalLimit - similarLimit;
    return { exactLimit, similarLimit };
};

export const ResultsContent = memo(
    ({
        // searchInfo,
        showInfo,
        isLightPage,
        sentence,
        data,
        picksData,
        place = 'desktop'
        // handleOpenSentence
    }) => {
        const { getSentences, saveSentence, goToPage } = useActions();
        const { isGuest } = useUser();
        const {
            LIMIT_RESULTS_GUEST,
            // LIMIT_RESULTS_PREMIUM,
            LIMIT_RESULTS_USER, 
            ENDPOINT
        } = useEnv();
        const {
            exactSentences = [],
            similarSentences = [],
            relatedSentences = [],
            info,
            dictionary,
            translation,
            idioms = [], 
            searchFeedback, 
            userPlan
        } = data || {};

        const { fetchAICache } = useApiContext() || {}
        
        // const { exactLimit, similarLimit } = limits || {};
        const { definitions: showDictionary } = dictionary || {};
        const { currentSentence } = info || {};
        const showRelated = relatedSentences.length > 0;
        const showIdioms = idioms.length > 0;
        const exactSentencesLength = exactSentences.length;
        const similarSentencesLength = similarSentences.length;
        const { sentence: showTranslation } = translation || {};
        const { exactLimit, similarLimit } = getSentencesLimits({
            isGuest,
            exactSentencesLength,
            similarSentencesLength,
            LIMIT_RESULTS_GUEST,
            LIMIT_RESULTS_USER
        });

        const onSaveSentence = (s,p) => {
            s.query = showInfo.sentence
            saveSentence(s)
        }

        const sentencesArr = [
            {
                show: exactSentencesLength > 0,
                variant: 'exact',
                title:
                    exactSentencesLength === 1
                        ? 'SEARCH_STATS_PLURALS_EXACT_ONE'
                        : 'SEARCH_STATS_PLURALS_EXACT_FEW',
                action: 'COMMON_SHOW_MORE',
                limit: exactLimit,
                sentences: exactSentences
            },
            {
                show: similarSentencesLength > 0,
                variant: 'similar',
                title:
                    similarSentencesLength === 1
                        ? 'SEARCH_STATS_PLURALS_SIMILAR_ONE'
                        : 'SEARCH_STATS_PLURALS_SIMILAR_FEW',
                action: 'COMMON_SHOW_MORE',
                limit: similarLimit,
                sentences: similarSentences
            }
        ];
        const [containerRef, updateRect] = useBoundingBox();
        // const containerRef = React.useRef(null)
        const [width, height] = useSize(containerRef);
        const containerSize = { width, height };

        const handleLimitChanged = useCallback(() => {
            updateRect();
        }, [updateRect]);

        const handleSelectLang = useCallback(language => {
            const lang = codeByLang(language);
            getSentences({ type: 'SEARCH', sentence: currentSentence, o: 'l', lang });
        }, [getSentences, currentSentence])

        return (
            <Content
                ref={containerRef}
                gridGap="md"
            >
                <Flex flexDirection='column' gridGap={{_:'sm', md:'md'}}>
                    {/* {isGuest && isFirstView && !isTouch && (
                    <Flex as="section" m="2em 0 1em">
                        <Welcome payoff={payoff} />
                    </Flex>
                )} */}
                    <Hint {...showInfo} />
                    {picksData && (
                        <Picks place={place} activePick={currentSentence} {...picksData} handleSearch={getSentences} />
                    )}

                    

                    {info.zero && <SearchTitle {...info} />}
                    {showTranslation && (
                        <Translation
                            place={place}
                            isLightPage={isLightPage}
                            handleSelectLang={handleSelectLang}
                            {...translation}
                        />
                    )}

                    {/* {!showTranslation && <SearchTitle variant="head" {...info} />} */}
                    

                    {
                    showDictionary && (
                            <Word
                            place={place}
                            {...dictionary}
                            isLightPage={isLightPage}
                            translation={translation}
                        />
                    )
                    }
                    {searchFeedback && <FeedbackBlock 
                        data={searchFeedback} 
                        fetcher={fetchAICache} 
                        goToPage={goToPage}
                        endPoint={ENDPOINT+'/api'}
                        />}
                    {showIdioms && (
                        <Idioms place={place} data={idioms} isLightPage={isLightPage} />
                    )}
                   
                </Flex>
                {/* {!showTranslation && <SearchTitle {...info} />} */}
                {sentencesArr
                    .filter(item => item.show)
                    .map(
                        (
                            { title, variant, action, limit, sentences },
                            index
                        ) => (
                                <Sentences
                                    place={place}
                                    key={index}
                                    title={title}
                                    variant={variant}
                                    action={action}
                                    containerSize={containerSize}
                                    isLightPage={isLightPage}
                                    // handleOpenSentence={handleOpenSentence}
                                    handleSaveSentence={onSaveSentence}
                                    handleLimitChanged={handleLimitChanged}
                                    // handleTopAction={handleOpenSentences}
                                    handleSearch={getSentences}
                                    navigate={false}
                                    limit={limit}
                                    sentences={sentences}
                                    sentence={currentSentence}
                                    translation={translation}
                                />
                        )
                    )}
                    {showRelated &&(
                            <RelatedSentences
                            place={place}
                            data={relatedSentences}
                            isLightPage={isLightPage}
                        />
                    )}

            </Content>
        );
    }
);

export default ResultsContent;
