import {routes as desktopRoutes} from './desktop/index'
import {getRoutes as getLibraryRoutes} from '@ludwig/library'
import {user as userRoutes} from './user'
import { searchCore, contentCore, libraryCore, routes as oldRoutes  } from '@ludwig/core'

const { routes: searchRoutes } = searchCore || {}
const { routes: contentRoutes } = contentCore || {}
const { routes: libraryRoutesCore } = libraryCore || {}

const libraryRoutes = getLibraryRoutes('library')

export const routes = {
    ...desktopRoutes,
    ...libraryRoutes,
    ...libraryRoutesCore,
    ...searchRoutes,
    ...contentRoutes,
    ...userRoutes,
    GET_PROFILE_START: oldRoutes['GET_PROFILE_START']
}

export default routes
