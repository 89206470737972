import { useState, useEffect } from "react"
import {
  Flex,
  Box,
  ActionButton,
  ActionLink,
  styled,
  useSystem,
  useActions,
  useLocation,
  Logo, 
  logoExt, 
} from "@ludwig/ui"

import { Animation } from "../common/Animation"
import { Text } from "../common/LudwigText"
import { BadgeNewFeature } from './components/BadgeNewFeature'


import Empty from './tour/Empty.json'
import FirstWelcome from './tour/FirstWelcome_Tour.json'
import EngSearch from './tour/EngSearch_Tour.json'
import ChTranslationTour from './tour/ChTranslation_Tour.json'
import ParaphraserTour from './tour/Paraphraser_Tour.json'
import DesktopAppTourMac from './tour/DesktopApp_Mac.json'
import DesktopAppTourWin from './tour/DesktopApp_Win.json'
   
import {
  CY_DESKTOP_TOUR_LOGIN,
  CY_DESKTOP_TOUR_NEXT,
  CY_DESKTOP_TOUR_SEARCH,
} from "../../../utilities/constants"


const getTourData = (isWin) => [
  {ani: FirstWelcome, color: 'smoke.100', title: 'What can you do with Ludwig?', text: 'Whenever you’re feeling unsure about a sentence, Ludwig can help you to solve all your linguistic doubts. Discover how to use it!'},
  {ani: EngSearch, color: 'blues.50', title: 'Search your English sentence', text: 'Type your sentence in English and Ludwig will show you relevant examples taken from reliable and inspiring English sources'},
  {ani: ChTranslationTour, color: 'coral.100', title: 'Translate with confidence', text: 'Search directly in your own language to get a contextualized translation in English. With Ludwig you will be able to see if the translation has logic and is correct'},
  {ani: ParaphraserTour, featured: true, color: 'salmon.50', title: 'Rewrite any sentence in one click', text: 'Find different ways to write a sentence with our AI-powered paraphraser'},
  {ani: isWin ? DesktopAppTourWin : DesktopAppTourMac, color: 'purple.100', title: 'Works Everywhere', text: 'Ludwig works with all writing apps: highlight a piece of text and hit the shortcut to search Ludwig'}
]

export const Tour = ({ guestMode }) => {
  const { isWin } = useSystem() //{isWin:true} //
  const { dispatchClick = () => {} } = useActions()
  const tourData = getTourData(isWin)
  const { type } = useLocation()
  const isFirstLanding = type === 'FIRST'

  const [step, setStep] = useState(0)

  const currentTour = tourData[step]

  const [Ani, setAni] = useState(currentTour.ani)

  const goTo = (index) => {
    setStep(index)
    setAni(tourData[index].ani)
  }

  const goNext = () => {
    goTo(step + 1)
  }

  const startAgain = () => {
    setAni(Empty) // ReactLottie doesn't have easy rewind/playback control
    setTimeout(() => {
      setAni(currentTour.ani)
    })
  }
  useEffect(() => {
    if (guestMode) {
      dispatchClick({ label: "tour-guest" })
    }
  }, [])
  return (
    <Flex flexDirection="column" width="100%" className="nodrag">
      <Flex flexDirection="column" height="410px" maxHeight="100%">
        <Flex
          onClick={startAgain}
          p="md"
          pt="xs"
          bg={currentTour.color}
          width="100%"
          flexDirection="column"
          alignItems="center"
        >

          <Flex width="100%" mb="sm">
            {isFirstLanding && <Logo logo={logoExt} width="100px" />}
            <Flex flex="1" alignItems="center" justifyContent="center" mt='md'>
              <BadgeNewFeature show={currentTour.featured} />
            </Flex>
            {isFirstLanding && <Box width="100px"></Box>}
          </Flex>
          
          <Text
            textAlign="center"
            textId={currentTour.title}
            variant="heading_6"
          />
          <Box
            style={{ cursor: "pointer", aspectRatio: "16/9" }}
            maxWidth="400px" /*height='' borderRadius='sm' overflow='hidden'*/
          >
            <Animation animationData={Ani} />
          </Box>
        </Flex>

        <Flex
          flex="1"
          p="md"
          pb="0"
          px="md"
          width="100%"
          flexDirection="column"
          alignItems="center"
        >
          <Text textAlign="center" color="grey.500" variant="body">
            {currentTour.text}
          </Text>
        </Flex>
      </Flex>

      <Flex
        p="md"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        
        <DotNav onSelect={goTo} current={step} list={tourData} />
        <Flex justifyContent="flex-end">
          {step < tourData.length - 1 && (
            <ActionButton
              track={{ label: "tour-next" }}
              data-cy={CY_DESKTOP_TOUR_NEXT}
              handleClick={goNext}
              variant="SolidLightSmall"
            >
              <Text id="Next" />
            </ActionButton>
          )}
          {step === tourData.length - 1 && (
            <ActionLink
              track={
                guestMode ? { label: "tour-login" } : { label: "tour-search" }
              }
              data-cy={
                guestMode ? CY_DESKTOP_TOUR_LOGIN : CY_DESKTOP_TOUR_SEARCH
              }
              to={{
                type: guestMode ? "LOGIN" : "SEARCH",
                payload: {
                  sentence: "Getting started",
                },
              }}
              variant="SolidBlueSmall"
            >
              <Text id={guestMode ? "Sign In" : "Start using Ludwig"} />
            </ActionLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}



const Dot = styled(Box)`
  cursor: pointer;
`

Dot.defaultProps = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
}

const DotNav = ({ current, list, onSelect }) => {
  return (
    <Flex ml='sm' width="110px" justifyContent="space-between">
      {list.map((d, i) => (
        <Dot
          key={i}
          {...(current === i
            ? { bg: "blues.500" }
            : { bg: "smoke.100", onClick: () => onSelect(i) })}
        />
      ))}
    </Flex>
  )
}
