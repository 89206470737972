import React from 'react'

import {Tapable} from '@ludwig/ui'

export const WinCloseIcon = ({}) => {
  return (
    <Tapable as='svg' xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 12 12">
      <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.326448 -0.326367C-0.146206 -0.506609 0.146023 -0.506609 0.326265 -0.326367L12.3263 11.6736C12.5065 11.8539 12.5065 12.1461 12.3263 12.3263C12.146 12.5066 11.8538 12.5066 11.6736 12.3263L-0.326448 0.326347C-0.50669 0.146105 -0.50669 -0.146125 -0.326448 -0.326367Z" fill="#BCBCBC"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3264 -0.326485C12.5066 -0.146243 12.5066 0.145987 12.3264 0.326229L0.326291 12.3262C0.146049 12.5065 -0.146182 12.5065 -0.326423 12.3262C-0.506664 12.146 -0.506664 11.8538 -0.32642 11.6735L11.6737 -0.326487C11.8539 -0.506729 12.1461 -0.506728 12.3264 -0.326485Z" fill="#BCBCBC"/>
      </g>
  </Tapable>
  )
}
