import { PayWall } from '../components/desktop/main/PayWall'
import { DraggableWrapper } from '../components/Wrappers'

export const Upgrade = () => {
    return(
        <DraggableWrapper bg='white' width="100%" height='100%' flexDirection='column' overflow='overlay'>
                    
           <PayWall />

        </DraggableWrapper>
    )
}