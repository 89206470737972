import React from 'react'
import {Tapable} from '@ludwig/ui'

export const MacFullIcon = ({handleClick}) => {
  return (
    <Tapable handleClick={handleClick} as='svg' xmlns="http://www.w3.org/2000/svg" width="20px" height="16px" viewBox="0 0 20 16">
  <g fill="none" fillRule="evenodd">
    <circle cx="10" cy="8" r="5.75" fill="#E4E4E4" />
    <path fill="#000" fillOpacity=".6" fillRule="nonzero" d="M8.5,5 L13,5 L13,9.5 L8.5,5 Z M11.5,11 L7,11 L7,6.5 L11.5,11 Z"/>
  </g>
</Tapable>

  )
}
