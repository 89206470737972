import { Text as OText, styled, variant } from '@ludwig/ui';
 
export const Text = styled(OText)(
    props =>
        variant({
            variants: {
                ...props.theme.typography
            }
        })
  )

