import { Box, Flex, ActionLink } from '@ludwig/ui'
import { Text } from '../../common/LudwigText'


export const PayWallGeneric = ({title, text, image, button, requestGoPremium}) => {
    return (
        <Flex width='100%' flex='1' flexDirection='column'>
            <Flex flexDirection='column' textAlign='center' >
                <Text p='sm' variant='heading_4' textId={title} />
                <Text p='sm' variant='body_s' textId={text} />
            </Flex>

            <Flex flex='1' p='md' alignItems='center' justifyContent='center'>
                <Box as='img' maxWidth="100%" width='450px' src={image} alt='' />
            </Flex>

            <Flex justifyContent='center' className='nodrag'>
                <ActionLink handleClick={requestGoPremium}
                iconLeft='crownOutline' variant='SolidYellowMedium'>
                    <Text id={button} />
                </ActionLink>
            </Flex>
        </Flex>
    )
}