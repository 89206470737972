import React from 'react'
import { connect } from 'react-redux'
import {log} from './utilities/logger'

import * as pages from './pages/index';

const PageSwitcher = ({page}) => {
  const Component = pages[page]
  log.debug('Page:', page)
  return (
    <Component />
  )
}

const mapState = state => {
  const {page} = state || {}
  return {page}
}

export default connect(mapState)(PageSwitcher)
