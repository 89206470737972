import { History as HistoryView } from '../components/desktop/main/History'
import { MainLayout } from '../components/Wrappers'

export const History = () => {
    const current = { key: 'history', area: 'main' } 
    return (
        <MainLayout current={current}>
            <HistoryView />
        </MainLayout>
    )
}

