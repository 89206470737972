import React, { useEffect, useState } from 'react'
import { Flex, useActions, InputHeader, useSelectors, useLocation } from '@ludwig/ui'

export const SearchBox = () => {

    const {info, appData} = useSelectors() || {}
    const { userSetFocus } = useActions()
    const [sentence, setSentence] = useState(info.sentence)

    const { payload } = useLocation() || {}
    const { focusSearchBox = 0 } = payload || {}

    const { collapsedWin, fullScreen } = appData || {}

    const onHandleInputOpen = e => {
        if(!e) document.activeElement.blur()
    }

    const onFocusSearchBox = () => {
        const field = document.querySelector('.public-DraftEditor-content')
        if(field) {
            field.click()
            field.focus()
        }
    }

    useEffect(() => {
        setSentence(info.sentence)
    }, [info])

    useEffect(() => {
        if(focusSearchBox) {
            setSentence(' ') // empty string is skipped within InputHeader, this does the trick
            onFocusSearchBox()
        }
    }, [focusSearchBox])


    const interceptInteraction = e => {
        const isRightElement = ! (e.currentTarget === e.target)
        if(userSetFocus) userSetFocus({focusIs: isRightElement})
    }
    const isCollapsed = collapsedWin && !fullScreen


    const wrapperProps = !isCollapsed ? {
        borderBottom: '1px solid',
        borderColor: 'smoke.100',
        px: {ms: 'sm'}
    } : {}


    return (
        <Flex {...wrapperProps} className='nodrag' width='100%' zIndex='2' position='sticky' top='0' justifyContent='center'>
            <Flex onClick={interceptInteraction} maxWidth='100%' width='800px' height='100%' flexDirection='column' justifyContent='center' position='relative'>
                <InputHeader place='desktop' isCompactView={isCollapsed} sentence={sentence} handleInputOpen={onHandleInputOpen} />
            </Flex>
        </Flex>
    )
}