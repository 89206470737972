import React from 'react'

import PageSwitcher from './PageSwitcher';
import { useUserCookie } from './hooks/index';

export const App = () => {
  useUserCookie({name: '_ljwt'})
  return (
    <PageSwitcher />
  )
}
