import { Flex, Box, NavLink, useSelectors, useActions, styled, Markdown } from '@ludwig/ui'
import { Text } from '../common/LudwigText'
import { BadgeNewFeature } from './components/BadgeNewFeature'
import useSWR from 'swr'
import axios from 'axios'
import dayjs from 'dayjs'

const fetcher = (url) => axios(url).then((res) => res.data)


export const WhatsNew = () => {

    const { data, error } = useSWR('https://cdn5.ludwig.guru/desktop-app-releases?t=' + new Date().getDate(), fetcher)

    const { appData } = useSelectors()
    const { version = '2.1.1' } = appData || {}

    if(error) return <Flex></Flex>

    const source = data ? data.find(d => d.version === version) : null

    if(!source) return <Flex></Flex>

    const date = dayjs(source.date).format('MMMM D, YYYY')

    return (
        <Flex flexDirection='column' p='xl' width='100%'>
            
            <Text mb='lg' variant='heading_6' textAlign='center'>What’s New in Ludwig</Text>

            <Text mb='xs' variant='label' color='grey.200'>{date}</Text>
            <Text mb='lg' variant='heading_6'>Version {source.version}</Text>

            {source.featured && <Featured {...source.featured} />}

            <Text variant='strong_l'>Release notes:</Text>

            <Text variant='body'><Markdown content={source.notes}/></Text>

        </Flex>
    )
}


const Featured = ({title, text}) => {
    return (
        <Flex flexDirection='column' p='md' mb='lg' bg='salmon.50'>
            <Flex>
                <BadgeNewFeature label='NEW' show='true'/>
            </Flex>
            
            <Text my='md' variant='strong_l'>{title}</Text>

            <Text variant='body'><Markdown content={text}/></Text>
        </Flex>
    )
}