import React from 'react'

import {Tapable} from '@ludwig/ui'

export const MacCloseIcon = ({handleClick}) => {
  return (
    <Tapable handleClick={handleClick} as='svg' xmlns="http://www.w3.org/2000/svg" width="20px" height="16px" viewBox="0 0 20 16">
  <g fill="none" fillRule="evenodd">
    <circle cx="10" cy="8" r="5.75" fill="#E4E4E4" />
    <polygon fill="#000" fillOpacity=".6" fillRule="nonzero" points="13 5.75 12.25 5 10 7.25 7.75 5 7 5.75 9.25 8 7 10.25 7.75 11 10 8.75 12.25 11 13 10.25 10.75 8"/>
  </g>
</Tapable>
  )
}
