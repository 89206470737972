import { Flex, NavLink, useSelectors, useActions, styled } from '@ludwig/ui'


import { Text } from '../common/LudwigText'

export const Account = () => {

    const { user } = useSelectors()
    
    const {requestManageAccount, requestLogout} = useActions()

    return (
        <Flex width='100%'>

            <Flex flex='1'>
                <Pict user={user} />

                <Flex flex='1' flexDirection='column' pl='md'>
                    <Text textId={user.firstName + ' ' + user.lastName} variant='strong' />
                    <Text textId={user.email} color='grey.200' variant='body_s' mb='sm' />
                    {user.isPremium && <Text variant='body_s'><PremiumBadge>PREMIUM</PremiumBadge></Text>}
                    {!user.isPremium && <Text variant='body_s'><FreeBadge>FREE</FreeBadge></Text>}
                </Flex>

               
            </Flex>



            {/* <Flex flexDirection='column' py='sm'>
                <Text variant='body' textId='Subscription' mb='md' />
                <Text variant='body_s' color='grey.300' textId='Premium' />
                <Text variant='strong' color='grey.500' textId='Yearly Plan' />
            </Flex>

            <Box my='md' width='100%' height='1px' bg='grey.50'></Box> */}
            
            <Flex flexDirection='column' justifyContent='space-between' alignItems='flex-end'>

                <NavLink handleClick={requestManageAccount}>
                    <Text textId='Manage Account' variant='strong_s' color='blues.500' />
                </NavLink>

                <NavLink handleClick={requestLogout}>
                    <Text textId='Logout' variant='strong_s' color='blues.500' />
                </NavLink>
            </Flex>
        </Flex>
    )
}



const Pict = ({user}) => {
    const f = user.firstName ? user.firstName.slice(0,1) : ''
    const l = user.lastName ? user.lastName.slice(0,1) : ''
    const fl = f+l ? f+l : 'G'
    return (
        <Flex alignItems='center' justifyContent='center'
             width='64px' height='64px' borderRadius='50%' border='1px solid black'>
            <Text textId={fl} variant='strong_l' />
        </Flex>
    )
}


const PremiumBadge = styled('span')(
    props => `
        padding:0 0.3rem;
        background: ${props.theme.colors.yellows['500']};
    `
)


const FreeBadge = styled('span')(
    props => `
        padding:0 0.3rem;
        background: ${props.theme.colors.grey['50']};
    `
)