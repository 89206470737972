import { blacks } from './blacks';

const base = {
    '50': '#fff9e1',
    '100': '#fff0b3',
    '200': '#ffe781',
    '300': '#ffdd4f',
    '400': '#ffd529',
    '500': '#ffce03',
    '600': '#ffc903',
    '700': '#ffc202',
    '800': '#ffbc02',
    '900': '#ffb001'
};

const contrast = {
    '50': blacks[500],
    '100': blacks[500],
    '200': blacks[500],
    '300': blacks[500],
    '400': blacks[500],
    '500': blacks[500],
    '600': blacks[500],
    '700': blacks[500],
    '800': blacks[500],
    '900': blacks[500]
};

const alpha = {
    '50': base[50] + '1f',
    '100': base[100] + '1f',
    '200': base[200] + '1f',
    '300': base[300] + '1f',
    '400': base[400] + '1f',
    '500': base[500] + '1f',
    '600': base[600] + '1f',
    '700': base[700] + '1f',
    '800': base[800] + '1f',
    '900': base[900] + '1f'
};

export const yellows = {
    '50': base[50],
    '100': base[100],
    '200': base[200],
    '300': base[300],
    '400': base[400],
    '500': base[500],
    '600': base[600],
    '700': base[700],
    '800': base[800],
    '900': base[900],
    light: base[50],
    medium: base[500],
    dark: base[800],
    contrast,
    alpha
};
