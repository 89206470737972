import { Welcome as WelcomeView } from '../components/desktop/main/Welcome'
import { MainLayout } from '../components/Wrappers'

export const Welcome = () => {
    const current = { key: '', area: 'main' }
    return (
        <MainLayout layout='welcome' current={current}>
            <WelcomeView />
        </MainLayout>
    )
}
