import { Signin as SigninView } from '../components/desktop/Signin'
import { DraggableWrapper } from '../components/Wrappers'
import { ToastsProvider } from '../contexts/toastsContext'

export const Login = () => {
    return(
        <DraggableWrapper bg='white' width="100%" height='100%' flexDirection='column' justifyContent='center' overflow='hidden'>
            <ToastsProvider>
                <SigninView />
            </ToastsProvider>
        </DraggableWrapper>
    )
}