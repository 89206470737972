import { userCore, searchCore, selectors as coreSelectors } from "@ludwig/core"
import {selectors as librarySelector} from '@ludwig/library'
import {desktopSelector} from './desktop'

const { selectors: userSelectors } = userCore || {}
const { selectors: searchSelectors } = searchCore || {}
// const { system: systemSelector } = coreSelectors || {}


export const selectors = {
    ...coreSelectors,
    ...userSelectors,
    ...searchSelectors,
    ...librarySelector,
    desktopSelector,
}