export const setSection = (payload) => ({
  type: 'SET_SECTION',
  payload
})

export const togglePinWindow = (payload) => ({
  type: 'TOGGLE_PIN_WINDOW',
  payload
})

export const collapseExpandWindow = (payload) => ({
  type: 'COLLAPSE_EXPAND_WINDOW',
  payload
})

export const userSearchFocus = (payload) => ({
  type: 'USER_SEARCH_FOCUS',
  payload
})

export const sendMessage = (payload) => ({
  type: 'SEND_MESSAGE',
  payload
})

