import { getThemeProperty, styled } from '@ludwig/ui'

export const TextInput = styled.input`
width:100%;
border: none;
padding: 16px;
font-size: 16px;
border-radius: 6px;
margin-bottom: 12px;
border: 2px solid transparent;
background: #F4F4F4;
outline: none !important;
&.invalid {
    border-color: ${getThemeProperty('colors', 'coral', 900)};
}
&:focus {
    outline: none !important;
    border-color: ${props => props.theme.colors.blues[200]};
}
&[disabled] {
    opacity:.5;
}
`

TextInput.defaultProps = {
    bg: 'smoke.100',
    borderColor: 'smoke.100'
}