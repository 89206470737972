import React from 'react';
import PropTypes from 'prop-types';
// import { strings } from '@ludwig/utilities';
import { Flex, styled, variant, css, keyframes } from '@ludwig/ui';

// const { checkSvgPathIsValid } = strings || {};

// log.debug('Icon - Volume: ', icons)

const rotateSpin = keyframes`
    from 0%, to {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const rotateCss = css`
    animation: ${rotateSpin} 1.5s infinite ease-in-out;
`;

const pulseFrames = keyframes`
    from 0%, to {
        transform: scale(0.8);
    }
    from 70%, to {
        transform: scale(1.0);
    }
    100% {
        transform: scale(0.8);
    }
`;

const pulseAnimation = css`
    transform: scale(1);
    animation: ${pulseFrames} 2s infinite;
`;

const IconWrapper = styled(Flex)(
    props => `
        align-items: center;
        justify-content: center;
        pointer-events: none;
        user-select: none;
        svg,
        path {
            pointer-events: none;
        }
    `,
    props =>
        variant({
            variants: {
                ...props.theme.buttons
            }
        })
);

const Svg = styled('svg')(
    props => props.loop && rotateCss,
    props => props.pulse && pulseAnimation
);

export const Icon = ({
    title,
    icon,
    iconPath,
    width = 24,
    height = 24,
    loop,
    pulse,
    ...props
}) => {

    //console.log('checkSvgPathIsValid', checkSvgPathIsValid(iconPath))
    let path = iconPath
    
    return (
        <IconWrapper loop={loop} {...props}>
            <Svg
                loop={loop}
                pulse={pulse}
                xmlns="http://www.w3.org/2000/svg"
                width={width}
                height={height}
                viewBox='0 0 24 24'>
                <path fill="currentColor" d={path} />
            </Svg>
        </IconWrapper>
    );
};

Icon.propTypes = {
    disabled: PropTypes.bool,
    //icon: PropTypes.string.isRequired,
    loop: PropTypes.bool
};

Icon.defaultProps = {
    width: '1.4em',
    height: '1.4em',
    as: 'span'
};

// export default Icon;
