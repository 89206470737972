import React from 'react'

import {Tapable} from '@ludwig/ui'

export const MacMinimizeIcon = ({handleClick}) => {
  return (
    <Tapable handleClick={handleClick} as='svg' xmlns="http://www.w3.org/2000/svg" width="20px" height="16px" viewBox="0 0 20 16">
  <g fill="none" fillRule="evenodd">
    <circle cx="10" cy="8" r="5.75" fill="#E4E4E4" />
    <rect width="8" height="1" x="6" y="7.5" fill="#000" fillOpacity=".6" fillRule="nonzero"/>
  </g>
  </Tapable>

  )
}
