import { Search as SearchView } from '../components/desktop/main/Search'
import { SearchBoxLight } from '../components/desktop/main/SearchBoxLight'
import { MainLayout } from '../components/Wrappers'

export const Search = () => {
    const current = { key: 'search', area: 'main' } 
    return (
        <MainLayout layout='search' current={current}>
            <SearchBoxLight />
            <SearchView />
        </MainLayout>
    )
}
