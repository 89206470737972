import {
    Flex,
    Box
  } from "@ludwig/ui"
import { Text } from "../../common/LudwigText"

export const BadgeNewFeature = ({ show, label='NEW FEATURE' }) => {
    const hidden = show ? 1 : 0
    return (
      <Flex
        alignItems="center"
        bg="blues.50"
        px="xs"
        borderRadius="2px"
        opacity={hidden}
      >
        <Box px="sm">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.52457 1.46353C5.67425 1.00287 6.32595 1.00287 6.47563 1.46353L7.32473 4.0768C7.39167 4.28281 7.58365 4.42229 7.80026 4.42229H10.548C11.0324 4.42229 11.2338 5.0421 10.8419 5.3268L8.61893 6.94189C8.44368 7.06921 8.37035 7.2949 8.43729 7.50091L9.28639 10.1142C9.43607 10.5748 8.90883 10.9579 8.51697 10.6732L6.29399 9.05811C6.11875 8.93079 5.88145 8.93079 5.7062 9.05811L3.48322 10.6732C3.09136 10.9579 2.56412 10.5748 2.7138 10.1142L3.5629 7.50091C3.62984 7.2949 3.55651 7.06921 3.38127 6.94189L1.15828 5.3268C0.766428 5.0421 0.967816 4.42229 1.45218 4.42229H4.19994C4.41655 4.42229 4.60853 4.28281 4.67546 4.0768L5.52457 1.46353Z"
              stroke="#2683FF"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
        <Text
          letterSpacing="3px"
          id={label}
          color="blues.500"
          variant="strong_s"
        />
      </Flex>
    )
  }