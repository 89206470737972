import { primaryColors, primaryElements } from './primaryScheme/index';
import { secondaryColors, secondaryElements } from './secondaryScheme/index';

const colors = secondaryColors;
const elementsColor = secondaryElements;

// const colors = primaryColors
// const elementsColor = primaryElements

export { colors, elementsColor };
