import React from 'react'
import { Flex, useSelectors, ActionButton, useDeleteHistoryModal } from '@ludwig/ui'
import { HistoryView } from './HistoryView.js'
import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'

import { Text } from '../common/LudwigText'


export const History = () => {
    const { history } = useSelectors()
    const historyData = {queries: history.map(d => ({...d, query:d.sentence}))}

    const { showDeleteHistoryModal } = useDeleteHistoryModal()

    return (
        <ColumnWrapContainer>
            <ColumnContainer className='nodrag'>
                <Flex width='100%' flexDirection='column' >
                    
                    <Flex p='lg' pl='32px' pb='0' >

                        <Flex flex='1'>
                            <Text textId='History' variant='heading_5' />
                        </Flex>
                        
                        <ActionButton variant='SolidWhiteSmall' handleClick={showDeleteHistoryModal}>
                            <Text id='Clear History' variant='strong_s' />
                        </ActionButton>
                    </Flex>
                    <HistoryView
                        history={historyData}
                        hideBackAction={() => {}} bg='none' />
                    
                </Flex>
            </ColumnContainer>
        </ColumnWrapContainer>
    )
}
