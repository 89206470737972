import { useState } from 'react'
import { Flex, Box, Tooltip, useSelectors, useActions, styled } from '@ludwig/ui'
import { Dropdown } from '@ludwig/prim'

import { Icon } from '../common/LudwigIcon'
import { Text } from '../common/LudwigText'
import { Toggle } from '../common/LudwigToggle'
import { ShortCut } from './components/ShortCut'
// import { isMac } from '@ludwig/utilities'

import { Account } from './Account.js'

// TODO Fix / Improve Prim Dropdown component
const NextDropdown = styled(Dropdown)`
        div[role="menubar"] {
            min-width: initial;
            div[role="menu"] {
                padding: 8px;
                div[role="menuitem"] {
                    font-size: 0.8em;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
`

export const General = () => {

    const { theme, appData } = useSelectors() || {}
    const {startup, version, winSize} = appData || {}
    const { fixed } = winSize || {}

    const defToolValue = 'Search'
    let {appShortcut} = appData || {}
    if(!appShortcut) appShortcut = { alt: false, ctrl: true, shift: true, key: 'L', defaultTool: defToolValue }
    const [tool, setTool] = useState(appShortcut.defaultTool || defToolValue)

    const {
        toggleStartup,
        toggleFixedWinSize,
        // toggleStayOnTop,
        setAppShortcut
    } = useActions()

    
    
    const handleStartup = () => {
        if(toggleStartup) toggleStartup({startup:!startup})
    }
    
    const handleAppShortcutChange = (payload) => {
        if(setAppShortcut) setAppShortcut({...payload, defaultTool: tool})
    }

    const onToolSelected = (t) => {
        setTool(t)
        if(setAppShortcut) setAppShortcut({...appShortcut, defaultTool: t})
    }

    // const rtlText = rtl ? 'Right to left' : 'Left to right'

    return (
        <Flex flexDirection='column' p='xl' width='100%'>

            <Account />

            <Box my='ms' width='100%' height='1px' bg='grey.50'></Box>

            <Flex alignItems='center' py='sm'>
                <Text flex='1' variant='strong_s' textId='Load Ludwig on startup' />
                <Toggle checked={startup} onChange={handleStartup} />
            </Flex>


            <Flex alignItems='center' py='sm'>
                <Text flex='1' variant='strong_s' textId='Version' />
                <Text variant='body_s' color='grey.100' textId={version} />
            </Flex>

            <Box my='sm' width='100%' height='1px' bg='grey.50'></Box>


            <Flex width='100%' alignItems='stretch' flexWrap='wrap' style={{gridGap: '12px'}}>

                <Flex flexDirection='column'>
                    <Flex alignItems='center'>
                        <Text variant='body_s' color='grey.100' textId='Keyboard Shortcut' />
                        <Tooltip text='Change the key combination to launch Ludwig Desktop from other apps.'>
                            <Icon color='blues.500' iconPath={theme.icons.question_s} />
                        </Tooltip>
                    </Flex>
                
                    <ShortCut shortcut={appShortcut}
                        onChangeShortcut={handleAppShortcutChange} />
                </Flex>

                <Flex flexDirection='column' justifyContent='flex-start'>
                    <Flex alignItems='center'>
                        <Text variant='body_s' color='grey.100' textId='Default Tool' />
                        <Tooltip text='Customize the behavior of the shortcut.'>
                            <Icon color='blues.500' iconPath={theme.icons.question_s} />
                        </Tooltip>
                    </Flex>

                    <NextDropdown mt='sm'
                        list={['Search', 'Paraphrase', 'Do nothing']}
                        initialInput={tool}
                        hasInputFilter={false}
                        handleSelect={onToolSelected} />
                </Flex>
            </Flex>
            

            
            <Box my='md' width='100%' height='1px' bg='grey.50'></Box>

            {/* <Flex alignItems='center' py='sm'>
                <Text flex='1' variant='strong' textId='Main theme' />
            </Flex> */}
        </Flex>
    )
}


