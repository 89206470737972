import React from 'react'

import { connect, useSelector } from 'react-redux'

import { NavLink, useDispatchActions } from '@ludwig/core'
import { getLogger } from '@ludwig/utilities'
import { I18nProvider, Text, getTranslation, languages, transByCode, auto } from '@ludwig/i18n';
import {
  texts,
  events,
  blocks,
  localizedExamples,
  social,
  searchTexts,
  commonTexts,
  catalogEn
} from '@ludwig/data';

import {
  Global,
  //theme as oldTheme,
  UiProvider,
} from "@ludwig/ui"

import { theme, UiProvider as NewUiProvider } from '@ludwig/prim'
import appCss from './appCss'
//import theme from './theme'
import { log } from './utilities/logger'

import { actions, selectors } from './core/index'

const defaultCatalogs = { en: catalogEn };

const data = {
  events,
  blocks,
  social,
  localizedExamples,
  searchTexts,
  commonTexts
};

const contextProps = {
  data,
  getTranslation,
  texts,
  auto,
  transByCode,
  languages
}

const currentLogLevel = log.getLevel()

const loggers = [
  // 'Core', 
  // 'Ui', 
  // 'Utilities'
]
loggers.forEach(logger => getLogger(logger).setLevel(currentLogLevel))

log.debug(selectors)
log.debug(theme)

const {
  userSelector,
  desktopSelector,
  librarySelector,
  system: systemSelector,
  historySelector,
  isLoadingSearch,
  showInfo,
  filterSelector,
  picksSelector
} = selectors || {}

const sendEvent = payload => ({ type: 'SEND_EVENT', payload })
const allActions = { ...actions, sendEvent }

export const AppProvider = connect()(({ children, contextValue }) => {
  const mappedActions = useDispatchActions(allActions)
  const user = useSelector(state => userSelector(state))
  const system = useSelector(state => ({ ...systemSelector(state), platform: 'electron' })) // Force platform
  const desktop = useSelector(state => desktopSelector(state))
  const library = useSelector(state => librarySelector(state))
  const domain = useSelector(state => state.domain)
  const location = useSelector(state => state.location)
  const history = useSelector(state => historySelector(state))
  const loadingSearch = useSelector(state => isLoadingSearch(state))
  const info = useSelector(state => showInfo(state))
  const userContent = useSelector(state => state.userContent)
  const filter = useSelector(state => filterSelector(state))
  const picks = useSelector(state => picksSelector(state))


  // location.routesMap.WEBCLIPS = true //false
  // location.type = 'FAVORITES'
  //library.baseLink = '/library/'

  return (
    <I18nProvider
      language={'en'}
      catalogs={defaultCatalogs}>
      <UiProvider
        theme={theme}
        components={{ NavLink, Text }}
        value={{
          ...contextValue, actions: mappedActions, selectors: {
            user,
            desktop,
            library,
            domain,
            location,
            history,
            theme,
            loadingSearch,
            info,
            userContent,
            system,
            filter,
            picks
          }, env: {
            LIMIT_RESULTS_USER: process.env.REACT_APP_LIMIT_RESULTS_USER,
            LIMIT_RESULTS_GUEST: process.env.REACT_APP_LIMIT_RESULTS_GUEST,
            LIMIT_USER: process.env.REACT_APP_LIMIT_USER,
            LIMIT_GUEST: process.env.REACT_APP_LIMIT_GUEST,
            LIMIT_RESULTS_PREMIUM: process.env.REACT_APP_LIMIT_RESULTS_PREMIUM,
            LIBRARY_URL: process.env.REACT_APP_LIBRARY_URL,
            STREAM_API: process.env.REACT_APP_STREAM_API,
            DATA_CDN: process.env.REACT_APP_DATA_CDN,
            ENDPOINT_PARAPHRASE: process.env.REACT_APP_ENDPOINT,
            ENDPOINT: process.env.REACT_APP_ENDPOINT,
          },
          ...contextProps
        }}
      //  actions={{...mappedActions}}
      >
        <NewUiProvider value={{getTranslation, texts, components: {Text} }}>
          {children}
          <Global styles={appCss(theme)} />
        </NewUiProvider>
      </UiProvider>
    </I18nProvider>
  )
})

