import React, {useState, useEffect} from 'react'
import { 
    Flex, 
    Box, 
    NavLink, 
    ActionLink, 
    useSelectors, 
    useActions, 
    useSystem,
    useDomain,
    Tooltip,
    Logo, 
    logoMain,
    logoExt, 
    Tapable, 
    variant,
    styled,
    ActionButton
 } from '@ludwig/ui'
import { Icon } from './common/LudwigIcon'
import { Text } from './common/LudwigText'
import { getThemeProperty } from '../../theme'
import { WinCommands } from './common/WinCommands'
import { ActionAvatar } from '@ludwig/prim'
import { CY_DESKTOP_LIBRARY_BUTTON, CY_DESKTOP_PARAPHRASER_BUTTON, CY_DESKTOP_SEARCH_BUTTON, CY_DESKTOP_TRANSLATE_BUTTON } from '../../utilities/constants'

const sections = [
    {key: 'search', label: 'Search', icon:'search', href: '/s/welcome', variant:'', 'data-cy': CY_DESKTOP_SEARCH_BUTTON},
    {key: 'translate', label: 'Translate', icon:'translate', href: '/translate', variant:'', 'data-cy': CY_DESKTOP_TRANSLATE_BUTTON},
    {key: 'paraphrase', label: 'Paraphrase', icon:'paraphraser', href: '/paraphrase', variant:'', 'data-cy': CY_DESKTOP_PARAPHRASER_BUTTON},
    {key: 'library', label: 'Library', icon:'library', onlyPremium:true, href: '/library', variant:'', 'data-cy': CY_DESKTOP_LIBRARY_BUTTON},
    //{key: 'settings', label: 'Settings', icon:'settings', href: '/settings'}
]

const sidebarStates = {
    narrow: {
        width: '80px',
        padding: '0',
        align: 'center',
    },
    large: {
        width: '200px',
        padding: '10px',
        align: 'flex-start'
    }
}



export const Sidebar = ({current, handleChange}) => {
    
    const {isMac} = useSystem() // {isMac:false, isWin:true} //
    const plat = isMac ? 'Mac' : 'Win'

    const { requestGoPremium, goToPage } = useActions() || {}
    const { user, info, appData, loadingSearch, location } = useSelectors() || {}
    const { firstName, lastName, email, isPremium, isGuest, isLoggedIn } = user || {}
    const { sentence } = info || {}

    const paraphraserVisited = JSON.parse(localStorage.getItem('paraphraserVisited'))
    const [isNew, setIsNew]= useState(!paraphraserVisited)
    
    const { sidebarState = 'large' } = appData || {} // it can be none, narrow, large
    const sidebarCurrentState = sidebarStates[sidebarState]

    sections.forEach(s => {
        s.active = false
        if(current.key === s.key) s.active = true
    })
    
    sections[0].href = `/s/${sentence || 'welcome'}`

    useEffect(() => {
        if (location.pathname ==='/paraphrase') {
            setIsNew(false)
            localStorage.setItem('paraphraserVisited', JSON.stringify(true));
        }
      }, [isNew, location.pathname]);
    

    if(sidebarState === 'none') return (<></>)

    const disableSidebarStyle = loadingSearch ? {pointerEvents: 'none'} : {}

    return (
        <Flex 
            flexDirection='column'
            width={sidebarCurrentState.width}
            style={disableSidebarStyle}
            justifyContent= 'space-between'
            alignItems = 'center'
            zIndex='10'
            >
                <Flex 
                    flexDirection='column' >
                    {isMac && <Flex height='25px' width='100%' justifyContent={sidebarCurrentState.align} >
                        <WinCommands />
                    </Flex>}
                    <Flex className='nodrag' flexDirection='column'>
                        <NavLink display='flex' href='/' width='100%'
                            px={sidebarCurrentState.padding} style={{outline: 'none !important'}}
                            alignItems='center' justifyContent={sidebarCurrentState.align}>
                                {sidebarState === 'large' && <Box mt='sm'>
                                    {/* <svg width="160" height="48" viewBox="0 0 160 48"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="black" d={theme.legacyIcons.logoTld} />
                                    </svg> */}
                                    <Logo logo={logoExt} />
                                </Box>}
                                {sidebarState === 'narrow' && <Box /*ml='md'*/ mt='sm'>
                                    <Logo logo={logoMain} />
                                    {/* <svg width="60" height="60" viewBox="0 0 60 60"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill="black" d={theme.legacyIcons.ludwig} />
                                    </svg> */}
                                </Box>}
                        </NavLink>
                    </Flex>

                    <Flex 
                        flexDirection='column'
                        p='lg'
                        width='100%'
                        alignItems={sidebarCurrentState.align} 
                        className='nodrag'>

                        {sections.map((s,i) => (
                            <SidebarItem key={s.key} 
                                section={s} 
                                index={i} 
                                size={sidebarState}
                                handleChange={handleChange} />
                        ))}

                        {!isPremium && <Flex mt='l'>
                            {sidebarState === 'large' &&<ActionButton handleClick={()=>requestGoPremium({flow:'sidebar'})} iconLeft='crownOutline' variant='SolidYellowSmall'>
                                <Text id='Go premium' />
                            </ActionButton>}
                            {sidebarState === 'narrow' && <Tooltip layerOptions={{placement: 'right-center'}} text='Go premium'><ActionButton handleClick={()=>requestGoPremium({flow:'sidebar'})} iconLeft='crownOutline' variant='SolidYellowSmall'></ActionButton></Tooltip>}
                        </Flex>}
                    
                    </Flex>

                </Flex>

                {isNew !==false && location.pathname !== '/paraphrase' &&
                <NewFeatureWrapper variant={sidebarState + plat} >
                    <NewFeature href='/paraphrase' variant={sidebarState} >
                        {sidebarState === 'large' && <Text fontWeight='bold' textId='Try it!'/>}
                        {sidebarState === 'narrow' && <Text fontWeight='bold' textId='Try the Paraphrase!'/>}
                    </NewFeature>
                </NewFeatureWrapper>}

                <ActionAvatarWrapper className='nodrag' pb= 'md' pl='sm' alignItems='center' minWidth='180px' variant={sidebarState}>
                        <ActionAvatar NavLink={NavLink}  user= {user} p='none' className='nodrag' variant='primaryContrastAlpha'/>
                        {sidebarState === 'large' && <Text p='sm' fontWeight='bold' color='grey.500'>{user.firstName} {user.lastName}</Text>}
                </ActionAvatarWrapper>
        </Flex>
    )
}

const NewFeature = styled(NavLink)(
    props =>`
    position: relative;
    background:${getThemeProperty('colors', 'blues', '500')}; 
    color:white; 
    padding: 4px 8px; 
    border-radius:5px;
    border: 2px solid white;
        &:after {
            content:"";
            position: absolute;
            height: 0px;
            width: 0px;
            top: 7px;
            left: -14px;
            border-width: 8px;
            border-color: transparent ${getThemeProperty('colors', 'blues', '500')} transparent transparent;
            border-style: solid;};
        &:before {
            content:"";
            position: absolute;
            height: 0px;
            width: 0px;
            top: 7px;
            left: -16px;
            border-width: 8px;
            border-color: transparent white transparent transparent;
            border-style: solid;}
`)


const NewFeatureWrapper= styled(Flex)(
props=>`position:absolute;
        z-index:2;
        box-shadow: ${getThemeProperty('shadows', 'shadow_04')};
`,
props =>variant({
    variants: {
        narrowMac: {
            top:'209px',
            left:'60px',
        }, 
        largeMac: {
            top:'213px',
            left:'175px',
        },
        narrowWin: {
            top:'185px',
            left:'60px',
        }, 
        largeWin: {
            top:'187px',
            left:'175px',
        }
    }
}))

const ActionAvatarWrapper= styled(Flex)(
    props=>`
        pb= 'md';
        pl='sm';
        alignItems='center';
    `,
    props =>variant({
        variants: {
            narrow: {
                minWidth: '30px'
            }, 
            large: {
                minWidth: '180px'
            }
        }
    }))


// const Stick = styled(Box)`
//     position: absolute;
//     right:-12px;
//     top:22px;
//     transition: opacity .25s ease-in-out;
//     opacity: 0;
//     z-index: 10;
// `


// const SideContainer = styled(Flex)`
//     &:hover ${Stick}{
//         opacity: 1;
//     }
// `



const SidebarItem = ({section, index, size, handleChange}) => {

    const { theme } = useSelectors()

    const onHandleClick = () => {
        if(!section.href) handleChange(section)
    }

    const width = size === 'narrow' ? 'none' : '100%'
    const padding = size === 'narrow' ? '0' : 'md'

    return (
        
        <Box width={width}>
            <Link onClick={onHandleClick} href={section.href} className={section.active && 'active'}>
                <ItemWrapper mt={index > 0 ? 'l' : '0'} variant={section.variant}>
                    {size === 'narrow' && <Tooltip layerOptions={{placement: 'right-center'}} text={size === 'narrow' ? section.label : ''}>
                        <Icon pr={padding} iconPath={theme.icons[section.icon]} />
                    </Tooltip>}

                    {size === 'large' && <Icon pr={padding} iconPath={theme.icons[section.icon]} />}
                    {size === 'large' && <Text textId={section.label}  variant='strong' />}
                </ItemWrapper>
            </Link>
        </Box>
        
    )

}



const Link = styled(NavLink)(
    props => `
    color: ${getThemeProperty('colors', 'grey', '500')};

    &:hover, &.active{
        color: ${getThemeProperty('colors', 'blues', '500')};
        }
    `, 
    props => variant({
        variants: {
            ...props.theme.buttons
        }
    })
)

const TextCut = styled(Text)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ItemWrapper = styled(Flex)(
    props =>variant({
        variants: {
            paraphraser:{
                bg: '#ff986bff',
                color: 'white',
                borderRadius: '4em', 
                ml:'-4px',
                py: '2px',
                pl: '4px', 
                pr: '18px',
                '&:hover': {
                    bg: 'red',
                    bg: '#FFF3ED',
                    color: '#ff986bff',
                },
            }
        }
    }))

ItemWrapper.defaultProps = {
    mb:'sm',
}