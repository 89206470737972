
import React, { useEffect, useCallback, useState, useRef } from 'react'
import {Flex, Box, styled, useSystem, useSelectors, keyframes} from '@ludwig/ui'
import { Kbd } from './Kbd'
import { Icon } from '../../common/LudwigIcon'
import { Text } from '../../common/LudwigText'

import log from '../../../../utilities/logger'

const IconPlus = styled(Icon)`
  color: ${props => props.theme.colors.grey[200]};
`

const FlickerAnimation = keyframes`  
  from { background-color: #bedaffff; } 
`; 


const ShortCutWrapper = styled(Flex)`
  cursor: pointer;
  user-select: none;
  width:350px;
  background: ${props => props.theme.colors.smoke[100]};
  justify-content: space-between;
  &:hover {
    background: ${props => props.theme.colors.smoke[200]};
  }
  &.active {
    border: 2px dashed ${props => props.theme.colors.blues[500]};
    animation: ${FlickerAnimation} .75s infinite alternate;
  }
`
ShortCutWrapper.defaultProps = {
  mt: 'sm',
  borderRadius: 'xs',
  p: 'sm'
}

const LetterKbd = styled(Kbd)`
  padding: 0.3em 1em;
`

export const ShortCut = (props) =>  {
  const { theme } = useSelectors() || {}
  const {isMac} = useSystem()
    const { shortcut: initialShortcut, onChangeShortcut } = props || {}
    const {current:currentInitialShortcut} = useRef(initialShortcut)
    const [{ editing, shortcut }, setState] = useState({editing: false, shortcut:currentInitialShortcut })
    const {current:handleChangeShortcut} = useRef(onChangeShortcut)
    const toggleEditing = () => {
      setState(prevState => ({ ...prevState, editing: !prevState.editing }))
    }
    const handleKeyDown = useCallback(e => {
      const { which, key, keyCode, altKey, ctrlKey, metaKey, shiftKey } = e || {}
      e.preventDefault()
      log.debug('COMPONENT - ShortCut Selector - Event: ', e)
      log.debug('COMPONENT - ShortCut Selector - Key: ', key)
      log.debug('COMPONENT - ShortCut Selector - Which: ', which)
      log.debug('COMPONENT - ShortCut Selector - Code: ', keyCode)
      const metaKeys = {
        alt: altKey,
        ctrl: isMac ? metaKey : ctrlKey,
        shift: shiftKey
      }

      log.debug('COMPOENT - ShortCut Selectro - Keys Before Persist', metaKeys)
      const metaKeysArr = Object.keys(metaKeys).filter(item => metaKeys[item] === true) || []
      log.debug('COMPOENT - ShortCut Selector - Meta Keys', metaKeysArr)
      log.debug(
        'COMPOENT - ShortCut Selector - Meta Keys Arr',
        metaKeysArr.length
      )
      let registeredKeys = metaKeysArr.toString().replace(',', ' + ') + ' + '
      log.debug('COMPONENT - ShortCut Selector - Try Register Keys: ', registeredKeys)
      log.debug('COMPONENT - ShortCut Selector - Key Code: ', keyCode)
      if (metaKeysArr.length > 1 && keyCode) {
        const charList = 'abcdefghijklmnopqrstuvwxyz0123456789'
        let letterKey = String.fromCharCode(keyCode).toLowerCase()
        if (charList.indexOf(letterKey) !== -1) {
          registeredKeys += letterKey.toUpperCase()
          log.debug('COMPONENT - ShortCut Selector - Registered Keys', registeredKeys)
          const nextShortcut = {
            alt: altKey,
            ctrl: isMac ? metaKey : ctrlKey,
            shift: shiftKey,
            key: letterKey.toUpperCase()
          }
          setState(prevState => ({
            // tempKeys: registeredKeys,
            ...prevState,
            editing: false,
            shortcut: nextShortcut || prevState.shortcut
          }))
        }
      }
    }, [isMac])

    useEffect(() => {
      if (shortcut && shortcut !== currentInitialShortcut) {
        log.debug('COMPOENT - ShortCut Selector - Use Effect - On Change Shortcut', handleChangeShortcut)
        log.debug('COMPOENT - ShortCut Selector - Use Effect - Shortcut', shortcut)
        handleChangeShortcut(shortcut)
      }
    }, [shortcut, currentInitialShortcut, handleChangeShortcut])

    useEffect(() => {
    if (editing) {
      document.addEventListener('keydown', handleKeyDown, false)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // if (shortcut !== prevShortcut) {
    //   document.removeEventListener('keydown', handleKeyDown)
    //   handleSetShortcut({shortcut})
    // }
  return () => {
    document.removeEventListener('keydown', handleKeyDown)
  }
  }, [editing, shortcut, handleKeyDown])

    const { alt, key, shift, ctrl } = shortcut || {}
    return (
      <ShortCutWrapper onClick={toggleEditing} className={editing ? 'active': ''}>
        {!editing ? (
          <Flex>
            
            {alt && (
              <>
                <Kbd keyLetter={'Alt'} /> <IconPlus iconPath={theme.icons.plus_s} />
              </>
            )}
            {ctrl && (
              <>
                <Kbd keyLetter={isMac ? 'Cmd' : 'Ctrl'} />{' '}
                <IconPlus iconPath={theme.icons.plus_s} />
              </>
            )}

            {shift && (
              <>
                <Kbd keyLetter={'Shift'} /> <IconPlus iconPath={theme.icons.plus_s} />
              </>
            )}

            {key && <LetterKbd keyLetter={key} />}
          </Flex>
        ) : (
          <Box>
            <Text display='block' variant='strong_s' textId='Press the key combination you want to use.' />
            <Text variant='body_s' textId={'At least two keys from ' + (isMac ? 'Cmd' : 'Ctrl') + ', Alt, Shift and a letter.'} />
          </Box>
          
        )}
        <Flex>
          <Icon iconPath={editing ? theme.icons.close : theme.icons.edit} />
        </Flex>
      </ShortCutWrapper>
    )
}
