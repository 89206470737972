const base = {
    '50': '#E4E4E4',
    '100': '#BCBCBC',
    '200': '#8F8F8F',
    '300': '#626262',
    '400': '#414141',
    '500': '#1F1F1F',
    '600': '#1B1B1B',
    '700': '#171717',
    '800': '#121212',
    '900': '#000000'
};

const contrast = {
    '50': '#000000',
    '100': '#000000',
    '200': '#000000',
    '300': '#ffffff',
    '400': '#ffffff',
    '500': '#ffffff',
    '600': '#ffffff',
    '700': '#ffffff',
    '800': '#ffffff',
    '900': '#ffffff'
};
const alpha = {
    '50': base[50] + '1F',
    '100': base[100] + '1F',
    '200': base[200] + '1F',
    '300': base[300] + '1F',
    '400': base[400] + '1F',
    '500': base[500] + '1F',
    '600': base[600] + '1F',
    '700': base[700] + '1F',
    '800': base[800] + '1F',
    '900': base[900] + '1F'
};

export const blacks = {
    '50': base[50],
    '100': base[100],
    '200': base[200],
    '300': base[300],
    '400': base[400],
    '500': base[500],
    '600': base[600],
    '700': base[700],
    '800': base[800],
    '900': base[900],
    light: base[300],
    medium: base[500],
    dark: base[900],
    contrast,
    alpha
};
