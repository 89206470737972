import { colors } from './colors/index';

const { primaryAlpha, danger, alphaGrays, secondary } = colors || {};

// const shadows = {
//     '0': {
//         boxShadow: 'none'
//     },
//     '1': {
//         // boxShadow: `3px 3px 5px -1px ${colors.primaryAlpha['500']}`,
//         boxShadow: `0 3px 10px 1px ${primaryAlpha['500']},  0 3px 10px 1px ${primaryAlpha['500']}`
//     },
//     '2': {
//         // boxShadow: `5px 5px 7px -1px ${colors.primaryAlpha['500']}`
//         boxShadow: `0px 9px 30px 3px ${primaryAlpha['500']}, 0px 9px 30px 3px ${primaryAlpha['500']}`
//     },
//     '3': {
//         // boxShadow: `7px 7px 9px -1px ${colors.primaryAlpha['500']}`,
//         boxShadow: `0 18px 30px 3px ${primaryAlpha['500']},  0 18px 30px 3px ${primaryAlpha['500']}`
//     }
// };

const shadows = [
    'none',
    `0 3px 10px 1px ${primaryAlpha['500']},  0 3px 10px 1px ${primaryAlpha['500']}`,
    `0px 9px 30px 3px ${primaryAlpha['500']}, 0px 9px 30px 3px ${primaryAlpha['500']}`,
    `0 18px 30px 3px ${primaryAlpha['500']},  0 18px 30px 3px ${primaryAlpha['500']}`,
    `0px 0px 0px 2px ${danger['900']} inset,  0px 0px 0px 2px ${danger['900']} inset`
];

const fourAlphaGray = `0 0 0 4px ${alphaGrays['3']}`;
const fourSecondary = `0 0 0 4px ${secondary['100']}`;
const fourWhiteInset = `0 0 0 4px white inset`;

shadows.none = shadows[0];
shadows.sm = shadows[1];
shadows.md = shadows[2];
shadows.lg = shadows[3];
shadows.fourAlphaGray = fourAlphaGray;
shadows.fourSecondary = fourSecondary;
shadows.fourWhiteInset = fourWhiteInset;

export { shadows };
