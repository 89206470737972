
import newColors from './configs/colors.json'

const colors = { ...newColors }


export const elementsColor = {
    grid: {
        bg: colors.blue['500'] + '1f'
    },
    related: {
        color: colors.coral['500'],
        // bg: colors.coral['100'],
        bg: 'white',
        hover: {
            color: colors.coral['700']
        }
    },
    exact: {
        color: colors.blue['500'],
        lightColor: colors.blue['300'],
        lighterColor: colors.blue['200'],
        // bg: electricBlues['100'],
        bg: colors.smoke['100'],
        hover: {
            color: colors.blue['700']
        }
    },
    similar: {
        color: colors.yellow['500'],
        lightColor: colors.yellow['300'],
        lighterColor: colors.yellow['200'],
        // bg: colors.yellow['100'],
        bg: colors.smoke['100'],
        hover: {
            color: colors.yellow['700']
        }
    },
    translation: {
        color: colors.aqua['500'],
        bg: colors.grey['50'],
        hover: {
            color: colors.aqua['700']
        }
    },
    dictionary: {
        color: colors.blue['100'],
        lightColor: colors.grey['300'],
        bg: colors.grey['50'],
        hover: {
            color: colors.grey['700']
        }
    },
    idiom: {
        color: colors.purple['300'],
        lightColor: colors.purple['300'],
        bg: colors.grey['50'],
        hover: {
            color: colors.purple['700']
        }
    },
    set: {
        title: colors.coral['500'],
        lightTitle: colors.coral['100'],
        darkTitle: colors.coral['700'],
        text: colors.grey['700'],
        headerBg: colors.yellow['300'],
        bg: colors.smoke
    },
    paraphrase: {
        color: colors.salmon['500'],
        lightColor: colors.salmon['500'],
        lighterColor: colors.salmon['100'],
        darkerColor: colors.salmon['900'],
        bg: colors.smoke['100'],
        hover: {
            color: colors.salmon['900']
        }
    },
};

export const alternativeBtns = {
    dropDown: {
        background: colors.smoke['100'],
        boxShadow: "none",
        color: colors.grey['500'],
        opacity: 1,
        // pb: "4px",
        // pl: "16px",
        // pr: "16px",
        // pt: "4px",
    },

    dictionaryBtn: {
        background: '#ffffffff',
        boxShadow: "none",
        border : `1px solid ${colors.grey['500']}`,
        color: colors.grey['500'],
        opacity: 1,
        "&:hover": {
            color: '#ffffffff',
            background: colors.grey['500'],
        }
        // pb: "4px",
        // pl: "16px",
        // pr: "16px",
        // pt: "4px",
    },
    shortCut: {
        background: colors.blue['50'],
        color: colors.blue['500'],
        pl: "4px",
        pr: "4px",
    },
    textLink: {
        color: colors.blue['500'],
        p: 'none',
        mx: 'sm',
        fontWeight:'normal'
    }, 
};