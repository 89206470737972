// theme.js
import { breakpoints, sizes } from './breakpoints';
import { colors as oldColors} from './colors/index';
import { buttons as oldButtons, themeButtons } from './buttons';
import { elementsColor, alternativeBtns  } from './elementsColors';
import { borderRadii } from './borders';
import { space } from './space';
import { results } from './results';
import { shadows as oldShadows } from './shadows';
import {
    fonts,
    fontsSecondary,
    fontSizes,
    fontWeights,
    lineHeights
} from './fonts';

import newColors from './configs/colors.json'
import newButtons from './configs/buttons.json'
import newShadows from './configs/shadows.json'
import typography from './configs/typography.json'
import borders from './configs/borders.json'
import * as legacyIcons from './icons'
import icons from './configs/iconsPathSource.json'
import spaces from './configs/spaces.json'

const colors = { ...oldColors, ...newColors}
colors.secondary = colors.blue
colors.blues = newColors.blue
colors.yellows = newColors.yellow
colors.third = newColors.yellow
colors.sixth = newColors.purple
colors.fifth = newColors.aqua
colors.blacks[500] = newColors.blue[100]

const buttons = { ...oldButtons, ...alternativeBtns, ...newButtons}
const shadows = {...oldShadows,...newShadows}

buttons.third = buttons.SolidYellowMedium

shadows[1] = shadows.shadow_02
shadows[2] = shadows.shadow_04
shadows[3] = shadows.shadow_06
shadows.sm = shadows.shadow_02
shadows.md = shadows.shadow_12
shadows.lg = shadows.shadow_06

export const theme = {
    results,
    z: shadows,
    br: borderRadii,
    name: 'primary',
    lineHeights,
    fontSizes,
    fonts,
    fontsSecondary,
    fontWeights,
    breakpoints,
    sizes,
    space,
    spaceSteps: [1, 2, 2, 2, 3],
    bigSteps: [4, 5, 5, 5, 5, 5],
    largeSteps: [5, 6, 6, 6, 6],
    radii: borderRadii,

    colors,
    borders,
    icons,
    legacyIcons,
    buttons,
    typography,
    shadows,
    spaces,
    
    btns: themeButtons,
    ...elementsColor 
};

export default theme;

// export const secondaryTheme = {
//     ...theme,
//     name: 'secondary',
//     set: {
//         title: colors.secondary['500'],
//         lightTitle: colors.secondary['100'],
//         darkTitle: colors.secondary['700'],
//         text: colors.primaryGrays['700'],
//         headerBg: colors.primaryGrays['100'],
//         bg: colors.secondary['50']
//         // bg: '#e6f0ff'
//     }
// };

export const getThemeProperty = (group, property, index) => {
    if (theme && theme[group] && theme[group][property]) {
        return Number.isInteger(index) || index
            ? theme[group][property][index]
            : theme[group][property];
    } else if (theme && theme[group]) {
        return theme[group];
    } else {
        return '';
    }
};

export * from './buttons';
export * from './utils';
export * as iconsPaths from './icons/index';