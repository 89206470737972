import React from 'react'

import {Tapable} from '@ludwig/ui'

export const WinFullIcon = ({}) => {
  return (
    <Tapable as='svg' xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 12 12">
      <rect width="12" height="0.923077" fill="#BCBCBC"/>
      <rect y="0.923096" width="0.923077" height="10.1538" fill="#BCBCBC"/>
      <rect x="11.0771" y="0.923096" width="0.923077" height="10.1538" fill="#BCBCBC"/>
      <rect y="11.0769" width="12" height="0.923077" fill="#BCBCBC"/>
  </Tapable>
  )
}
