
import { Flex, styled, variant, useSelectors, useActions, Text, NavLink } from '@ludwig/ui'
import { respStyleObject } from '../theme'

import { Sidebar } from './desktop/Sidebar'
import { TitleBar } from './desktop/TitleBar'

export const BodyContainer = styled(Flex)``

BodyContainer.defaultProps = {
  height: '100%',
  flex: 1,
  overflow: 'hidden'
}

export const BodyColumn = styled(Flex)(
  props => `
  align-items: center;
`,
props => variant({
  prop: 'layout',
  variants: {
    default: {
      bg: 'smoke.50'
    },
    search: {
      bg: props.isCollapsed ? 'white' : 'smoke.50',
    },
    welcome: {
      bg: props.isCollapsed ? 'white' : 'smoke.50'
    },
    paraphrase: {
      bg: props.isCollapsed ? 'white' : 'smoke.50'
    }
  }
}))

BodyColumn.defaultProps = {
  layout: 'default',
  flexDirection: 'column',
  height: '100%',
  flex: 1,
  overflow: 'hidden'
}
export const BodyInnerColumn = styled(Flex)(variant({
  prop: 'layout',
  variants: {
    default: {
      height: '100%',
      maxWidth: '800px',
      overflow: 'hidden',
    },
    welcome: {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    search: {
      height: 'calc(100% - 25px)',
      alignItems: 'center'
    }, 
    paraphrase: {
      height: 'calc(100% - 25px)',
      overflow: 'overlay',
      alignItems: 'center'
    }
  }
}))

BodyInnerColumn.defaultProps = {
  layout: 'default',
  width: '100%',
  flex: 1,
  flexDirection: 'column'
}

export const ColumnContainer = styled(Flex)(
  props => variant({
    prop: 'layout',
    variants: {
      default: {
        width: '100%',
        overflowY: 'auto',
        overflow: 'overlay',
        boxShadow: 'shadow_12',
        borderRadius: '16px',
        // bg: 'white'
        // bg: 'smoke.100'
      },
      search: {
        width: '800px',
        maxWidth: '100%',
        bg: 'smoke.50'
      },
      paraphrase: {
        width: '800px',
        maxWidth: '100%',
        bg: 'smoke.50'
      },
      welcome: {
        overflow: 'overlay',
        width: '800px',
        maxWidth: '100%',
        boxShadow: 'shadow_12',
        borderRadius: '16px',
        mt: 'sm',
        bg: 'white',
        flex: '0 1 auto'
      }
    }
  }))

ColumnContainer.defaultProps = {
  bg: 'smoke.100',
  layout: 'default',
  flexDirection: 'column',
  flex: 1
}


export const ColumnWrapContainer = styled(Flex)(
  variant({
    prop: 'layout',
    variants: {
      default: {
        flex: 1,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        p: 'md',
        pt: '0'
      },
      search: {
        width: '100%',
        alignItems: 'center',
        maxWidth: '100%',
        overflow: 'overlay',
        flex: 1,
        pb: 'lg',
        px: respStyleObject({md: 'sm'}),
        WebkitAppRegion: 'no-drag',
        // WebkitUserSelect: none;
        WebkitUserDrag: 'none'
      },
      welcome: {
        height: 'calc(100% - 25px)',
        pb: 'md',
        width: '100%',
        alignItems: 'center',
        maxWidth: '100%',
        flex: 1,
        WebkitAppRegion: 'no-drag',
        // WebkitUserSelect: none;
        WebkitUserDrag: 'none'
      },
      maintenance:{
        p:0
      }
    }
  }))

ColumnWrapContainer.defaultProps = {
  layout: 'default',
  flexDirection: 'column'
}


export const DraggableWrapper = styled(Flex)`
  -webkit-app-region: drag;
  & .nodrag {
    -webkit-app-region: no-drag;
    // -webkit-user-select: none;
    -webkit-user-drag: none;
  }
`

export const MainLayout = ({ current, layout, children, ...props }) => {
  const { appData, location} = useSelectors() || {}
  const { collapsedWin, fullScreen } = appData || {}
  const isCollapsed = collapsedWin && !fullScreen

  return (<DraggableWrapper width="100%" height='100%' flexDirection='column' overflow='hidden' {...props} >

      <BodyContainer>
          <Sidebar current={current} />

          <BodyColumn layout={layout} isCollapsed={isCollapsed}>
              <TitleBar />
              <BodyInnerColumn className='nodrag' layout={layout}>
                  {/* nodrag in Welcome and Search  */}
                  {children}
              </BodyInnerColumn>
          </BodyColumn>
          

      </BodyContainer>

      {(!collapsedWin) && 
      <NewFeatureBanner/>}
      
  </DraggableWrapper>
  )
}


const BannerWrapper = styled(Flex)`
  cursor:pointer;
`

const NewFeatureBanner = ()=> {
  const { openUrl } = useActions() || {}
  const onHandleClick = () => {
    if(openUrl) openUrl({href:'https://v.ludwig.guru/chrome-extension_5'})
  }
  return (
    <BannerWrapper onClick={onHandleClick} className='nodrag' bg='yellow.500' justifyContent='center' p='xs'>
      <Text fontSize='14px' textAlign='center' >
        <b>Ludwig is now on Chrome. </b>
        <span>Your favorite writing assistant works with your favorite browser. </span>
        <u>Get it now!</u>
      </Text>
    </BannerWrapper>
  )
}