import { useState } from 'react'
import { Flex, NavLink } from '@ludwig/ui'
import { General } from './settings/General'
import { Feedback } from './settings/Feedback'
import { WhatsNew } from './settings/WhatsNew'
// import { Account } from './settings/Account'
import { Tour } from './settings/Tour'
import { Text } from './common/LudwigText'
import { ColumnContainer, ColumnWrapContainer } from '../Wrappers'


const sections = [
    {key: 'general', label: 'General', icon:'settings', active:false},
    //{key: 'account', label: 'Account', icon:'account', active:false},
    //{key: 'info', label: 'How to use', icon:'question', active:false},
    {key: 'feedback', label: 'Contact Us', icon:'feedback', active:false},
    {key: 'tour', label: 'How It Works', icon:'feedback', active:false},
    {key: 'whatsnew', label: 'What\'s New!', icon:'news', active:false}
]


const TabItem = ({section, handleSelection}) => {
    const bg = section.active ? 'white' : 'none'
    return(
        <Flex p='sm' px='md' bg={bg} style={{userSelect: 'none'}}
            borderRadius='sm' borderBottomLeftRadius='none' borderBottomRightRadius='none' >
            <NavLink onClick={() => handleSelection(section)}>
                <Text textId={section.label} variant='strong_s' color='blue.500' />
            </NavLink>
        </Flex>
    )
}

const TabBar = ({current, change, ...props}) => {

    sections.forEach(s => {
        s.active = false
        if(current === s.key) s.active = true
    })

    const handleSectionChange = section => {
        const { key } = section || {}
        change(key)
    }

    return(
        <Flex width='100%' {...props}>
            {sections.map(s => (
                <TabItem key={s.key} section={s} handleSelection={handleSectionChange} />
            ))}
        </Flex>
    )
}

export const SettingsContainer = () => {

    const [section, setSection] = useState('general')

    const topLeftRad = section === 'general' ? '0px' : '16px'

    return (
        <ColumnWrapContainer>
            <TabBar className='nodrag' current={section} change={setSection} />
            <ColumnContainer className='nodrag' style={{borderTopLeftRadius: topLeftRad}} bg='white'>
                    
                <Flex className='nodrag'>
                    {section === 'general' && <General />}
                    {section === 'feedback' && <Feedback />}
                    {section === 'tour' && <Tour />}
                    {section === 'whatsnew' && <WhatsNew />}
                </Flex>
            </ColumnContainer>
        </ColumnWrapContainer>

    )
}


