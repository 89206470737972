import { LibraryView } from '@ludwig/library'
import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'

export const Library = () => {
    return (
        <ColumnWrapContainer>
            <ColumnContainer bg='white' className='nodrag'>
                <LibraryView />
            </ColumnContainer>
        </ColumnWrapContainer>
    )
}
