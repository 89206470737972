import React from 'react'
import { Flex, useActions, InputHeader, useSelectors } from '@ludwig/ui'

export const SearchBoxLight = () => {

    const {info, appData} = useSelectors() || {}
    const { userSetFocus } = useActions()

    const { collapsedWin, fullScreen } = appData || {}

    const interceptInteraction = e => {
        const isRightElement = ! (e.currentTarget === e.target)
        if(userSetFocus) userSetFocus({focusIs: isRightElement})
    }
    const isCollapsed = collapsedWin && !fullScreen

    const wrapperProps = !isCollapsed ? {
        borderBottom: '1px solid',
        borderColor: 'smoke.100',
        px: {ms: 'sm'}
    } : {}

    return (
        <Flex {...wrapperProps} className='nodrag' width='100%' zIndex='2' position='sticky' top='0' justifyContent='center'>
            <Flex onClick={interceptInteraction} maxWidth='100%' width='800px' height='100%' flexDirection='column' justifyContent='center' position='relative'>
                <InputHeader place='desktop' isCompactView={isCollapsed} sentence={info.sentence} handleInputOpen={()=>{}} />
            </Flex>
        </Flex>
    )
}