import { searchCore, contentCore, userCore, actions as allActions, libraryCore, trackActions } from '@ludwig/core'
import { actions as libraryActions } from '@ludwig/library'

// const { actions: userActions } = userCore || {} // empty
const { actions: searchActions } = searchCore || {}
const { actions: contentActions } = contentCore || {}
const { actions: libraryActionsCode } = libraryCore || {}

const {
    dispatchClick,
    sendEvent
} = trackActions || {}

export {
    dispatchClick,
    sendEvent
}

// login is not present in userCore, we use allActions
const { loginStart, goToPage, getProfile } = allActions
export { loginStart, goToPage, getProfile }

const { getStreamToken } = libraryActionsCode
export { getStreamToken }

const {
    changeEditorFocusState,
    changeEditorState,
    deleteHistory,
    deleteHistoryError,
    deleteHistorySentence,
    deleteHistorySuccess,
    fixTypo,
    getAutoComplete,
    getFilterSources,
    getHistory,
    getSentences,
    getTranslation,
    hideSuggestions,
    resetInput,
    showSuggestions,
    toggleAllSources,
    toggleCat,
    toggleSource,
    updateFilter,
    updateInput
} = searchActions || {}

export {
    changeEditorFocusState,
    changeEditorState,
    deleteHistory,
    deleteHistoryError,
    deleteHistorySentence,
    deleteHistorySuccess,
    fixTypo,
    getAutoComplete,
    getFilterSources,
    getHistory,
    getSentences,
    getTranslation,
    hideSuggestions,
    resetInput,
    showSuggestions,
    toggleAllSources,
    toggleCat,
    toggleSource,
    updateFilter,
    updateInput
}

const {
    deleteWebclip,
    saveSentence,
    saveWebclip
} = contentActions || {}

export {
    deleteWebclip,
    saveSentence,
    saveWebclip
}

const {
    getLibraryFS,
    refreshLibraryFS,
    updateLibrary,
    createFolder,
    renameFolder,
    deleteDocument,
    deleteFolder,
    uploadFile,
    createDocument,
    getDocument,
    editDocument,
    getTags,
    getDocumentUrl
} = libraryActions || {}

export {
    getLibraryFS,
    refreshLibraryFS,
    updateLibrary,
    createFolder,
    renameFolder,
    deleteDocument,
    deleteFolder,
    uploadFile,
    createDocument,
    getDocument,
    editDocument,
    getTags,
    getDocumentUrl
}

export {
    updateUser
} from './user'

export {
    setSection,
    togglePinWindow,
    collapseExpandWindow,
    userSearchFocus,
    sendMessage
} from './desktop'
