import React from 'react'
import {styled} from '@ludwig/ui'
import { Text } from '../../common/LudwigText'

const BaseKbd = styled(Text)`
  align-items: center;
  padding: 0.3em 0.5em;
  border-radius: 0.4em;
  cursor: pointer;
  font-weight: 700;
  user-select: none;
  color: ${props => props.theme.colors.grey[200]};
`
BaseKbd.defaultProps = {
  variant: 'body_s' 
}

const getMetaKey = keyLetter => {
  switch (keyLetter.toLowerCase()) {
    case 'cmd':
      return `⌘ ${keyLetter}`;
      case 'alt':
        return `⌥ ${keyLetter}`;
        case 'shift':
      return `⇧ ${keyLetter}`;
    default:
      return keyLetter
  }
}


export const Kbd = props => {
  const { keyLetter } = props || {}
  return <BaseKbd>{getMetaKey(keyLetter)}</BaseKbd>
}
