const space = [0, 4, 8, 12, 16, 24, 32, 64];

space.none = 0;
space.xs = space[1];
space.sm = space[2];
space.ms = space[3];
space.md = space[4];
space.lg = space[5];
space.l = space[5];
space.xl = space[6];
space.xxl = space[7];

export { space };
