// theme.js

import { darken, lighten, modularScale } from 'polished';

// COLORS GENERATED http://mcg.mbitson.com/#!?mcgpalette0=%237edae4

import { blacks } from './blacks'; // Latest Scheme
import { grays as newGrays } from './newGrays'; // Latest Scheme
import { blues } from './blues'; // Latest Scheme
import { corals } from './corals'; // Latest Scheme
import { yellows as electricYellows } from './electricYellows'; // Latest Scheme
import { teals } from './teals'; // Latest Scheme

import { yellows } from './yellows';
import { pinks } from './pinks';
import { redines } from './redines';
import { azures } from './azures';
import { grays } from './grays';
import { purples } from './purples';
import { greens } from './greens';

const colors = {
    primary: {
        ...blacks
    },
    primaryContrast: {
        ...blacks.contrast
    },
    primaryContrastAlpha: {
        ...blacks.contrast
    },
    primaryLight: {
        ...blacks
    },
    primaryWhite: {
        ...blacks.contrast
    },
    primaryAlpha: {
        ...blacks.alpha
    },
    primaryOutline: {
        ...blacks
    },
    secondary: {
        ...blues
    },
    secondaryContrast: {
        ...blues.contrast
    },
    secondaryLight: {
        ...blues
    },
    secondaryWhite: {
        ...blues.contrast
    },
    secondaryAlpha: {
        ...blues.alpha
    },
    secondaryOutline: {
        ...blues
    },
    third: {
        ...electricYellows
    },
    thirdContrast: {
        ...electricYellows.contrast
    },
    thirdAlpha: {
        ...electricYellows.alpha
    },
    thirdVariant: {
        ...yellows
    },
    danger: {
        ...redines
    },
    primaryGrays: {
        ...grays
    },
    secondaryGrays: {
        ...newGrays
    },
    shaded: {
        ...newGrays
    },
    fourth: {
        ...corals
    },
    fourthContrast: {
        ...corals.contrast
    },
    fourthAlpha: {
        ...corals.alpha
    },
    fifth: {
        ...teals
    },
    fifthContrast: {
        ...teals.contrast
    },
    sixth: {
        ...pinks
    },
    sixthContrast: {
        ...pinks.contrast
    }
};

const themeColors = {
    // black: blacks['500'],
    blacks: [
        blacks['100'],
        blacks['300'],
        blacks['500'],
        blacks['700'],
        blacks['900']
    ],
    blacksContrasts: [
        blacks.contrast['100'],
        blacks.contrast['300'],
        blacks.contrast['500'],
        blacks.contrast['700'],
        blacks.contrast['900']
    ],
    white: '#fff',
    blue: blues['500'],
    blues: [
        blues['100'],
        blues['300'],
        blues['500'],
        blues['700'],
        blues['900']
    ],
    blueGr: blues['200'],
    azure: azures['500'],
    azures: [
        azures['100'],
        azures['300'],
        azures['500'],
        azures['700'],
        azures['900']
    ],
    redine: redines['500'],
    redines: [
        redines['100'],
        redines['300'],
        redines['500'],
        redines['700'],
        redines['900']
    ],
    redineGr: electricYellows['400'],
    yellow: yellows['500'],
    yellows: [
        yellows['100'],
        yellows['300'],
        yellows['500'],
        yellows['700'],
        yellows['900']
    ],
    yellowGr: `linear-gradient(to right, ${yellows['200']} 0%, ${yellows['400']} 100%)`,
    purple: '#f5acd8',
    purples: [
        purples['100'],
        purples['300'],
        purples['500'],
        purples['700'],
        purples['900']
    ],
    pink: '#f34f9f',
    pinks: [
        pinks['100'],
        pinks['300'],
        pinks['500'],
        pinks['700'],
        pinks['900']
    ],
    green: greens['500'],
    greens: [
        greens['100'],
        greens['300'],
        greens['500'],
        greens['700'],
        greens['900']
    ],
    gray: '#4a4a4a',
    grays: [
        grays['100'],
        grays['300'],
        grays['500'],
        grays['700'],
        grays['900']
    ],
    grayGr: `linear-gradient(to right, whitesmoke 0%, ${grays['100']} 100%)`,
    whiteGray: 'whitesmoke',
    gGray: '#3C4043',
    whiteGrays: [
        '#fafafa',
        lighten(0.05, 'whitesmoke'),
        'whitesmoke',
        darken(0.05, 'whitesmoke'),
        darken(0.2, 'whitesmoke')
    ],
    alphaGray: 'rgba(0, 0, 0, 0.12)',
    alphaGrays: [
        'rgba(0, 0, 0, 0.04)',
        'rgba(0, 0, 0, 0.07)',
        'rgba(0, 0, 0, 0.12)',
        'rgba(0, 0, 0, 0.22)',
        'rgba(0, 0, 0, 0.42)'
    ],
    alphaSmoke: 'rgba(245, 245, 245, 0.95)',
    lightSmoke: '#fafafa',
    alphaWhite: 'rgba(255, 255, 255, 0.85)',
    facebook: '#1877F2',
    // facebook: '#3B5998',
    facebookHighlight: '#133783',
    twitter: '#2daddc',
    twitterHighlight: '#0271bf',
    google: '#4285F4',
    googleHighlight: '#3367D6',
    github: '#fafafa',
    githubHighlight: '#ccc',
    electricBlues: blues,
    teals,
    ...colors
};

const elements = {
    grid: {
        bg: colors.secondary['500'] + '1f'
    },
    related: {
        color: colors.fourth['500'],
        // bg: colors.fourth['100'],
        bg: 'white',
        hover: {
            color: colors.fourth['700']
        }
    },
    exact: {
        color: colors.secondary['500'],
        lightColor: colors.secondary['300'],
        lighterColor: colors.secondary['200'],
        // bg: electricBlues['100'],
        bg: colors.secondaryGrays['50'],
        hover: {
            color: colors.secondary['700']
        }
    },
    similar: {
        color: colors.third['500'],
        lightColor: colors.third['300'],
        lighterColor: colors.third['200'],
        // bg: colors.third['100'],
        bg: colors.secondaryGrays['50'],
        hover: {
            color: colors.third['700']
        }
    },
    translation: {
        color: colors.fifth['500'],
        bg: colors.secondaryGrays['50'],
        hover: {
            color: colors.fifth['700']
        }
    },
    dictionary: {
        color: colors.primary['500'],
        lightColor: colors.primary['300'],
        bg: colors.secondaryGrays['50'],
        hover: {
            color: colors.primary['700']
        }
    },
    idiom: {
        color: colors.sixth['500'],
        lightColor: colors.sixth['300'],
        bg: colors.secondaryGrays['50'],
        hover: {
            color: colors.sixth['700']
        }
    },
    set: {
        title: colors.fourth['500'],
        lightTitle: colors.fourth['100'],
        darkTitle: colors.fourth['700'],
        text: colors.primaryGrays['700'],
        headerBg: colors.thirdVariant['300'],
        bg: 'whitesmoke'
    }
};

export { themeColors as primaryColors, elements as primaryElements };
