import {  Flex } from '@ludwig/ui'

import { Tour } from './settings/Tour'

import { ColumnContainer, DraggableWrapper } from '../Wrappers'

export const First = ({data}) => {
    // const {appData} = useSelectors() || {}
    // const { collapsedWin } = appData || {}

    return (
        <DraggableWrapper layout='welcome' justifyContent='center'>
            
                <Flex px='md'>
                    <ColumnContainer layout='welcome'>
                        <Tour guestMode='true' />
                    </ColumnContainer>
                </Flex>
            
        </DraggableWrapper>
    )
}

