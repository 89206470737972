import React, {useState, useRef, useEffect} from 'react';
import Lottie from 'react-lottie-light2'

const defaultOptions = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        progressiveLoad: false
    }
}

//const eventListeners = {
    // /** triggered only if loop is set to true */
    // loopCompleted: (data) => {
    //     log.debug('Animation Loop Completed - Data: ', data);
    // },
    // /** triggered when animation is destroyed */
    // destroy: (data) => {
    //     log.debug('Animation Destroyed - Data: ', data);
    // },
    // /** triggered when loop is set to false */
    // complete: (data) => {
    //     log.debug('Animation Complete - Data: ', data);
    // },
//};


export const Animation = ({ animationData, options = defaultOptions, ...props }) => {
    
    return <Lottie
        options={{
            ...defaultOptions, 
            animationData,
        }}
        height='initial'
    />
};

export default Animation;
