import { Flex, ActionButton, ActionLink } from '@ludwig/ui'
import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'
import { Text } from '../common/LudwigText'

const gettingStartedBtn = [
    {key:'translate-start-cn', label:'扩至全世界', path:'/translation/chinese+simplified-english/扩至全世界'},
    {key:'translate-start-tr', label:'Bir taşla iki kuş vurmak', path:'/translation/turkish-english/Bir taşla iki kuş vurmak'},
    {key:'translate-start-de', label:`Die Katze im Sack kaufen`, path:`/translation/german-english/Die Katze im Sack kaufen`},
    {key:'translate-start-ja', label:'一石二鳥', path:'/translation/japanese-english/一石二鳥'},
    {key:'translate-start-fr', label:'il pleut des cordes', path:'/translation/french-english/il pleut des cordes'},
    {key:'translate-start-kr', label:`식은 죽 먹기`, path:`/translation/korean-english/식은 죽 먹기`},
    {key:'translate-start-es', label:'cuando el rio suena piedras trae', path:'/translation/spanish-english/cuando el rio suena piedras trae'}
]

export const Translate = ({data}) => {

    return (
        <ColumnWrapContainer>
            <ColumnContainer bg='white' className='nodrag'>
                
                <Flex alignItems='center' width='100%' flex='1' flexDirection='column' overflowY='auto' margin='auto'>  
                    <Flex p='lg' flexDirection='column' mt='auto' >
                        <Text textAlign='center' pb='5px' fontSize='xl' fontWeight='700'>
                            Translate in English<br></br>with confidence
                        </Text>
                    </Flex>
                    
                    <Flex flexWrap='wrap' justifyContent='center' width= '400px'>
                        
                        {gettingStartedBtn.map(d => (
                            <ActionLink variant='SolidLightMedium' m='sm' track={{label: d.key}} href={d.path}>
                                <Text textId={d.label}/>
                            </ActionLink>
                        ))}
                        

                    </Flex>  

                    <Text textAlign='center' p='lg'  > 
                        
                        Search Ludwig in any language<br></br>
                        Get a contextualized English translation<br></br>
                        Make sure that what you write is aligned with what you think<br></br>
                        
                    </Text>
                    
                    <Flex flexDirection='column' py='lg' mb='auto'>
                        <ActionLink variant='SolidSalmonMedium' track={{label: 'translate-start'}} href='/translation/italian-english/provare+per+credere'>
                            <Text textId='Translate in English now'/>
                        </ActionLink>
                    </Flex>
                </Flex>
                
            </ColumnContainer>
        </ColumnWrapContainer>
    )
}

