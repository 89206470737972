import React from 'react'

import {Tapable} from '@ludwig/ui'

export const WinMinimizeIcon = ({}) => {
  return (
    <Tapable  as='svg' xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 12 12">
      <rect y="5.54004" width="12" height="0.92" fill="#BCBCBC"/>
  </Tapable>
  )
}
