import { log } from '@ludwig/utilities';

const appCss = (theme) => {
    log.debug('Theme: ', theme)
    return `
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gelasio:ital,wght@0,600;1,600&display=swap');
    body,
    html {
        height: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        /* to avoid scrollbars */
        -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
        font-family: ${theme.fonts.sansSerif};
        background: white;
    }

    #root {
        overflow: hidden;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    a {
        -webkit-appearance: none;
        text-decoration: none;
        color: inherit;
        cursor: inherit;
        > svg {
            pointer-events: none;
        }
    }

    hr {
        margin: 0.5em 0;
        border: 0;
        border-top: 1px solid ${theme.colors.whiteGray};
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    ::-webkit-scrollbar {
        width: 10px;
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background: ${theme.colors.lightSmoke};
        background: none;
    }

    ::-webkit-scrollbar-thumb {
        min-height: 2rem;
        background: rgba(0, 0, 0, 0.3);
        background-clip: padding-box;
        border: 3px solid rgba(255, 255, 255, 0);
        border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.5);
        border: 2px solid rgba(255, 255, 255, 0);
    }

    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.05);
    }

    ::-webkit-input-placeholder,
    :-moz-placeholder,
    :-ms-input-placeholder,
    ::-moz-placeholder {
        /* Firefox 18- */
        font-weight: 300;
    }
`};

export default appCss;
