export const arrowRight =
    'M6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 L15.5040084,11 L13.1270042,8.62299579 C12.7364799,8.2324715 12.7364799,7.59930652 13.1270042,7.20878223 C13.5175285,6.81825794 14.1506935,6.81825794 14.5412178,7.20878223 L18.7094397,11.3770042 C19.099964,11.7675285 19.099964,12.4006935 18.7094397,12.7912178 L14.5412178,16.9594397 C14.1506935,17.349964 13.5175285,17.349964 13.1270042,16.9594397 C12.7364799,16.5689155 12.7364799,15.9357505 13.1270042,15.5452262 L15.6722304,13 L6,13 Z';
export const arrowLeft =
    'M18.002333,11.168222 C18.5546177,11.168222 19.002333,11.6159372 19.002333,12.168222 C19.002333,12.7205067 18.5546177,13.168222 18.002333,13.168222 L8.49832455,13.168222 L10.8753288,15.5452262 C11.2658531,15.9357505 11.2658531,16.5689155 10.8753288,16.9594397 C10.4848045,17.349964 9.85163949,17.349964 9.4611152,16.9594397 L5.29289322,12.7912178 C4.90236893,12.4006935 4.90236893,11.7675285 5.29289322,11.3770042 L9.4611152,7.20878223 C9.85163949,6.81825794 10.4848045,6.81825794 10.8753288,7.20878223 C11.2658531,7.59930652 11.2658531,8.2324715 10.8753288,8.62299579 L8.33010257,11.168222 L18.002333,11.168222 Z';

export const arrowNW =
    'M15,6 C15,6.55228475 14.5522847,7 14,7 L8.414,7 L8.414,7 L16.6568458,15.2426491 C17.0473832,15.6331603 17.0473908,16.2663253 16.6568711,16.6568542 C16.6568683,16.6568571 16.6568655,16.6568599 16.6568542,16.6568542 C16.2663384,17.047387 15.6331734,17.047387 15.2426491,16.6568627 C15.2426463,16.6568599 15.2426435,16.6568571 15.2426491,16.6568458 L7,8.414 L7,8.414 L7,14 C7,14.5522847 6.55228475,15 6,15 C5.44771525,15 5,14.5522847 5,14 L5,6 C5,5.44771525 5.44771525,5 6,5 L14,5 C14.5522847,5 15,5.44771525 15,6 Z';

export const chevronLeft =
    'M14.6931372,6.32789827 C15.0866995,6.77895053 15.1175844,7.40690631 14.6931372,7.95830735 L11.3866848,12.0012253 L14.6931372,16.0441432 C15.1175844,16.5955443 15.0866995,17.2245346 14.6931372,17.6724833 C14.3004574,18.1235355 13.6368726,18.0945689 13.2680183,17.6724833 C12.8991639,17.2524667 9.29533373,12.8153953 9.29533373,12.8153953 C9.10660324,12.601908 9,12.307653 9,12.0001908 C9,11.6927286 9.10660324,11.3984735 9.29533373,11.1849862 C9.29533373,11.1849862 12.8991639,6.74998387 13.2680183,6.32789827 C13.6368726,5.90477815 14.3004574,5.87684601 14.6931372,6.32789827 Z';

export const chevronDown =
    'M6.32789827,9.30686279 C6.77895053,8.91330053 7.40690631,8.8824156 7.95830735,9.30686279 L12.0012253,12.6133152 L16.0441432,9.30686279 C16.5955443,8.8824156 17.2245346,8.91330053 17.6724833,9.30686279 C18.1235355,9.69954262 18.0945689,10.3631274 17.6724833,10.7319817 C17.2524667,11.1008361 12.8153953,14.7046663 12.8153953,14.7046663 C12.601908,14.8933968 12.307653,15 12.0001908,15 C11.6927286,15 11.3984735,14.8933968 11.1849862,14.7046663 C11.1849862,14.7046663 6.74998387,11.1008361 6.32789827,10.7319817 C5.90477815,10.3631274 5.87684601,9.69954262 6.32789827,9.30686279 Z';
export const chevronRight =
    'M9.30686279,17.6721017 C8.91330053,17.2210495 8.8824156,16.5930937 9.30686279,16.0416927 L12.6133152,11.9987747 L9.30686279,7.95585677 C8.8824156,7.40445573 8.91330053,6.77546543 9.30686279,6.32751674 C9.69954262,5.87646448 10.3631274,5.90543114 10.7319817,6.32751674 C11.1008361,6.74753329 14.7046663,11.1846047 14.7046663,11.1846047 C14.8933968,11.398092 15,11.692347 15,11.9998092 C15,12.3072714 14.8933968,12.6015265 14.7046663,12.8150138 C14.7046663,12.8150138 11.1008361,17.2500161 10.7319817,17.6721017 C10.3631274,18.0952219 9.69954262,18.123154 9.30686279,17.6721017 Z';

export const chevronTop =
    'M17.6721017,14.6931372 C17.2210495,15.0866995 16.5930937,15.1175844 16.0416927,14.6931372 L11.9987747,11.3866848 L7.95585677,14.6931372 C7.40445573,15.1175844 6.77546543,15.0866995 6.32751674,14.6931372 C5.87646448,14.3004574 5.90543114,13.6368726 6.32751674,13.2680183 C6.74753329,12.8991639 11.1846047,9.29533373 11.1846047,9.29533373 C11.398092,9.10660324 11.692347,9 11.9998092,9 C12.3072714,9 12.6015265,9.10660324 12.8150138,9.29533373 C12.8150138,9.29533373 17.2500161,12.8991639 17.6721017,13.2680183 C18.0952219,13.6368726 18.123154,14.3004574 17.6721017,14.6931372 Z';

export const expand =
    'M10.1071429,12.1305804 C10.1941964,12.1305804 10.2712054,12.1640625 10.3381696,12.2310268 L11.4832589,13.3761161 C11.5502232,13.4430804 11.5837054,13.5200893 11.5837054,13.6071429 C11.5837054,13.6941964 11.5502232,13.7712054 11.4832589,13.8381696 L8.1484375,17.1729911 L9.59486607,18.6194196 C9.72209821,18.7466518 9.78571429,18.8973214 9.78571429,19.0714286 C9.78571429,19.2455357 9.72209821,19.3962054 9.59486607,19.5234375 C9.46763393,19.6506696 9.31696429,19.7142857 9.14285714,19.7142857 L4.64285714,19.7142857 C4.46875,19.7142857 4.31808036,19.6506696 4.19084821,19.5234375 C4.06361607,19.3962054 4,19.2455357 4,19.0714286 L4,14.5714286 C4,14.3973214 4.06361607,14.2466518 4.19084821,14.1194196 C4.31808036,13.9921875 4.46875,13.9285714 4.64285714,13.9285714 C4.81696429,13.9285714 4.96763393,13.9921875 5.09486607,14.1194196 L6.54129464,15.5658482 L9.87611607,12.2310268 C9.94308036,12.1640625 10.0200893,12.1305804 10.1071429,12.1305804 Z M18.7857143,4.28571429 C18.9598214,4.28571429 19.1104911,4.34933036 19.2377232,4.4765625 C19.3649554,4.60379464 19.4285714,4.75446429 19.4285714,4.92857143 L19.4285714,9.42857143 C19.4285714,9.60267857 19.3649554,9.75334821 19.2377232,9.88058036 C19.1104911,10.0078125 18.9598214,10.0714286 18.7857143,10.0714286 C18.6116071,10.0714286 18.4609375,10.0078125 18.3337054,9.88058036 L16.8872768,8.43415179 L13.5524554,11.7689732 C13.4854911,11.8359375 13.4084821,11.8694196 13.3214286,11.8694196 C13.234375,11.8694196 13.1573661,11.8359375 13.0904018,11.7689732 L11.9453125,10.6238839 C11.8783482,10.5569196 11.8448661,10.4799107 11.8448661,10.3928571 C11.8448661,10.3058036 11.8783482,10.2287946 11.9453125,10.1618304 L15.2801339,6.82700893 L13.8337054,5.38058036 C13.7064732,5.25334821 13.6428571,5.10267857 13.6428571,4.92857143 C13.6428571,4.75446429 13.7064732,4.60379464 13.8337054,4.4765625 C13.9609375,4.34933036 14.1116071,4.28571429 14.2857143,4.28571429 L18.7857143,4.28571429 Z';

export const compress =
    'M11.7142857,12.6428571 L11.7142857,17.1428571 C11.7142857,17.3169643 11.6506696,17.4676339 11.5234375,17.5948661 C11.3962054,17.7220982 11.2455357,17.7857143 11.0714286,17.7857143 C10.8973214,17.7857143 10.7466518,17.7220982 10.6194196,17.5948661 L9.17299107,16.1484375 L5.83816964,19.4832589 C5.77120536,19.5502232 5.69419643,19.5837054 5.60714286,19.5837054 C5.52008929,19.5837054 5.44308036,19.5502232 5.37611607,19.4832589 L4.23102679,18.3381696 C4.1640625,18.2712054 4.13058036,18.1941964 4.13058036,18.1071429 C4.13058036,18.0200893 4.1640625,17.9430804 4.23102679,17.8761161 L7.56584821,14.5412946 L6.11941964,13.0948661 C5.9921875,12.9676339 5.92857143,12.8169643 5.92857143,12.6428571 C5.92857143,12.46875 5.9921875,12.3180804 6.11941964,12.1908482 C6.24665179,12.0636161 6.39732143,12 6.57142857,12 L11.0714286,12 C11.2455357,12 11.3962054,12.0636161 11.5234375,12.1908482 C11.6506696,12.3180804 11.7142857,12.46875 11.7142857,12.6428571 Z M19.2979911,5.89285714 C19.2979911,5.97991071 19.2645089,6.05691964 19.1975446,6.12388393 L15.8627232,9.45870536 L17.3091518,10.9051339 C17.4363839,11.0323661 17.5,11.1830357 17.5,11.3571429 C17.5,11.53125 17.4363839,11.6819196 17.3091518,11.8091518 C17.1819196,11.9363839 17.03125,12 16.8571429,12 L12.3571429,12 C12.1830357,12 12.0323661,11.9363839 11.9051339,11.8091518 C11.7779018,11.6819196 11.7142857,11.53125 11.7142857,11.3571429 L11.7142857,6.85714286 C11.7142857,6.68303571 11.7779018,6.53236607 11.9051339,6.40513393 C12.0323661,6.27790179 12.1830357,6.21428571 12.3571429,6.21428571 C12.53125,6.21428571 12.6819196,6.27790179 12.8091518,6.40513393 L14.2555804,7.8515625 L17.5904018,4.51674107 C17.6573661,4.44977679 17.734375,4.41629464 17.8214286,4.41629464 C17.9084821,4.41629464 17.9854911,4.44977679 18.0524554,4.51674107 L19.1975446,5.66183036 C19.2645089,5.72879464 19.2979911,5.80580357 19.2979911,5.89285714 Z';
