import {  Flex } from '@ludwig/ui'

import { Tour } from '../settings/Tour'
import { SearchBox } from './SearchBox'

import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'

export const Welcome = ({data}) => {

    return (
        <ColumnWrapContainer layout='welcome'>
        
            <SearchBox />

            <Flex px='md'>
                <ColumnContainer layout='welcome'>
                    <Tour />
                </ColumnContainer>
            </Flex>
            
        </ColumnWrapContainer>
    )
}

