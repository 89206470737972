import React, { useEffect, useRef, createContext, useState, useContext } from "react";
import { Text } from "@ludwig/ui";
import { Toast } from "../components/Toast";
import log from "../utilities/logger";

// const styles = {
//   position: 'absolute',
//   bottom: '0px',
//   right: '0px',
//   padding: '1em',
//   flexDirection: 'column',
//   alignItems: 'flex-end',
//   gap: '10px'
// }

const stylesString = `display:flex;position:absolute;bottom:20px;right:20px;flex-direction:column;align-items:flex-end;gap:10px;`

const defaultContextValue = {};

export const Context = createContext(defaultContextValue);

export const ToastsProvider = props => {
  const {
    children,
    rootId = 'l-toasts'
  } = props;

  const targetRef = useRef(null)

  const [toasts, setToasts] = useState([]);

  const closeToast = (toastId) => {
    log.debug('TOASTS - Close Toast - Id: ', toastId)
    setToasts(prevState => prevState.filter(({ id }) => toastId !== id))
  }
  const closeAllToasts = () => {
    setToasts(prevState => [])
  }

  const addToast = (toast) => {
    log.debug('TOASTS - Add Toast: ', toast)
    log.debug('TOASTS - Add Toast - Toasts: ', toasts)
    const { id: toastId } = toast || {}
    if (toasts.find(({ id }) => toastId === id)) {
      return;
    } else {
      setToasts(prevState => prevState.concat([toast]))
    }

  }

  const toastsContext = {
    toasts,
    addToast,
    closeToast,
    closeAllToasts,
    targetRef
  };

  useEffect(() => {

    let node = document.querySelector(`#${rootId}`);
    if (!node) {
      node = document.createElement('div')
      node.setAttribute('id', rootId)
      node.setAttribute('style', stylesString)
      document.body.appendChild(node)
    }
    targetRef.current = node;
    return () => document.body.removeChild(node)

  }, [])

  return (
    <Context.Provider value={toastsContext}>
      {children}
      {
        toasts.map(({ textId, id, ...toast }, index) => (
          <Toast target={targetRef.current} key={id} id={id} handleClose={closeToast} {...toast}><Text textId={textId} /></Toast>
        ))
      // createPortal(<Toast target={targetRef.current} key={index} handleClose={closeToast} {...toast}><Text textId={textId} /></Toast>, targetRef.current))
      }
    </Context.Provider>
  );
};

export const useToasts = () => {
  const context = useContext(Context);
  if (context === defaultContextValue) {
    throw new Error('useToastsContext must be used within ToastContextProvider');
  }
  const { toasts, addToast, closeToast, closeAllToasts, targetRef } = context || {}
  return { toasts, addToast, closeToast, closeAllToasts, targetRef };
};


export const { Consumer: ToastConsumer } = Context;
