import React, { useState } from 'react'
import { Tooltip, Box, Flex, useLocation, useActions, useSystem, styled, useSelectors } from '@ludwig/ui'
import { WinCommands } from './common/WinCommands'
import { QuickToggle } from './common/LudwigQuickToggle'

const sidebarSteps = ['none', 'narrow', 'large']
const sidebarStepTips = ['Show Sidebar', 'Expand Sidebar', 'Hide Sidebar']


const Bruschetta = styled('svg')`
    &:hover > path:nth-of-type(2){
        fill: #E0E0E0;
    }
`

export const TitleBar = React.memo(() => {

    const { theme, appData } = useSelectors() || {}
    const { toggleStayOnTop, toggleCollapseWin, changeSidebarState } = useActions()
    const {isMac, isWin} = useSystem() // {isMac:false, isWin:true} //

    const { stayOnTop, collapsedWin, sidebarState = 'large', fullScreen } = appData || {}

    const padBruschetta = sidebarState === 'none' ? 'sm' : 'md'

    const [prevSidebarState, setPrevSidebarState] = useState(sidebarState)

    const bruschettaTip = sidebarStepTips[sidebarSteps.indexOf(sidebarState)]

    const { prev } = useLocation()

    const handleTogglePinWindow = (e) => {
        toggleStayOnTop && toggleStayOnTop({ stayOnTop: !stayOnTop })
    }

    const handleCollapseExpandWindow = (e) => {
        toggleCollapseWin && toggleCollapseWin({ collapsedWin: !collapsedWin, prev, sidebarState, prevSidebarState })
        setPrevSidebarState(sidebarState)
    }

    const handleSidebaStepChange = () => {
        let idx = sidebarSteps.indexOf(sidebarState)
        idx++
        if (idx >= sidebarSteps.length) idx = 0
        changeSidebarState && changeSidebarState({ sidebarState: sidebarSteps[idx], collapsedWin, prev })
    }

    return (
        <Flex height='25px' width='100%' >
            {(isMac && sidebarState === 'none') && <WinCommands pl='xs' className='nodrag' />}

            <Flex flex='1' ml={padBruschetta}>
                <Tooltip text={bruschettaTip}>
                    <Box onClick={handleSidebaStepChange} className='nodrag' width='28px' height='25px'>
                        <Bruschetta width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 12C0 8.68629 2.68629 6 6 6H22C25.3137 6 28 8.68629 28 12V12C28 15.3137 25.3137 18 22 18H6C2.68629 18 0 15.3137 0 12V12Z" fill="#EFEFEF" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.74999 9.5C4.33578 9.5 3.99999 9.83579 3.99999 10.25C3.99999 10.6642 4.33578 11 4.74999 11L14.75 11C15.1642 11 15.5 10.6642 15.5 10.25C15.5 9.83579 15.1642 9.5 14.75 9.5H4.74999ZM4.75 13C4.33579 13 4 13.3358 4 13.75C4 14.1642 4.33579 14.5 4.75 14.5L23.25 14.5C23.6642 14.5 24 14.1642 24 13.75C24 13.3358 23.6642 13 23.25 13L4.75 13Z" fill="#B4B4B4" />
                        </Bruschetta>
                    </Box>
                </Tooltip>
            </Flex>

            {
                !fullScreen &&
                <Flex mx={isWin ? '' : 'md'} alignItems='center' className='nodrag'>
                    <QuickToggle disableTip={collapsedWin} tip={`${stayOnTop ? 'Unpin' : 'Pin'} Window`} selected={stayOnTop} icon={theme.icons.pin} handleToggle={handleTogglePinWindow} />
                    <QuickToggle disableTip={collapsedWin} tip={`${collapsedWin ? 'Expand' : 'Collapse'} Window`} selected={collapsedWin} icon={theme.icons.up} handleToggle={handleCollapseExpandWindow} />
                </Flex>
            }

            {isWin && <Flex height='100%' alignItems='center' className='nodrag'>
                <WinCommands />
            </Flex>}
        </Flex>
    )
})