import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

// import log from './utilities/logger'
import "./index.css"
import { App } from "./App"
import { AppProvider } from "./AppProvider"
import { createStore } from "./core/index"
import { ApiContextProvider } from "./contexts/apiContext"
// import reportWebVitals from './reportWebVitals';

const MainApp = ({ store }) => {
  return (
    <AppProvider>
      <App />
    </AppProvider>
  )
}

export const initApp = async () => {
  // const user = await getUser()
  const { store, apiInstance } = await createStore()
  // log.debug('STORE: ', store)
  // log.debug('STORE - State: ', store.getState())
  ReactDOM.render(
    <Provider store={store}>
      <ApiContextProvider api={apiInstance} dispatch={store.dispatch}>
        <MainApp />
      </ApiContextProvider>
    </Provider>,
    document.getElementById("root")
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();
}

initApp()
