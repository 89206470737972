import { Flex, ActionLink, useActions, useLocation, useSelectors } from '@ludwig/ui'

import { PayWallLimitCounter } from '@ludwig/views'
import { PayWallGeneric } from './paywalls/PaywallGeneric'

const sources = {
  limit: {
    title: 'Keep solving your English writing doubts',
    text: 'You have already done the 6 daily searches included in the free plan. Try Premium for free or come back tomorrow.',
    image: process.env.REACT_APP_CDNURL + '/paywall-1.png',
    button: 'Try Premium for free'
  },
  sentences: {
    title: 'Get more valuable sentence examples in context',
    text: 'The free plan includes 15 search results per query. Try Premium for free to get up to 60 search results.',
    image: process.env.REACT_APP_CDNURL + '/paywall-2.png',
    button: 'Try Premium'
  },
  filter: {
    title: 'Filter your sources and focus on what really matters to you',
    text: 'The Advanced Filter is Premium feature. Try Premium for free to filter by source and refine your search results.',
    image: process.env.REACT_APP_CDNURL + '/paywall-3.png',
    button: 'Try Premium'
  }

}




export const PayWall = () => {
  const { theme } = useSelectors()
  const { goToPage, requestGoPremium } = useActions()
  const { payload } = useLocation()
  const { flow='limit', responseRest, paraphraseLimit={}} = payload
  const source = sources[flow]
  const { waitingTimeLeft=0 } = responseRest || {}

  const payWallLimitCounterPara =
   flow === 'paraphrase' ? {
    text: {title:`You have already paraphrased <span style="color:#FF986B">${paraphraseLimit.maxLimit}</span> sentences!</br>Want more? Please wait...`,
          subtitle: 'Do you need Ludwig </br>immediately?',
          bannerText: '<strong>Go Premium</strong> and search as much as you want. </br> + All the features you need to be a better writer!',
          mainCta: 'Start 15-Day Free Trial',
          secondaryCta: 'Discover more...'},
    variant: 'paraphrase'
  } : {}
  
  const payWallLimitCounterSearch = {
    text: {title: 'You used your <span style="color:#2683ffff">6</span> free daily searches!</br>To continue searching, please wait...',
          subtitle: 'Do you need Ludwig </br>immediately?',
          bannerText: '<strong>Go Premium</strong> and search as much as you want. </br> + All the features you need to be a better writer!',
          mainCta: 'Start 15-Day Free Trial',
          secondaryCta: 'Discover more...'}, 
    variant: 'search'
  }
  
  const loc= flow === 'paraphrase' ? '/paraphrase' : (flow === 'limit'? '/s/welcome': '/')

  const handleRequestGoPremium=()=>{
    goToPage({type:'WELCOME'}) 
    requestGoPremium({flow})
  }

  //useEffect(()=>flow ? goToPage({type: 'WELCOME', payload:{}}):null, [flow])

  return (

    <Flex p='xl' pt='md' width='100%' flex='1' flexDirection='column' className='aaa' justifyContent='flex-start'>
      
      <Flex textAlign='center'  justifyContent='space-between' className='nodrag'>
        <svg width="160" height="48" viewBox="0 0 160 48" xmlns="http://www.w3.org/2000/svg">
            <path fill={theme.colors.smoke[500]} d={theme.legacyIcons.logoTldOnly} />
        </svg>
        <Flex flexDirection='column'>
          <ActionLink href={loc} variant='secondaryAlpha' iconLeft={'closeOutline'} />
        </Flex>
        
      </Flex>

      {flow !== 'limit' && flow !== 'paraphrase' && <PayWallGeneric {...source} requestGoPremium={handleRequestGoPremium} />}
      {flow === 'paraphrase' && <PayWallLimitCounter 
          waitingTimeLeft={paraphraseLimit.waitingTimeLeft}  
          requestGoPremium={()=>requestGoPremium({flow})} 
          {...payWallLimitCounterPara}/>}
      {flow === 'limit' && <PayWallLimitCounter 
          waitingTimeLeft={waitingTimeLeft}  
          requestGoPremium={()=>requestGoPremium({flow})} 
          {...payWallLimitCounterSearch}/>}
      
    </Flex>
  )
}
