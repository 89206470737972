import log from '../utilities/logger'

import { getUserDataFromCookie } from '@ludwig/core'
import { encodePayload } from '@ludwig/utilities'

export const getUser = async () => {
  let user = await getUserDataFromCookie()
  let token = user ? encodePayload(user) : ''
  log.debug('User from Cookie: ', user)
  log.debug('Encoded User: ', token)
  log.debug('Version: ', process.env.REACT_APP_VERSION)
  return user
}
