export const checkbox =
    'M18,4 C19.1045695,4 20,4.8954305 20,6 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,6 C4,4.8954305 4.8954305,4 6,4 L18,4 Z M17.694307,7.19544133 C17.3357217,6.90216927 16.8017162,6.94359972 16.495357,7.28846104 L16.495357,7.28846104 L9.93156748,14.8949383 L7.50662231,12.2716915 C7.30413415,12.0421152 6.98982091,11.937326 6.68530108,11.9978705 C6.38078125,12.0584149 6.13382697,12.2747951 6.03999323,12.563286 C5.9461595,12.851777 6.02016667,13.1671212 6.23337886,13.3873013 L6.23337886,13.3873013 L9.31743365,16.7237041 C9.4798301,16.8994905 9.71121957,17 9.95405538,17 L9.95405538,17 L9.96851189,17 C10.2168774,16.9961626 10.4513044,16.8875547 10.6110233,16.7023303 L10.6110233,16.7023303 L17.8071512,8.3613231 C18.1031524,8.00799045 18.0528923,7.48871339 17.694307,7.19544133 Z';
export const checkboxEmpty =
    'M18,4 C19.1045695,4 20,4.8954305 20,6 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,6 C4,4.8954305 4.8954305,4 6,4 L18,4 Z M6,6 L6,18 L18,18 L18,6 L6,6 Z';
export const checkboxRemove =
    'M5,11 L19,11 C19.5522847,11 20,11.4477153 20,12 C20,12.5522847 19.5522847,13 19,13 L5,13 C4.44771525,13 4,12.5522847 4,12 C4,11.4477153 4.44771525,11 5,11 Z';

export const check =
    'M5.26497404,12.9065198 C5.66212433,12.5229962 6.29498554,12.5340428 6.67850911,12.9311931 C6.67858493,12.9312716 6.67866075,12.9313502 6.67873654,12.9314287 L10.0831299,16.459093 L10.0831299,16.459093 L17.8961912,7.14910967 C18.2512282,6.72605041 18.8819914,6.67088697 19.3050744,7.02589569 C19.7281249,7.38087723 19.7833057,8.01159685 19.4283242,8.43464738 C19.4283119,8.43466194 19.4282997,8.43467651 19.4282875,8.43469107 L10.9027439,18.5938612 C10.547718,19.0169156 9.91695924,19.0720636 9.49390483,18.7170377 C9.46718337,18.6946131 9.4416536,18.6708057 9.41742023,18.645713 L5.24031735,14.320494 C4.85666766,13.9232405 4.86769576,13.2901934 5.2649493,12.9065437 C5.26495755,12.9065357 5.26496579,12.9065277 5.26497404,12.9065198 Z';

export const checkboxIncomplete =
    'M18,4 C19.1045695,4 20,4.8954305 20,6 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,6 C4,4.8954305 4.8954305,4 6,4 L18,4 Z M18,6 L6,6 L6,18 L18,18 L18,6 Z M16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 L8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L16,11 Z';

// export const checkboxRounded =
//     'M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 Z M17.1248763,7.6758972 C16.8021495,7.41195234 16.3215446,7.44923975 16.0458213,7.75961494 L16.0458213,7.75961494 L10.1384107,14.6054445 L7.95596008,12.2445224 C7.77372073,12.0379036 7.49083882,11.9435934 7.21677097,11.9980834 C6.94270313,12.0525735 6.72044427,12.2473156 6.63599391,12.5069574 C6.55154355,12.7665993 6.61815001,13.0504091 6.81004097,13.2485711 L6.81004097,13.2485711 L9.58569029,16.2513337 C9.73184709,16.4095415 9.94009761,16.5 10.1586498,16.5 L10.1586498,16.5 L10.1716607,16.5 C10.3951897,16.4965463 10.606174,16.3987992 10.749921,16.2320973 L10.749921,16.2320973 L17.226436,8.72519079 C17.4928372,8.4071914 17.447603,7.93984205 17.1248763,7.6758972 Z';

export const checkboxRoundedEmpty =
    'M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 Z M12,5 C8.13400675,5 5,8.13400675 5,12 C5,15.8659932 8.13400675,19 12,19 C15.8659932,19 19,15.8659932 19,12 C19,8.13400675 15.8659932,5 12,5 Z';

export const checkboxRoundedIncomplete =
    'M12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 Z M12,5 C8.13400675,5 5,8.13400675 5,12 C5,15.8659932 8.13400675,19 12,19 C15.8659932,19 19,15.8659932 19,12 C19,8.13400675 15.8659932,5 12,5 Z M16,11 C16.5522847,11 17,11.4477153 17,12 C17,12.5522847 16.5522847,13 16,13 L8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 L16,11 Z';
