import { Favorites as FavoritesView } from '../components/desktop/main/Favorites'
import { MainLayout } from '../components/Wrappers'

export const Favorites = () => {
    const current = { key: 'favorites', area: 'main' }
    return (
        <MainLayout current={current}>
            <FavoritesView />
        </MainLayout>
    )
}