// theme.js

import { modularScale, math } from 'polished';
import { breakpointsSteps } from './breakpoints';

// https://polished.js.org/docs/#modularscale
// scale ratio "major second": https://www.modularscale.com/?16&px&1.125

let steps = breakpointsSteps;
let scale = 'majorThird';
let fontSizes = [];
let lineHeights = [];

for (let i = 0; i < steps; i++) {
    fontSizes[i] = modularScale(i - 2, '1em', scale);
    lineHeights[i] = modularScale(i, '1em', scale);
}

fontSizes.xs = fontSizes[0];
fontSizes.tiny = math(`(${fontSizes[0]} + ${fontSizes[1]}) / 2`);
fontSizes.s = fontSizes[1];
fontSizes.sm = fontSizes[1];
fontSizes.md = fontSizes[2];
fontSizes.l = fontSizes[3];
fontSizes.lg = fontSizes[3];
fontSizes.xl = fontSizes[4];
fontSizes.xxl = fontSizes[5];
fontSizes.huge = fontSizes[6];

lineHeights.xs = lineHeights[0];
lineHeights.tiny = math(`(${lineHeights[0]} + ${lineHeights[1]}) / 2`);
lineHeights.s = lineHeights[1];
lineHeights.sm = lineHeights[1];
lineHeights.md = lineHeights[2];
lineHeights.l = lineHeights[3];
lineHeights.lg = lineHeights[3];
lineHeights.xl = lineHeights[4];
lineHeights.xxl = lineHeights[5];
lineHeights.huge = lineHeights[6];

// let lineHeights = []
// for (let i = 0; i < steps; i++) {
//   lineHeights[i] = modularScale(i - 2, "1em", scale)
// }

export const fonts = {
    sansSerif:
        '"Nunito Sans", "Open Sans", "helvetica neue", helvetica, arial, sans-serif',
    // serif: '"Playfair Display", "Alice", georgia, times, serif'
    serif: '"Gelasio", georgia, times, serif'
};
export const fontsSecondary = {
    sans: '"Open Sans", "helvetica neue", helvetica, arial, sans-serif',
    serif: '"Gelasio", georgia, times, serif'
};
export const fontWeights = {
    lighter: '100',
    light: '300',
    normal: '400',
    medium: '500',
    bolder: '700',
    bold: '700'
};

export { fontSizes, lineHeights };
