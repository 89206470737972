import { Box, Flex, styled, useSystem, useActions } from '@ludwig/ui'

import {MacFullIcon} from './winCommands/MacFullIcon'
import {MacCloseIcon} from './winCommands/MacCloseIcon'
import {MacMinimizeIcon} from './winCommands/MacMinimizeIcon'

import {WinFullIcon} from './winCommands/WinFullIcon'
import {WinCloseIcon} from './winCommands/WinCloseIcon'
import {WinMinimizeIcon} from './winCommands/WinMinimizeIcon'

const Tools = styled(Flex)(
    props => `
    align-items: stretch;
    height:100%;
`)


const MacWrapper = styled(Flex)`
  align-items: center;
  padding: 0 0.3em;
  svg g > *:last-child {
    display: none;
  }
  &:hover {
    svg g > *:last-child {
      display: block;
    }
  }
`

const WinWrapper = styled(Flex)`
  align-items: center;
  justify-content:center;
  height:100%;
  padding: 0;
  padding-left: 0.3em;
  & > div{
    height:100%;
    display: flex;
    align-items: center;
  }
  & > div:hover {
    background: ${props => props.theme.colors.smoke[200]};
  }
  & .close:hover{
    background: #E4172C;
    stroke: white;
  }
`


export const WinCommands = ({...props}) => {

    const {isMac, isWin} = useSystem() // {isMac:false, isWin:true} //

    const {
        minimizeWin,
        closeWin,
        toggleFullScreen,
      } = useActions()


    return (
        <Tools {...props}>

            {isMac && (
              <MacWrapper>
                  <MacCloseIcon handleClick={closeWin} />
                  <MacMinimizeIcon handleClick={minimizeWin} />
                  <MacFullIcon handleClick={toggleFullScreen} />
              </MacWrapper>
            )}

            {isWin && (
              <WinWrapper>
                  <Flex px='md' onClick={minimizeWin}>
                    <WinMinimizeIcon />
                  </Flex>
                  <Flex px='md' onClick={toggleFullScreen} >
                    <WinFullIcon/>
                  </Flex>
                  <Flex px='md' onClick={closeWin} className='close'>
                    <WinCloseIcon />
                  </Flex>
              </WinWrapper>
            )}        

        </Tools>
    )
}