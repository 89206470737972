import { colors } from './colors/index';
const {
    primary,
    primaryContrast,
    primaryAlpha,
    third,
    thirdContrast,
    fourth,
    fourthContrast,
    fourthAlpha
} = colors || {};

export const buttons = {
    primary: {
        color: primaryContrast['500'],
        background: primary['500'],
        '@media(hover)': {
            '&:hover': {
                color: primaryContrast['700'],
                background: primary['700']
            }
        },
        '&:focus, &:active': {
            background: primary['600']
        }
    },
    primaryLight: {
        color: primary['400'],
        background: primary['50'],
        '@media(hover)': {
            '&:hover': {
                color: primary['600'],
                background: primary['100']
            }
        },
        '&:focus': {
            background: primary['100']
        }
    },
    primaryLighter: {
        color: colors.primaryAlpha['500'],
        background: colors.primaryAlpha['200'],
        '@media(hover)': {
            '&:hover': {
                background: colors.primaryAlpha['400']
            }
        },
        '&:focus': {
            background: colors.primaryAlpha['400']
        }
    },
    primaryWhite: {
        color: primary['400'],
        background: primaryContrast['500'],
        '@media(hover)': {
            '&:hover': {
                color: primary['600'],
                background: primary['100']
            }
        },
        '&:focus': {
            background: primary['100']
        }
    },
    primaryOutline: {
        background: 'none',
        color: primary['500'],
        boxShadow: `0px 0px 0px 2px ${primary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: primary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: primaryContrast['500'],
                background: primary['500'],
                boxShadow: `0px 0px 0px 2px ${primary['500']} inset`
            }
        }
    },
    primaryOutlineThin: {
        background: 'none',
        color: primary['500'],
        boxShadow: `0px 0px 0px 1px ${primary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: primary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: primaryContrast['500'],
                background: primary['500'],
                boxShadow: `0px 0px 0px 1px ${primary['500']} inset`
            }
        }
    },
    primaryContrast: {
        color: primary['500'],
        background: primaryContrast['500'],
        '@media(hover)': {
            '&:hover': {
                color: primary['700'],
                background: primary['100']
            }
        },
        '&:focus': {
            background: primary['100']
        }
    },
    empty: {
        background: 'none',
        '@media(hover)': {
            '&:hover': {
                background: 'none'
            }
        },
        '&:focus': {
            background: 'none'
        }
    },
    primaryAlpha: {
        background: 'none',
        color: primary['500'],
        '@media(hover)': {
            '&:hover': {
                background: primaryAlpha['500']
            }
        },
        '&:focus, &:active': {
            background: primaryAlpha['400']
        }
    },
    primaryContrastAlpha: {
        background: primaryAlpha['500'],
        color: primary['500'],
        '@media(hover)': {
            '&:hover': {
                background: primaryAlpha['900']
            }
        },
        '&:focus, &:active': {
            background: primaryAlpha['700']
        }
    },
    secondary: {
        color: colors.secondaryContrast['400'],
        background: colors.secondary['400'],
        '@media(hover)': {
            '&:hover': {
                color: colors.secondaryContrast['400'],
                background: colors.secondary['600']
            }
        },
        '&:focus': {
            background: colors.secondary['600']
        }
    },
    secondaryLight: {
        color: colors.secondary['400'],
        background: colors.secondary['50'],
        '@media(hover)': {
            '&:hover': {
                color: colors.secondary['600'],
                background: colors.secondary['200']
            }
        },
        '&:focus': {
            background: colors.secondary['200']
        }
    },
    secondaryLighter: {
        color: colors.secondary['500'],
        background: colors.secondaryAlpha['200'],
        '@media(hover)': {
            '&:hover': {
                background: colors.secondaryAlpha['400']
            }
        },
        '&:focus': {
            background: colors.secondaryAlpha['400']
        }
    },
    secondaryContrast: {
        color: colors.secondary['400'],
        background: colors.secondaryContrast['400'],
        '@media(hover)': {
            '&:hover': {
                color: colors.secondary['600'],
                background: colors.secondary['100']
            }
        },
        '&:focus': {
            background: colors.secondary['100']
        }
    },
    secondaryLink: {
        color: colors.secondary['400'],
        background: 'none',
        '@media(hover)': {
            '&:hover': {
                color: colors.secondary['600'],
                background: 'none',
                textDecoration: 'underline'
            }
        },
        '&:focus': {
            background: colors.secondary['100']
        }
    },
    secondaryWhite: {
        color: colors.secondary['400'],
        background: colors.secondaryContrast['400'],
        '@media(hover)': {
            '&:hover': {
                color: colors.secondary['600'],
                background: colors.secondary['200']
            }
        },
        '&:focus': {
            background: colors.secondary['200']
        }
    },
    secondaryWhiteAlpha: {
        color: colors.secondaryContrast['500'],
        background: colors.secondary['200'],
        '@media(hover)': {
            '&:hover': {
                background: colors.secondary['400']
            }
        },
        '&:focus': {
            background: colors.secondary['400']
        }
    },
    secondaryAlpha: {
        // color: blue['500'],
        color: colors.primary['500'],
        '@media(hover)': {
            '&:hover': {
                background: colors.secondaryAlpha['500']
            }
        },
        '&:focus': {
            background: colors.secondaryAlpha['400']
        }
    },
    secondaryContrastAlpha: {
        color: colors.secondary['500'],
        '@media(hover)': {
            '&:hover': {
                background: colors.secondaryAlpha['500']
            }
        },
        '&:focus': {
            background: colors.secondaryAlpha['400']
        }
    },
    secondaryOutline: {
        background: 'none',
        color: colors.secondary['500'],
        boxShadow: `0px 0px 0px 2px ${colors.secondary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: colors.secondary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: colors.secondaryContrast['500'],
                background: colors.secondary['500'],
                boxShadow: `0px 0px 0px 2px ${colors.secondary['500']} inset`
            }
        }
    },
    secondaryOutlineThin: {
        background: 'none',
        color: colors.secondary['500'],
        boxShadow: `0px 0px 0px 1px ${colors.secondary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: colors.secondary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: colors.secondaryContrast['500'],
                background: colors.secondary['500'],
                boxShadow: `0px 0px 0px 1px ${colors.secondary['500']} inset`
            }
        }
    },
    secondaryOutlineWhite: {
        background: 'none',
        color: colors.secondary['500'],
        boxShadow: `0px 0px 0px 2px ${colors.secondary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: colors.secondary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: colors.secondary['500'],
                background: colors.secondary['100'],
                boxShadow: `0px 0px 0px 2px ${colors.secondary['500']} inset`
            }
        }
    },
    secondaryOutlineWhiteThick: {
        background: 'none',
        color: colors.secondary['500'],
        boxShadow: `0px 0px 0px 3px ${colors.secondary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: colors.secondary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: colors.secondary['500'],
                background: colors.secondary['100'],
                boxShadow: `0px 0px 0px 3px ${colors.secondary['500']} inset`
            }
        }
    },
    secondaryOutlineWhiteThin: {
        background: 'none',
        color: colors.secondary['500'],
        boxShadow: `0px 0px 0px 1px ${colors.secondary['500']} inset`,
        '&:focus,&:active': {
            background: 'none',
            color: colors.secondary['500']
        },
        '@media (hover)': {
            '&:hover': {
                color: colors.secondary['500'],
                background: colors.secondary['100'],
                boxShadow: `0px 0px 0px 1px ${colors.secondary['500']} inset`
            }
        }
    },
    third: {
        color: thirdContrast['500'],
        background: third['500'],
        '@media(hover)': {
            '&:hover': {
                color: thirdContrast['700'],
                background: third['700']
            }
        },
        '&:focus, &:active': {
            background: third['700']
        }
    },
    thirdContrast: {
        color: third['500'],
        background: 'white',
        '@media(hover)': {
            '&:hover': {
                color: third['700'],
                background: third['200']
            }
        },
        '&:focus, &:active': {
            background: third['700']
        }
    },
    danger: {
        color: fourthContrast['400'],
        background: fourth['800'],
        '@media(hover)': {
            '&:hover': {
                color: fourthContrast['400'],
                background: fourth['900']
            }
        },
        '&:focus, &:active': {
            color: fourthContrast['400'],
            background: fourth['900']
        }
    },
    dangerAlpha: {
        color: fourth['500'],
        '@media(hover)': {
            '&:hover': {
                background: fourthAlpha['900']
            }
        },
        '&:focus, &:active': {
            background: fourthAlpha['700']
        }
    },
    dangerContrastAlpha: {
        background: fourthAlpha['500'],
        color: fourth['500'],
        '@media(hover)': {
            '&:hover': {
                background: fourthAlpha['900']
            }
        },
        '&:focus, &:active': {
            background: fourthAlpha['700']
        }
    }
};

export const themeButtons = {
    p: {
        color: colors.secondaryContrast['700'],
        bg: colors.secondary['500'],
        hover: {
            color: colors.secondaryContrast['700'],
            bg: colors.secondary['700']
        },
        textTransform: 'uppercase'
    },
    one: {
        color: colors.primaryContrast['500'],
        bg: colors.primary['500'],
        hover: {
            color: colors.primaryContrast['700'],
            bg: colors.primary['700'],
            alphaBg: colors.primary['700'] + '1f'
        },
        textTransform: 'initial'
    },
    two: {
        color: colors.secondaryContrast['500'],
        bg: colors.secondary['500'],
        lightBg: colors.secondary['50'],
        hover: {
            color: colors.secondaryContrast['700'],
            bg: colors.secondary['700'],
            lightBg: colors.secondary['100']
        }
    },
    three: {
        color: colors.thirdContrast['500'],
        bg: colors.third['500'],
        hover: {
            color: colors.thirdContrast['700'],
            bg: colors.third['700']
        }
    }
};

export const buttonVariants = {
    PRIMARY: 'primary',
    PRIMARY_ALPHA: 'primaryAlpha',
    PRIMARY_OUTLINE: 'primaryOutline',
    SECONDARY: 'secondary',
    SECONDARY_ALPHA: 'secondaryAlpha',
    THIRD: 'third',
    FOURTH: 'fourth',
    DANGER: 'danger',
    PRIMARY_LINK: 'primaryLink',
    SECONDARY_LINK: 'secondaryLink',
    GOOGLE: 'google',
    FACEBOOK: 'facebook'
};
