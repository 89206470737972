import { Maintenance as MaintenanceView } from '../components/desktop/main/Maintenance'
import { DraggableWrapper } from '../components/Wrappers'

export const Maintenance = () => {
    return (
        <DraggableWrapper bg='aqua.500' width="100%" height='100%' flexDirection='column' overflow='overlay'>
                    
           <MaintenanceView />

        </DraggableWrapper>
    )
}
