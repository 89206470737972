import { useEffect, useRef, useState } from 'react'
import { Tooltip, Flex, Box, Loading, ActionButton, NavLink, useActions, useSelectors, styled } from '@ludwig/ui'

import { Icon } from './common/LudwigIcon'
import { Text } from './common/LudwigText'
import { TextInput } from './common/LudwigTextInput'

import { theme } from '../../theme'
import { useToasts } from '../../contexts/toastsContext'



export const Signin = ({error = null}) => {

    const {
        requestGoogleSignin, 
        requestFacebookSignin, 
        loginStart,
        requestSignUp,
        requestForgotPass,
        goToPage
    } = useActions()

    const { desktop } = useSelectors()
    const { loginError } = desktop

    const email = useRef()
    const password = useRef()

    const [processing, setProcessing] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [provider, setProvider] = useState(null)
    const [showError, setShowError] = useState(false)
    const [showLoginTip, setShowLoginTip] = useState(false)

    const handleKeyUp = e => {
        const enableBtn = (email.current.value && password.current.value)
        setBtnDisabled(!enableBtn)
        if (e.keyCode === 13 && enableBtn) handleLogin()
    }

    const handleLogin = () => {
        setProcessing(true)
        setBtnDisabled(true)
        loginStart({email: email.current.value, password: password.current.value})
    }

    const requestProvider = (provider) => {
        setProvider(provider)
        if(requestGoogleSignin && provider === 'Google') requestGoogleSignin()
        if(requestFacebookSignin && provider === 'Facebook') requestFacebookSignin()
    }

    const toggleInfoToReset = (v) => {
        setShowLoginTip(v)
    }
    const resetToFactory = () => {
        goToPage({type: 'RESET_FACTORY', payload:{}})
    }

    const {addToast} = useToasts()

    useEffect(() => {
        if(loginError) setShowError(true)
    }, [loginError])

    useEffect(() => {
        if(showError) {
            setProcessing(false)
            setShowError(false)
            setBtnDisabled(false)
            addToast({textId: 'Login error. Try again.', variant: 'error'})
        }
    }, [showError, addToast])

    return(
        <Flex width='100%' justifyContent='center' flexDirection='column'>
            <Flex alignItems='center' flexDirection='column'>
                <Icon fontSize='xxl' iconPath={theme.legacyIcons.ludwig} p='md' />
                <Text variant='heading_6_sans' textId='Sign in to Ludwig' />
                
                
                
                <Flex width='350px' alignItems='center' flexDirection='column' position='relative'>

                    

                    <Box mt='md' p='md'>
                        <Text color='grey.200' variant='body_s' textId='continue with' />
                    </Box>

                   

                    <Flex width='100%' className='nodrag'>
                        <ActionButton handleClick={()=>requestProvider('Google')} mr='xs' flex='1' variant='SolidBlueMedium'>
                            <Text id='Google' />
                            <SocialIcon type='google' />
                        </ActionButton>
                        <ActionButton handleClick={()=>requestProvider('Facebook')} ml='xs' flex='1' variant='SolidBlueMedium'>
                            <Text id='Facebook' />
                            <SocialIcon type='facebook' />
                        </ActionButton>
                    </Flex>

                    <Box p='md'>
                        <Text color='grey.200' variant='body_s' textId='or' />
                    </Box>

                    <Box width='100%' className='nodrag'>
                        <TextInput onKeyUp={handleKeyUp} disabled={processing} ref={email} className={error ? 'invalid' : ''} placeholder='Email' />
                        <TextInput onKeyUp={handleKeyUp} disabled={processing} ref={password} className={error ? 'invalid' : ''} type='password' placeholder='Password' />
                    </Box>

                    {error &&<Flex py='sm' width='100%' alignItems='center'>
                        <Icon fontSixe='xxl' iconPath={theme.icons.alert} color='coral.900' />
                        <Text pl='sm' color='coral.900' variant='body_s' textId='There was an error. Check your credentials or create an account.' />
                    </Flex>}

                    <Flex width='100%' className='nodrag'>
                        <ActionButton 
                            loader={processing} disabled={btnDisabled}
                            handleClick={handleLogin} ml='xs' flex='1' variant='SolidBlueMedium'>
                            <Text id='Sign In' />
                        </ActionButton>
                    </Flex>

                    <Box p='md' className='nodrag'>
                        <NavLink p='0' handleClick={requestForgotPass}>
                            <Text p='0' color='grey.200' variant='body_s' textId='Forgot password?' />
                        </NavLink>
                    </Box>


                    <Box pt='md' textAlign='center' className='nodrag'>
                        <Text color='grey.200' variant='body_s' textId='Don’t you have an account? ' />
                        <NavLink p='0' handleClick={requestSignUp}>
                            <Text color='blues.500' variant='body_s' textId='Register for free on ludwig.guru' />
                        </NavLink>
                    </Box>

                    {!showLoginTip && <Box pt='md' textAlign='center' className='nodrag'>
                        <NavLink p='0' handleClick={()=>toggleInfoToReset(true)}>
                            <Text color='coral.900' variant='body_s' textId="I can't login!" />
                        </NavLink>
                    </Box>}

                    {showLoginTip && <Box pt='md' textAlign='center' className='nodrag'>
                        {/* <Tooltip text="" delayEnter='0' > */}
                        <NavLink p='0' handleClick={resetToFactory}>
                            <Text color='blues.500' variant='body_s' textId='Click here to reset and restart' />
                        </NavLink>
                        {/* </Tooltip> */}
                        <Text color='grey.200' variant='body_s' textId=' Ludwig Desktop, then, try again.' />
                    </Box>}


                    {provider && <Flex bg='white' position='absolute' width='100%' height='100%'>
                        <Loading>
                            <Text textId={`Signing in with ${provider} in your browser...`}></Text>
                            <Box><NavLink p='0' handleClick={()=>setProvider(null)}>
                                <Text color='blues.500' variant='body_s' textId='If you get stuck, click to try again' />
                            </NavLink></Box>
                        </Loading>
                    </Flex>}

                </Flex>
            </Flex>
        </Flex>
    )
}



const FacebookLogo = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="white"/>
        <path d="M19.6376 16.7422H16.9681V26.522H12.9236V16.7422H11V13.3052H12.9236V11.081C12.9236 9.49055 13.6791 7 17.0041 7L20 7.01253V10.3487H17.8263C17.4697 10.3487 16.9684 10.5269 16.9684 11.2856V13.3084H19.9909L19.6376 16.7422Z" fill="#2683FF"/>
        </svg>
    )
}
const GoogleLogo = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="white"/>
            <path d="M10.6875 16C10.6875 15.0091 10.9605 14.0809 11.4348 13.286V9.91464H8.0634C6.72531 11.6525 6 13.7704 6 16C6 18.2296 6.72531 20.3475 8.0634 22.0853H11.4348V18.7139C10.9605 17.9191 10.6875 16.9909 10.6875 16Z" fill="#FBBD00"/>
            <path d="M16 21.3125L13.6562 23.6562L16 26C18.2296 26 20.3475 25.2747 22.0854 23.9366V20.5688H18.7175C17.9158 21.0448 16.9836 21.3125 16 21.3125Z" fill="#0F9D58"/>
            <path d="M11.4349 18.7139L8.06348 22.0854C8.3284 22.4294 8.61688 22.7589 8.92902 23.0711C10.8178 24.9598 13.329 26 16.0001 26V21.3125C14.0616 21.3125 12.3627 20.2687 11.4349 18.7139Z" fill="#31AA52"/>
            <path d="M26 16C26 15.3916 25.9449 14.7821 25.8363 14.1884L25.7483 13.708H16V18.3955H20.7442C20.2835 19.3119 19.5752 20.0596 18.7175 20.5688L22.0853 23.9366C22.4294 23.6717 22.7589 23.3832 23.0711 23.0711C24.9598 21.1823 26 18.6711 26 16Z" fill="#3C79E6"/>
            <path d="M19.7565 12.2435L20.1708 12.6578L23.4854 9.34324L23.0711 8.92895C21.1823 7.0402 18.6711 6 16 6L13.6562 8.34375L16 10.6875C17.419 10.6875 18.7531 11.2401 19.7565 12.2435Z" fill="#CF2D48"/>
            <path d="M16.0001 10.6875V6C13.329 6 10.8178 7.0402 8.92902 8.92891C8.61687 9.24105 8.3284 9.57055 8.06348 9.91465L11.4349 13.2861C12.3627 11.7312 14.0617 10.6875 16.0001 10.6875Z" fill="#EB4132"/>
        </svg>
    )
}

const SocialIconWrapper = styled(Box)`
    width: 32px;
    height: 32px;
    position: absolute;
    left: 3px;
    top: 3px;
    overflow: hidden;
`

const SocialIcon = ({type}) =>{
    return (
        <SocialIconWrapper>
            {type === 'google' && <GoogleLogo />}
            {type === 'facebook' && <FacebookLogo />}
        </SocialIconWrapper>
    )
}

