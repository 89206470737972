import React, {
  useEffect,
  useRef,
  createContext,
  useState,
  useContext,
} from "react"
import { baseApi } from "@ludwig/api"

import log from "../utilities/logger"

const defaultContextValue = {}

const {
  get: getApi,
  post: postApi,
  head: headApi,
  del: delApi,
  patch: patchApi,
  put: putApi,
} = baseApi || {}

export const ApiContext = createContext(defaultContextValue)

export const ApiContextProvider = (props) => {
  const { api, children } = props || {}
  const get = (props) => {
    return getApi({ api, ...props })
  }
  const post = (props) => {
    return postApi({ api, ...props })
  }
  const patch = (props) => {
    return patchApi({ api, ...props })
  }
  const del = (props) => {
    return delApi({ api, ...props })
  }
  const head = (props) => {
    return headApi({ api, ...props })
  }
  const put = (props) => {
    return putApi({ api, ...props })
  }

  const fetchAICache = (path) => {
    return get({ path, useCache: { cache: false }})
  }

  const fetchParaphrases = (path = "/paraphrase/compute") => {
    return get({ path }).then((response) => {
      log.debug('response', response)
      // const { response } = err || {}
            const { status, data, message, maxLimit, waitingTimeLeft } = response || {}
      let error = new Error("An error occurred while fetching the data.")
      switch (status) {
        case 200:
          return data
        case 412:
            error.waitingTimeLeft= waitingTimeLeft
            error.maxLimit= maxLimit
            error.message = message
            error.status = status 
            throw error
        case 429:
            // Attach extra info to the error object.
            error.message = message
            error.status = status 
            throw error
        default:
            error.message = message
            error.status = status
            throw error
      }
    })
  }

  const apiContext = {
    get,
    post,
    del,
    patch,
    head,
    put,
    fetchParaphrases,
    fetchAICache
  }

  return (
    <ApiContext.Provider value={apiContext}>{children}</ApiContext.Provider>
  )
}

export const useApiContext = () => {
  const context = useContext(ApiContext)
  if (context === defaultContextValue) {
    throw new Error("useApiContext must be used within ApiContextProvider")
  }
  const { get, post, del, patch, head, fetchParaphrases, fetchAICache } = context || {}
  return { get, post, del, patch, head, fetchParaphrases, fetchAICache }
}

export const { Consumer: ApiConsumer } = ApiContext
