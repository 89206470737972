const initialBreakpoints = [
    '320px', //  xs 0 viewXs
    '480px', //  sm 1 viewSm ================== DESKTOP
    '640px', //  ms 2 viewMs ================== DESKTOP
    '880px', //  md 3 viewMd AD ================== DESKTOP
    '1024px', //  lg 4 viewL
    '1140px', //  xl 5 adScreenL
    '1160px', //  xxl 6 ================== DESKTOP
    '1280px', //  adL 7 ADL
    '1380px', //  huge 8 adScreenXl
    '1500px' //  9
];

const breakpointsSteps = initialBreakpoints.length + 1;

const breakpoints = [...initialBreakpoints];

breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.ms = breakpoints[2];
breakpoints.md = breakpoints[3];
breakpoints.ad = breakpoints[3];
breakpoints.l = breakpoints[4];
breakpoints.lg = breakpoints[4];
breakpoints.xl = breakpoints[5];
breakpoints.xxl = breakpoints[6];
breakpoints.adL = breakpoints[7];
breakpoints.huge = breakpoints[8];

const mediaQueries = {
    xs: `(min-width: ${breakpoints[0]})`,
    sm: `(min-width: ${breakpoints[1]})`,
    ms: `(min-width: ${breakpoints[2]})`,
    md: `(min-width: ${breakpoints[3]})`,
    ad: `(min-width: ${breakpoints[3]})`,
    l: `(min-width: ${breakpoints[4]})`,
    xl: `(min-width: ${breakpoints[5]})`,
    xxl: `(min-width: ${breakpoints[6]})`,
    adL: `(min-width: ${breakpoints[7]})`,
    huge: `(min-width: ${breakpoints[8]})`,
    or: '(orientation: portrait)' // we can check orientation also
};

breakpoints.mediaQueries = mediaQueries;

export { breakpoints, breakpointsSteps, breakpoints as sizes };
