import { Tapable, styled, Tooltip } from '@ludwig/ui'
import { Icon } from './LudwigIcon'


export const QuickToggle = ({selected, variant, icon, handleToggle, tip, disableTip, ...props}) => {

    const isSelectedClass = selected ? 'selected' : ''
    
    return (
        <MiniButton className={`${isSelectedClass}`} 
            prevent={true} 
            handleClick={handleToggle}>
            
            {!disableTip && <Tooltip text={tip}>
                <Icon fontSize='8px' iconPath={icon} />
            </Tooltip>}
            {disableTip && <Icon fontSize='8px' iconPath={icon} />}
        </MiniButton>    
    )
}


const MiniButton = styled(Tapable)`
    border: 1px solid ${props => props.theme.colors.blues[500]};
    border-radius: 16px;
    display: flex;
    align-items:center;
    justify-content:center;
    width: 16px;
    height: 16px;
    margin: 0 3px;
    color: ${props => props.theme.colors.blues[500]};
    &:hover{
        background: ${props => props.theme.colors.blues[100]};
    }
    &.selected{
        background: ${props => props.theme.colors.blues[500]};
        color: white;
    }
`