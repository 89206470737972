import {  Box, Flex, NavLink, useActions } from '@ludwig/ui'
import { Text } from '../common/LudwigText'

import { ColumnWrapContainer } from '../../Wrappers'

export const Maintenance = ({data}) => {
    const { goToPage } = useActions();

    const goToHome = () => {
        goToPage({type: 'WELCOME', payload:{}})
    }

    return (
        <ColumnWrapContainer layout='maintenance'>
        
            <Flex mt="lg" pt='lg' pb='md' flex='1' alignItems='center' justifyContent='center'>
                <Box as='img' maxWidth="100%" width='450px' src="https://ludwig.guru/images/maintenance.png"  alt='' />
            </Flex>

            <Flex flexDirection='column' textAlign='center' mb='lg' pb='lg'  className='nodrag' >
                <Text variant='heading_4' textId='Sorry, Ludwig is under maintenance' />
                <Text variant='body_l' textId="We'll be back as soon as possible!" />

                
                <NavLink p='0' handleClick={goToHome} style={{textDecoration: 'underline'}}>
                    <Text  p='0' variant='body_s' textId='Try again' />
                </NavLink>
            </Flex>
            
        </ColumnWrapContainer>
    )
}

