import { Translate as TranslateView } from '../components/desktop/main/Translate'
import { MainLayout } from '../components/Wrappers'

export const Translate = () => {
    const current = { key: '', area: 'main' }
    return (
        <MainLayout layout='welcome' current={current}>
            <TranslateView />
        </MainLayout>
    )
}
