import { strings } from '@ludwig/utilities';
import { log } from '../../utilities/logger';

const {
    decodeURIComponentSafe
} = strings || {};

export const decodePath = ({ path, slice }) => {
    log.debug('Decode from Path - Input String ', path)
    let string = decodeURIComponent(
        decodeURIComponentSafe(path, 1)
            .trim()
            .replace(/\++/g, ' ')
    );
    if (slice) {
        string.slice(0, 78);
    }
    // try {
    //   string = decodeURIComponent(str.trim().replace(/\++/g, ' '))
    // } catch(e) {
    //   log.debug('CUSTOM - Routes Map - Decode from Path - Input String ', str)
    //   log.debug('CUSTOM - Routes Map - Decode from Path - Error ', e)
    // }
    // log.debug('CUSTOM - Routes Map - Decode from Path - Output String ', string)
    // log.debug('CUSTOM - Routes Map - Decode from Path ', string)
    return string;
};

let regAll = /([\;\/\?\:\@\=\&\"\<\>\#\%\{\}\|\\\^\~\[\]\`\s]+|[\u0041-\uFF5A]+|\S+)/gi;
let regUnsafe = /[\;\/\?\:\@\=\&\"\<\>\#\%\{\}\|\\\^\~\[\]\`\s]+/gi;

const isSafe = string => !regUnsafe.test(string);
const isUnsafe = string => regUnsafe.test(string);

export const encodeOptions = string => {
    let encoded = string && string.trim().replace(/\s+/g, ' ');
    let hasSafe = isSafe(string);
    let allArr = string.match(regAll) || [];
    // log.debug('CUSTOM - Routes Map - Encode Options - All Arr', allArr);
    let newArr = allArr.map(word => {
        if (isUnsafe(word)) {
            let encodedWord = encodeURIComponent(word);
            // log.debug('ROUTES - Search - Utils - Encode Options - Output String - Has Accent - Encoded Letter', encodedWord);
            return encodedWord;
        } else {
            // log.debug('ROUTES - Search - Utils - Encode Options - Output String - Has Accent - Word', word);
            return word;
        }
    });
    encoded = newArr.join('').replace(/%20/g, '+');

    encoded = replaceOperators(encoded);
    return encoded;
};

export const replaceOperators = encoded => {
    let string = encoded
        .replace(/%7B/g, '{')
        .replace(/%7D/g, '}')
        .replace(/%5D/g, ']')
        .replace(/%5B/g, '[');
    return string;
};