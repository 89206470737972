import { composeWithDevTools } from "redux-devtools-extension"
import { configureStore } from "@ludwig/core"
import { createApiInstance } from "@ludwig/api"
import { getAppVersion, getCid } from "@ludwig/utilities"

import parser from "ua-parser-js"

import { log } from "../utilities/logger"

import { reducers } from "./reducers"
import { routes } from "./routes"
import * as actions from "./actions"
import { selectors } from "./selectors"

import { getRoutes } from "@ludwig/library"
import { getUser } from "./getUser"

log.debug(reducers, selectors, actions)

const ENDPOINT_FULL = `${process.env.REACT_APP_BASEURL}/api`
const STREAM_API_ENDPOINT = `${process.env.REACT_APP_STREAM_API_ENDPOINT}/api`

export const getParsedUa = (ua) => {
  // eslint-disable-next-line no-undef
  const { navigator } = globalThis || {}
  const { userAgent } = navigator || {}
  let nextUserAgent = ua || userAgent || ""
  return nextUserAgent ? parser(nextUserAgent) : {}
}

export const createStore = async ({ cid, version = process.env.REACT_APP_VERSION } = {}) => {
  // log.debug("Actions: ", actions)
  // log.debug("Envs: ",  process.env)
  const user = await getUser()
  const ua = getParsedUa()
  const { token, id:userId } = user || {}
  const nextCid = cid || await getCid({ source: 'desktop-web', id: /* guestId or */userId });
  log.debug("Token: ", token)
  const apiInstance = createApiInstance({
    ENDPOINT_FULL,
    token,
  })
  const apiStream = createApiInstance({
    ENDPOINT_FULL: STREAM_API_ENDPOINT,
    token,
  })
  apiInstance.defaults.headers.common["Authorization"] = token || "" //
  if (version) {
    const appVersion = getAppVersion({
      VERSION: version,
      SOURCE: "desktop-web",
    })
    apiInstance.defaults.headers.common["Version"] = appVersion
    // apiStream.defaults.headers.common["Version"] = appVersion
  }
  if (nextCid) {
    apiInstance.defaults.headers.common["cid"] = nextCid || "" //
    // apiStream.defaults.headers.common["cid"] = cid || "" //
  }
  apiStream.defaults.headers.common["Authorization"] = token || ""
  const storeConfig = {
    preloadedState: {
      user,
      ua,
    },
    loadDefault: false,
    reducers,
    routes,
    actions,
    extra: { apiInstance, apiStream },
    customDevTools: composeWithDevTools,
  }
  return {...configureStore(storeConfig), apiInstance}
}

export { actions, routes, getRoutes, reducers, selectors }
