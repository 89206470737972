import { blacks } from './blacks';

const base = {
    '50': '#e0edff',
    '100': '#b3d3ff',
    '200': '#80b6ff',
    '300': '#4d99ff',
    '400': '#2683ff',
    '500': '#006dff',
    '600': '#0065ff',
    '700': '#005aff',
    '800': '#0050ff',
    '900': '#003eff'
};
const contrast = {
    '50': blacks[500],
    '100': blacks[500],
    '200': blacks[500],
    '300': blacks[500],
    '400': '#ffffff',
    '500': '#ffffff',
    '600': '#ffffff',
    '700': '#ffffff',
    '800': '#ffffff',
    '900': '#ffffff'
};

const alpha = {
    '50': base[50] + '1f',
    '100': base[100] + '1f',
    '200': base[200] + '1f',
    '300': base[300] + '1f',
    '400': base[400] + '1f',
    '500': base[500] + '1f',
    '600': base[600] + '1f',
    '700': base[700] + '1f',
    '800': base[800] + '1f',
    '900': base[900] + '1f'
};

export const blues = {
    '50': base[50],
    '100': base[100],
    '200': base[200],
    '300': base[300],
    '400': base[400],
    '500': base[500],
    '600': base[600],
    '700': base[700],
    '800': base[800],
    '900': base[900],
    light: base[50],
    medium: base[300],
    dark: base[500],
    contrast,
    alpha
};
