import React, { useState } from 'react'
import { styled, Tapable, variant, Flex, Box, NavLink, ActionButton, Text, Icon, useModalWithComponent } from '@ludwig/ui';

const AgreementComponent = ({ handleClose, updateAgreement }) => {
    const [agreement, setAgreement] = useState(null)
    const handleAgreement = () => {
        setAgreement(prevState => !prevState)
    }
    const handleUpdateAgreement = () => {
        updateAgreement()
    }
    return (
        <Box maxWidth='100%' width='450px' p='xl'>
            <Flex mb='md'>
                <Box width='50px'>
                    <Icon color='secondary.400' icon='infoOutline' />
                </Box>
                <Box>
                    <Text fontWeight='bold' textId='Your Data is safe' />
                </Box>
            </Flex>
            <Box mb='md' pl='50px'>
                <Text textId="We don't share or sell your documents, we are very protective about our own privacy on the web and we treat our users with the same care." />
            </Box>
            <CheckboxWrapper handleClick={handleAgreement}>
                <Box width='50px'>
                    <CheckboxIcon checked={agreement} color='secondary.400' />
                </Box>
                <Box style={{userSelect: 'none'}} flex='1'>
                    <Text textId='By using Library you explicitly accept its ' />
                    <AgreementLink onClick={e => e.stopPropagation()} target='_blank' href='https://ludwig.guru/terms'>
                        <Text textId='terms and conditions' />
                    </AgreementLink>
                </Box>
            </CheckboxWrapper>
            <Flex justifyContent='flex-end'>
                <ActionButton display='inline-flex' disabled={!agreement} handleClick={handleUpdateAgreement} variant='secondary'>
                    <Text textId='Proceed' />
                </ActionButton>
            </Flex>
        </Box>
    )
}

const CheckboxWrapper = styled(Tapable)(
    props => variant({
        variants: {
            default: {
                '@media(hover)': {
                    cursor: 'pointer',
                    '&:hover': {
                        bg: 'primary.50'
                    }
                }
            }
        }
    })
)

CheckboxWrapper.defaultProps = {
    variant: 'default',
    mb: 'md',
    borderRadius: 'sm',
    py: 'sm',
    isFlex: true,
    userSelect: 'none'
}
const AgreementLink = styled(NavLink)(
    props => variant({
        variants: {
            default: {
                color: 'secondary.400',
                '@media(hover)': {
                    cursor: 'pointer',
                    '&:hover': {
                        color: 'secondary.700'
                    }
                }
            }
        }
    })
)

AgreementLink.defaultProps = {
    variant: 'default',
    textDecoration: 'underline'
}

const CheckboxIcon = ({ checked, ...props }) => {
    return <Icon icon={checked ? 'checkbox' : 'checkboxEmpty'} {...props} />
}

export const useAgreement = props => {
    const {
        showModal: showAgreement,
        hideModal: closeAgreement
    } = useModalWithComponent({
        locked: true,
        Component: AgreementComponent,
        fluid: true,
        ...props
    });
    return { showAgreement, closeAgreement };
};
