import { blacks } from './blacks';

const base = {
    '50': '#f0fbfc',
    '100': '#d8f4f7',
    '200': '#bfedf2',
    '300': '#a5e5ec',
    '400': '#91e0e8',
    '500': '#7edae4',
    '600': '#76d6e1',
    '700': '#6bd0dd',
    '800': '#61cbd9',
    '900': '#4ec2d1'
};

const contrast = {
    '50': blacks[500],
    '100': blacks[500],
    '200': blacks[500],
    '300': blacks[500],
    '400': blacks[500],
    '500': blacks[500],
    '600': blacks[500],
    '700': blacks[500],
    '800': blacks[500],
    '900': blacks[500]
};

const alpha = {
    '50': base[50] + '1f',
    '100': base[100] + '1f',
    '200': base[200] + '1f',
    '300': base[300] + '1f',
    '400': base[400] + '1f',
    '500': base[500] + '1f',
    '600': base[600] + '1f',
    '700': base[700] + '1f',
    '800': base[800] + '1f',
    '900': base[900] + '1f'
};

export const azures = {
    '50': base[50],
    '100': base[100],
    '200': base[200],
    '300': base[300],
    '400': base[400],
    '500': base[500],
    '600': base[600],
    '700': base[700],
    '800': base[800],
    '900': base[900],
    light: base[50],
    medium: base[500],
    dark: base[800],
    contrast,
    alpha
};
