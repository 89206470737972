import { useEffect, useState } from 'react'

import { decodePayload, isUserValid } from '@ludwig/utilities'
import { useActions } from '@ludwig/ui'

import log from '../utilities/logger'

export const useUserCookie = ({ name: cookieName }) => {
  const { updateUser } = useActions()
  const [cookieState, setCookieState] = useState(null)
  useEffect(() => {
    const handleCookie = ({ oldValue: lastCookie, newValue: cookie }) => {
      log.debug('Use Cookie - Handle Cookie')
      try {
        let lastCookieValue = getCookieValue(lastCookie, cookieName)
        let nextCookieValue = getCookieValue(cookie, cookieName)
        log.debug('Use Cookie - Handle Cookie - Last: ', lastCookieValue)
        log.debug('Use Cookie - Handle Cookie - Next: ', nextCookieValue)
        if (lastCookieValue !== nextCookieValue) {
          let user = decodePayload(nextCookieValue)
          let isValid = isUserValid(user)
          if (isValid) {
            updateUser(user)
          }
        }
      } catch (e) {

      }
    }
    setCookieState(prevState => document.cookie)
    let intervalId = setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== cookieState) {
        log.debug('Use Cookie - Different Cookie: ')
        // log.debug('Use Cookie - New Cookie: ', cookie)
        // log.debug('Use Cookie - Last Cookie: ', cookieState)
        try {
          handleCookie({ oldValue: cookieState, newValue: cookie });
        } finally {
          log.debug('Use Cookie - Set New Cookie: ')
          clearInterval(intervalId)
          setCookieState(prevState => cookie)
        }
      }
    }, 1000);
    return () => clearInterval(intervalId)
  }, [cookieName, cookieState, updateUser])
}

const getCookieValue = (cookies, name) => {
  log.debug('Use Cookie - Get Cookie Value - Name: ', name)
  return cookies && cookies
  .split('; ')
  .find(row => row.startsWith(`${name}=`))
  .split('=')[1];}