// import { Flex } from '@ludwig/ui'

import { Headbar } from '../components/desktop/main/Headbar'
import { Source as SourceView } from '../components/desktop/main/Source'
import { BodyContainer, ColumnContainer, ColumnWrapContainer, DraggableWrapper } from '../components/Wrappers'

export const Source = () => {
    const current = {key: 'search', area:'main'}
    return(
        <DraggableWrapper width="100%" height='100%' flexDirection='column' overflow='hidden'>

            <Headbar current={current} />

            <BodyContainer>
                
                <ColumnWrapContainer>
                    <ColumnContainer>
                        <SourceView />
                    </ColumnContainer>
                </ColumnWrapContainer>
                
            </BodyContainer>

        </DraggableWrapper>
    )
}