// import React from 'react'
import { Box, styled } from '@ludwig/ui'

export const Toggle = styled(Box)`
  margin: 0 0.5em;
  font-size: 1em;
  appearance: none;
  width: 2.8em;
  height: 1.5em;
  background: ${props => props.checked ? props.theme.colors.blues[500] : props.theme.colors.grays[0]};
  border-radius: 3em;
  position: relative;
  cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  opacity: ${props => props.disabled ? '0.4' : '1'};
  outline: none;
  &:after {
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #fff;
    left: 0;
    box-shadow: 0 0 0.25em ${props => props.theme.colors.alphaGrays[3]};
    transition: left .2s ease-in-out;
  }
  &:checked {
    background: ${props => props.theme.colors.blues[500]};
    &:after {
      left: calc(100% - 1.5em);
    }
  }
`

Toggle.defaultProps = {
  as: 'input',
  type: 'checkbox'
}

