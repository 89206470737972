import { colors } from './colors/index';
const { secondary } = colors || {};

const borderRadii = [4, 8, 12, 16];

borderRadii.none = 0;
borderRadii.xs = borderRadii[0];
borderRadii.sm = borderRadii[1];
borderRadii.md = borderRadii[2];
borderRadii.normal = borderRadii[3];
borderRadii.lg = '3em';
borderRadii.xl = '4em';

const borders = ['0', '1px solid rgba(0,0,0,0.12)', '1px solid black'];

borders.none = borders[0];
borders.alphaGray = borders[1];
borders.secondary = `1px solid ${secondary.medium}`;

export { borderRadii, borders };
