import React, { useEffect, useState } from 'react'
import { MainLayout } from '../components/Wrappers'
import {ParaphraseView, ParaphraseContextProvider} from '@ludwig/views'
import { Flex} from '@ludwig/prim'
import { useSelectors, useEnv, useActions, useLocation } from '@ludwig/ui'
import ParaphraserLoader from '../components/desktop/animations/paraphraser_tile.json'
import { useApiContext } from '../contexts/apiContext'


const isParaphrase = type => type === 'PARAPHRASE'

export const Paraphrase = () => {
    const {fetchParaphrases} = useApiContext()
    const current = { key: 'paraphrase', area: 'main' }
    const { user } = useSelectors() || {}
    const { payload, type } = useLocation() || {}
    const { sentence:initialSentence } = payload || {}
    const { ENDPOINT_PARAPHRASE } = useEnv() || {}
    const paraphraseEndPoint = ENDPOINT_PARAPHRASE + '/api/'
    const { dispatchClick = () => {}, goToPage } = useActions()
    //const [limitError, setLimitError]=useState()
   
    const paraphrasesLimit = (error) => {if (error && error.waitingTimeLeft) goToPage({type: 'UPGRADE', payload: {flow:'paraphrase', paraphraseLimit:{ ...error}}})}
    const info={
        endPoint:paraphraseEndPoint,
        user,
        fetchParaphrases, 
        dispatchClick:dispatchClick, 
        paraphrasesLimit:paraphrasesLimit,
        initialSentence: isParaphrase(type) ? initialSentence : '', 
        type:type,
        goToPage
    }
  
    return (
        <MainLayout current={current} layout='paraphrase'>
            <Flex top='50px' justifyContent='center' >
                <Flex maxWidth='100%' width='800px' flexDirection='column' justifyContent='center'>
                    <ParaphraseContextProvider value={{...info}}>
                        <ParaphraseView
                            //user={user}
                            //fetcher={fetchParaphrases}
                            //endPoint={paraphraserEndPoint}
                            //dispatchClick={dispatchClick}
                            //paraphrasesLimit={paraphrasesLimit}
                            layout='smoke.50'
                            variant='paraphrase'
                            loadingAnimation={ParaphraserLoader}
                            animationOptions={{ loop: true }}
                            usageContext="desktopTextBar"
                            {
                                ...(isParaphrase(type) && initialSentence ? {initialSentence} : {})
                            }
                        />
                    </ParaphraseContextProvider>
                </Flex>
            </Flex>
        </MainLayout>
    )
}