import React, { useState, useEffect, useRef, useCallback } from 'react';

// import { dr_clock } from '@ludwig/images/lib/drawn/dr_clock';
import { drawn as drawnImages } from '@ludwig/images';

import log from '../../../utilities/logger';

import {
    useUser,
    useActions,
    useDeleteHistoryModal, useSocialShareModal, useConfirmModal,
    Box, Flex,
    Image, 
    Text,
    ActionLink,
    HistorySnippet, HSSkeleton,
    ContentGrid,
    ContentWrapper
} from '@ludwig/ui';

const { dr_clock } = drawnImages || {};

export const HistoryView = ({
    history,
    backAction,
    hideBackAction,
    ...props
}) => {
    const { getSentences, deleteHistorySentence } = useActions();
    const { isGuest } = useUser();
    const containerRef = useRef(null);
    const { showDeleteHistoryModal } = useDeleteHistoryModal();
    const { showSocialShareModal } = useSocialShareModal();
    const { showConfirmModal } = useConfirmModal();
    const { totalPages, currentPage, queries, loading: isLoading } =
        history || {};

    const pages = Array.from(Array(totalPages).keys());
    const hasPages = pages && pages.length > 1;
    const hasQueries = queries && queries.length > 0;

    const [{ showEmptyState, showGuestState, showLoader }, setState] = useState(
        {
            showEmptyState: !hasQueries,
            showGuestState: isGuest,
            showLoader: isLoading
        }
    );

    const showPagination = !showEmptyState && hasPages;

    useEffect(() => {
        if (isLoading) {
            setState(prevState => ({ ...prevState, showLoader: true }));
        } else {
            setState(prevState => ({
                ...prevState,
                showLoader: false,
                showEmptyState: !hasQueries,
                showGuestState: isGuest
            }));
        }
    }, [isLoading, isGuest, hasQueries]);

    const handleSearch = payload => {
        getSentences(payload);
    };
    const handleSocialShare = useCallback(
        payload => {
            log.debug(
                'COMPONENT - HistoryView - Handle Social Share: ',
                payload
            );
            showSocialShareModal({ ...payload });
        },
        [showSocialShareModal]
    );
    const handleDeleteSentence = useCallback(
        payload => {
            log.debug(
                'COMPONENT - HistoryView - Handle Delete Sentence - Payload: ',
                payload
            );
            const triggerConfirm = payload => {
                log.debug(
                    'COMPONENT - HistoryView - Handle Delete Sentence - Confirm - Payload: ',
                    payload
                );
                if (deleteHistorySentence) {
                    return deleteHistorySentence(payload);
                }
            };
            const { renderContent, ...rest } = payload || {};
            showConfirmModal({
                confirmAction: triggerConfirm,
                confirmPayload: rest,
                renderContent,
                confirmTitle: 'HISTORY_REMOVE_SENTENCE_TITLE_DEFAULT',
                confirmActionText: 'HISTORY_REMOVE_SENTENCE_ACTION_DEFAULT',
                confirmSuccess: 'HISTORY_REMOVE_SENTENCE_SUCCESS_DEFAULT',
                confirmCloseText: 'COMMON_ACTION_CLOSE_DEFAULT'
            });
        },
        [deleteHistorySentence, showConfirmModal]
    );
    const headerAction = !showGuestState &&
        !showEmptyState && {
            variant: 'dangerAlpha',
            track: { label: `history-page-nav-next` },
            text: 'HISTORY_ACTION_CLEAR_HISTORY',
            handleClick: showDeleteHistoryModal,
            fontSize: 'sm'
        };

    useEffect(() => {
        const { current } = containerRef || {};
        current.scrollTo(0, 0);
    }, []);

    return (
        <ContentWrapper ref={containerRef} {...props}>
            {/* <ContentHeader
                hideBackAction={hideBackAction}
                backAction={backAction}
                headerAction={headerAction}
            /> */}

            {/* <ContentGrid
                variant="favorites"
                width="100%"
                overflowY="auto"
                flex="1"> */}
                {showGuestState && (
                    <Flex
                        py="lg"
                        px="xs"
                        flexDirection="column"
                        alignItems="center"
                        textDecoration="none"
                        position="relative"
                        bg="grays.400">
                        <Box as="h2" fontFamily="serif">
                            <Text id={'HISTORY_TITLE_GUEST'} />
                        </Box>
                        <Box>
                            <Image image={dr_clock} width={170} />
                        </Box>
                        <ActionLink
                            to="/login"
                            variant="secondary"
                            track={{ label: 'history-signin' }}>
                            <Box as="span">
                                <Text id={'HISTORY_ACTION_SIGN_IN'} />
                            </Box>
                        </ActionLink>
                    </Flex>
                )}
                {!showGuestState && (
                    <Flex width='100%'
                        p="md"
                        flexDirection="column"
                        alignItems="center"
                        textDecoration="none"
                        position="relative"
                        bg="grays.400">
                        <Box width='100%'>
                            {!showLoader && showEmptyState && (
                                <Flex
                                    alignItems="center"
                                    flexDirection="column">
                                    <Box as="p">
                                        <Text
                                            id={
                                                'HISTORY_DESCRIPTION_ZERO_QUERIES'
                                            }
                                        />
                                    </Box>
                                    <ActionLink
                                        to="/"
                                        variant="secondary"
                                        track={{ label: 'history-search' }}>
                                        <Box as="span">
                                            <Text
                                                id={
                                                    'HISTORY_ACTION_START_SEARCH'
                                                }
                                            />
                                        </Box>
                                    </ActionLink>
                                </Flex>
                            )}
                            {/* {showPagination && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    pages={pages}
                                />
                            )} */}
                            <Box mb="md">
                                {!showLoader &&
                                    !showEmptyState &&
                                    queries.map((item, index) => (
                                        <HistorySnippet
                                            handleDelete={handleDeleteSentence}
                                            handleShare={handleSocialShare}
                                            handleSearch={handleSearch}
                                            key={index}
                                            {...item}
                                        />
                                    ))}
                                {showLoader &&
                                    [
                                        ...'load_some_fake_history'
                                    ].map((item, index) => (
                                        <HSSkeleton key={index} />
                                    ))}
                            </Box>
                            {/* {showPagination && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    pages={pages}
                                />
                            )} */}
                        </Box>
                    </Flex>
                )}
            {/* </ContentGrid> */}
            {showPagination && (
                <ContentGrid
                    alignSelf="stretch"
                    borderTop="1px solid rgba(0,0,0,0.12)"
                    p="none">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pages={pages}
                    />
                </ContentGrid>
            )}
        </ContentWrapper>
    );
};

const Pagination = ({ currentPage, totalPages, pages }) => {
    return (
        <Flex
            py="sm"
            alignItems="stretch"
            width="100%"
            justifyContent={{ _: 'center' }}
            fontSize={{ _: 'sm' }}>
            {totalPages && totalPages > 1 && (
                <Flex alignItems="center">
                    <ActionLink
                        mr={{ _: 'sm', md: 'md' }}
                        shouldDispatch={currentPage && currentPage > 0}
                        disabled={!currentPage || currentPage <= 1}
                        to={{
                            type: 'HISTORY',
                            payload: {
                                page:
                                    currentPage && currentPage > 2
                                        ? currentPage - 1
                                        : undefined
                            }
                        }}
                        nav
                        variant="primaryAlpha"
                        left
                        iconLeft={'arrowLeftOutline'}>
                        <span>
                            <Text id={'HISTORY_ACTION_PREVIOUS'} />
                        </span>
                    </ActionLink>
                    <Flex alignItems="center" mr={{ _: 'sm', md: 'md' }}>
                        <span>
                            <span>{currentPage}</span>/<span>{totalPages}</span>
                        </span>
                    </Flex>
                    <ActionLink
                        mr={{ _: 'sm', md: 'md' }}
                        shouldDispatch={currentPage < pages.length}
                        to={{
                            type: 'HISTORY',
                            payload: { page: currentPage + 1 }
                        }}
                        nav
                        disabled={currentPage >= pages.length}
                        variant="primaryAlpha"
                        track={{ label: `history-page-nav-next` }}
                        right
                        iconRight={'arrowRightOutline'}>
                        <span>
                            <Text id={'HISTORY_ACTION_NEXT'} />
                        </span>
                    </ActionLink>
                </Flex>
            )}
        </Flex>
    );
};
