const initialState = {
    pinWin:false,
    expWin:false,
    searchFocus:false,
    errorAfterSendMessage: null,
    loginError:null,
    currentSection: {
        area: '',
        section: '',
        sub: ''
    }
}

const desktop = (state = initialState, action) => {
    const { type, payload } = action || {}
    switch (type) {
        case 'SET_SECTION':
        case 'TOGGLE_PIN_WINDOW':
        case 'COLLAPSE_EXPAND_WINDOW':
        case 'USER_SEARCH_FOCUS':
        case 'LOGIN_ERROR':
        case 'MESSAGE_SENT':
            //  TODO[epic=contact] Should use ludwig/core and ludwig/api
            return { ...state, ...payload }

        default:
            return state
    }
}

export default desktop