import {reducers as coreReducers, contentCore, userCore, searchCore} from '@ludwig/core'

import page from './page'
import {desktop} from './desktop/index'
import {reducers as libraryReducers} from '@ludwig/library'
import domain from './domain'


const {
    ua, 
    version, 
    i10n, 
    origin
} = coreReducers || {}

const { reducers: userReducers } = userCore || {}
const { reducers: searchReducers } = searchCore || {}
const { reducers: contentReducers } = contentCore || {}

export const reducers = {
    page, desktop, domain,
    ua, 
    version, 
    i10n, 
    origin,
    ...libraryReducers, 
    ...contentReducers,
    ...userReducers,
    ...searchReducers
}

export default reducers
