import {useEffect, useRef} from 'react'
import { Flex, ResultsLoader, useSelectors, styled } from '@ludwig/ui'
import { ResultsContent } from './ResultsContent'
import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'

const Override = styled(Flex)`
width:100%;
height:100%;
  & > div{
    display:block;
  }
`

export const Search = () => {
  const { info, loadingSearch, picks } = useSelectors() || {}
  const { currentSentence } = info || {}
  const containerRef = useRef(null)
  useEffect(() => {
    const {current} = containerRef || {}
    current.scrollTop = 0
  }, [loadingSearch])

  return (
    <ColumnWrapContainer ref={containerRef} layout='search' px={{_: 'xs', ms: 'ms'}}>
      <ColumnContainer layout='search'>
        <Flex width='100%'>
          {loadingSearch && <Override mt='md' borderRadius='sm' overflow='hidden'><ResultsLoader /></Override>}

          {
            !loadingSearch &&
            <ResultsContent
              // overflowY='auto'
              data={info.sentencesData}
              showInfo={info}
              picksData={picks}
              sentence={currentSentence} />}
        </Flex>
      </ColumnContainer>
    </ColumnWrapContainer>

  )
}
