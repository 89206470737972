import {redirect} from '@ludwig/core'

export const user = {
    UPDATE_USER: {
        thunk: async (dispatch, getState, { action, extra: { apiInstance: api, apiStream } }) => {
            const {payload} = action || {}
            const {...user} = payload || {}
            const {token, role} = user || {}
            api.defaults.headers.common['Authorization'] = token || '';
            apiStream.defaults.headers.common['Authorization'] = token || '';
            if (role === 'user') {
                dispatch({type: 'LOGIN_SUCCESS', payload: {user}})
                redirect(dispatch({type: 'WELCOME'}))
            } else {
                dispatch({type: 'LOGOUT_SUCCESS', payload: {user}})
                redirect(dispatch({type: 'LOGIN'}))
            }
        }
    }
}
