import {getComponentsByRoute, getPathsByRoute} from '@ludwig/core'
import {routes} from '../routes/index'

const components = getComponentsByRoute(routes) || {}
const pathsByRoute = getPathsByRoute(routes) || {}

const page = (state = 'Welcome', action = {}) => {
    const { type } = action || {};
    
    const isPage = pathsByRoute[type];
    if (!isPage) {
        return state;
    }
    return components[type] || state;
};
export default page