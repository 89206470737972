import log from "../../utilities/logger";

const parentHostname = process.env.REACT_APP_PARENT_HOST
log.debug('Parent Host: ', parentHostname)
const initialState = {
    overwriteHostname: parentHostname ? `https://${parentHostname}` : 'https://ludwig.guru'
}
const domain = (state = initialState, action = {}) => state;

export default domain
