import { Flex, useSelectors } from '@ludwig/ui'
import { FavoritesView } from './FavoritesView.js'
import { ColumnContainer, ColumnWrapContainer } from '../../Wrappers'
import { Text } from '../common/LudwigText'

export const Favorites = () => {
     const { userContent } = useSelectors() || {}
    return (
        <ColumnWrapContainer>
            <ColumnContainer className='nodrag'>
                <Flex p='lg' pl='32px' pb='0'>
                    <Text textId='Favorites' variant='heading_5' />
                </Flex>

                <FavoritesView
                    handleCloseWebclipForm={() => {}}
                    bg='none'
                    userContent={userContent}
                    hideBackAction />
            </ColumnContainer>
        </ColumnWrapContainer>
    )
}
