import { useState } from 'react'
import { Flex, ActionIcon, useSelectors } from '@ludwig/ui'

import { Text } from '../common/LudwigText'
import { Icon } from '../common/LudwigIcon'

export const Source = () => {
  const { theme } = useSelectors()

  const [detailsVisible, setDetailsVisible] = useState(false)

  const handleToggleDetails = () => {
    setDetailsVisible(prev => !prev)
  }

  return (
    <Flex width='100%' flexDirection='column' bg='smoke.100' p='lg'>
      <Flex>
        <Flex flex='1' alignItems='center'>
          <Icon color='grey.500' iconPath={theme.icons.left} />
          <Text variant='strong_s' textId='Back' />
        </Flex>
        <Flex>
          <ActionIcon ml='md' variant='primaryAlpha' icon={theme.icons.target} />
          <ActionIcon ml='md' variant='primaryAlpha' icon={theme.icons.info} handleClick={handleToggleDetails} color={detailsVisible ? 'blues.500' : 'grey.500'} />
          <ActionIcon ml='md' variant='primaryAlpha' icon={theme.icons.external} />
        </Flex>
      </Flex>

      <Flex mt='lg'>
        <Text pl='sm' variant='heading_6_sans' textId='Standford Encyclopedia of Philosophy' />
      </Flex>

      {detailsVisible &&
      <Flex width='100%' flexDirection='column'>
        <Flex py='md' px='sm' borderBottom='1px solid #DEDEDE'>
          <Text width='110px' variant='body_s' textId='Article' />
          <Text variant='body_s' textId='Article Title' />
        </Flex>

        <Flex py='md' px='sm' borderBottom='1px solid #DEDEDE'>
          <Text width='110px' variant='body_s' textId='Author' />
          <Text variant='body_s' textId='First Author Name, Second Author Name, Third Author Name, Fourth Author Name' />
        </Flex>

        <Flex py='md' px='sm'>
          <Text width='110px' variant='body_s' textId='Year' />
          <Text variant='body_s' textId='2014' />
        </Flex>
      </Flex>}

    </Flex>
  )
}
