import { useEffect, useState } from 'react'
import { Flex, Box, NavLink, ActionButton, useActions, useSelectors, styled } from '@ludwig/ui'

import { Icon } from '../common/LudwigIcon'
import { Text } from '../common/LudwigText'
import { TextArea } from '../common/LudwigTextArea'
import { theme } from '../../../theme'
import { useToasts } from '../../../contexts/toastsContext'

const faqList =[
    {text:'Ludwig\'s search is not working?', icon: 'search', url:'https://ludwig.guru/faqs#1'},
    {text:'Facing troubles with your subscription?', icon: 'premium', url:'https://ludwig.guru/faqs#2'},
    {text:'Something is wrong with your account?', icon: 'account', url:'https://ludwig.guru/faqs#3'},
    {text:'Text to speech is not working?', icon: 'question', url:'https://ludwig.guru/faqs#4'},
    {text:'Do you have questions about the service?', icon: 'question', url:'https://ludwig.guru/faqs#5'}
]




export const Feedback = () => {

    const { sendMessage } = useActions()

    const [message, setMessage] = useState('')
    const [disableSent, setDisableSend] = useState(true)
    const [messageSent, setMessageSent] = useState(false)

    const { desktop } = useSelectors()
    const { errorAfterSendMessage } = desktop
    const [messageError, setMessageError] = useState(errorAfterSendMessage)

    const {addToast, closeToast, closeAllToasts} = useToasts()

    useEffect(() => {
        setMessageError(errorAfterSendMessage)
        if(errorAfterSendMessage && errorAfterSendMessage!=='OK') addToast({textId: 'There was an error. Try again.', variant: 'error'})
    }, [errorAfterSendMessage])

    const onTyping = e => {
        const v = e.currentTarget.value
        setDisableSend(!v.length > 0)
        setMessage(v)
    }

    const onSendMessage = () => {
        sendMessage({message})
        setMessageSent(true)
        addToast({textId: 'Message sent! Thank you!', variant: 'success'})
    }

    return (
        <Flex flexDirection='column' p='xl' width='100%'>
            
                <Text variant='heading_6_sans' color='grey.500' textId='Nice to hear from you!' />
                <Text textId='We are happy to answer your feedbacks, suggestions or bug reportings. If you have some dubt or technical issue, try to give a look to our FAQs page.' 
                color='grey.100' variant='body_s' />

                <Flex flexDirection='column' pt='md'>
                    {faqList.map(d => (
                        <FaqCard key={d.url} icon={d.icon} text={d.text} href={d.url} />
                    ))}
                </Flex>

                <Box mt='md'>
                    <Flex flexDirection='column'>
                        <TextArea rows='4' onChange={onTyping} placeholder='Enter your message' />
                        <Flex justifyContent='flex-end'>
                            <ActionButton disabled={disableSent} handleClick={onSendMessage} variant='SolidBlueMedium'>
                                <Text id='Send' />
                            </ActionButton>
                        </Flex>
                    </Flex>
                </Box>
            
        </Flex>
    )
}



const FaqCard = ({icon, text, href}) => {

    const { openUrl } = useActions() || {}

    const onHandleClick = () => {
        if(openUrl) openUrl({href})
    }

    return(
        <FaqWrapper onClick={onHandleClick} bg='white' mb='sm' borderRadius='xs' border='1px solid black' borderColor='blues.500'>
            <Flex height='100%' alignItems='center'  p='md' py='sm'>
                <Text color='blues.500' textId={text} variant='strong' />
                <Flex width='100%' flex='1' justifyContent='flex-end'  alignItems='flex-end'>
                    <Icon color='blues.500' iconPath={theme.icons[icon]} />
                </Flex>
            </Flex>
        </FaqWrapper>
    )
}

const FaqWrapper = styled(Box)(
    props => `
        cursor: pointer;
        background: white;
        &:hover{
            background: ${props.theme.colors.blues[50]};
        }
    `)

